.cms-become-a-peak-customer,
.page-layout-cms-landing {

  .page-title {
    margin-bottom: 2.3rem;

    @include media-breakpoint-down(lg) {
      margin-bottom: 1.8rem;
    }
  }

  .page.messages {
    padding: 0;

    [role='alert']:not(:empty) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      padding-right: 6.8rem;
      padding-bottom: 3.6rem;
      z-index: $zindex-modal-backdrop;

      @include media-breakpoint-down(md) {
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        padding-bottom: 8.8rem;
      }

      .message-container {

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }

        .message .btn-close {
          display: block;
        }
      }

      .btn-close,
      .overlay-close {
        display: none;
      }
    }
  }
}

.become-a-peak-customer-wrapper {
  max-width: 1368px;
  margin: 0 auto;
  padding: 6rem 2.4rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 4rem 2.4rem 8rem;
  }

  &::after {
    content: '';
    display: block;
    background: url(../images/icon-peak-primary-ellipse.svg);
    width: 59px;
    height: 48px;
    margin: 0 auto;
    margin-top: 3.6rem;
    background-size: cover;

    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
      width: 80px;
      height: 66px;
    }
  }

  .header-message {

    [data-content-type='column-line'] {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1.6rem;
      margin-bottom: 3.6rem;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: 0;
        margin-bottom: 7.2rem;
      }

      [data-content-type='column'] {
        width: auto !important;
        flex: 0 0 30%;

        &.contact {
          flex: 0 0 70%;
        }
      }
    }

    h3 {
      font-size: 2.6rem;
      line-height: 1.07;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: 4.6rem;
        line-height: 1.13;
      }
    }

    .contact {

      > [data-content-type='text']:last-child {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        margin-top: 2.2rem;

        @include media-breakpoint-up(lg) {
          gap: 3.7rem;
          flex-direction: row;
          margin-top: 2.4rem;
        }

        a {
          display: flex;
          text-decoration: underline;
          color: $dark;
          font-weight: 600;
          font-size: 1.6rem;

          @include media-breakpoint-up(lg) {
            font-size: 2rem;
          }

          &:hover {
            color: $primary;
          }

          &::before {
            content: '';
            display: inline-block;
            background: url(../images/icon-primary-ellipse.svg);
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 1.5rem;
            width: 33px;
            height: 32px;
          }

          &[href^=tel] {

            &::before {
              background: url(../images/icon-phone-ellipse.svg);
            }
          }

          &[href^=mailto] {

            &::before {
              background: url(../images/icon-email-ellipse.svg);
            }
          }
        }
      }
    }

    p {
      margin: 0;
      font-size: 1.6rem;

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.5;
      }
    }
  }
}

.become-a-peak-customer-form {
  background-color: $light;
  position: relative;
  z-index: 1;
  padding: 2.6rem 2.8rem 4.5rem;

  @include media-breakpoint-up(xl) {
    padding: 6.4rem 11.2rem 8rem;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(../images/icon-peak-graphic.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 176px;
    height: 145px;
    z-index: -1;
  }

  @include media-breakpoint-up(lg) {

    &::before {
      content: '';
      width: 355px;
      height: 306px;
    }
  }

  .width-1-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3.9rem;

    @include media-breakpoint-down(lg) {
      gap: 0;
    }

    >div {
      flex: 1;

      @include media-breakpoint-down(lg) {
        width: 100%;
        flex: inherit;
      }
    }
  }

  .fieldset {

    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }

    .field {
      margin-bottom: 2.8rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 1.8rem;
      }

      .label {
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-size: 1.8rem;

        @include media-breakpoint-down(lg) {
          font-size: 1.4rem;
        }
      }

      &.required > .label::after {
        content: '*';
        margin: 0 0 0 0.5rem;
        color: $primary;
      }

      .input-text {
        height: 40px;
        padding: 1rem 1.5rem;
        border: initial;

        @include media-breakpoint-up(lg) {
          padding: 1.3rem 1.5rem;
          height: 48px;
        }
      }

      &.email {

        .control::before {
          content: none;
        }
      }

      &.requirements {
        margin-bottom: 2.2rem;

        @include media-breakpoint-up(lg) {
          margin-bottom: 3.2rem;
        }

        .input-text {
          min-height: 160px;
        }
      }
    }

    > span {
      margin: 3rem 0 0;
      display: block;
      font-size: 1.5rem;
    }
  }

  .subscribe-newsletter {
    display: flex;
    align-items: center;
    margin-bottom: 2.651rem;

    label {
      font-size: 1.3rem;
      margin-left: 1.2rem;
    }
  }

  .actions-container {

    .action.primary {
      padding: 0.5rem 2rem;
      font-size: 1.4rem;
      line-height: 2;
      width: 100%;

      @include media-breakpoint-up(lg) {
        padding: 0.9rem 1rem;
        font-size: 1.6rem;
        letter-spacing: 0.128rem;
        width: 167px;
      }

      span {
        text-transform: uppercase;
      }
    }
  }
}
