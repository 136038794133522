.process {

  @include media-breakpoint-up(lg) {
    padding: 0 2.4rem;
  }

  h2 {
    font-size: 2.6rem !important;
    line-height: 1.07;
    letter-spacing: -0.104rem;

    @include media-breakpoint-up(lg) {
      font-size: 4.6rem !important;
      letter-spacing: -0.224rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 5.6rem;
    }
  }

  h3 {
    font-size: 2.2rem;
    line-height: 1.09;
    font-weight: 600;

    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
      line-height: 1;
    }

    @include media-breakpoint-up(xl) {
      font-size: 3.2rem;
    }
  }

  [data-content-type='column-group'] {
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 4rem 2.4rem 6.7rem;
    background-position: bottom !important;

    @include media-breakpoint-up(lg) {
      padding: 3.2rem 6.5rem 7rem;
      flex-direction: row;
      background-position: left top;
    }

    @include media-breakpoint-up(xl) {
      padding: 6.2rem 11rem 7rem;
    }

    [data-content-type='column-line'] {
      flex-direction: column;
    }

    [data-content-type='column'].content {

      @include media-breakpoint-up(lg) {
        max-width: 517px;
        padding-right: 3rem;
      }

      [data-content-type='text'] {
        margin-bottom: 2.8rem;

        p {
          font-size: 1.4rem;
          line-height: 1.42;
          margin-bottom: 1.4rem;

          &:last-child {
            margin-bottom: 0;
          }

          @include media-breakpoint-up(lg) {
            font-size: 1.8rem;
            line-height: 1.55;
          }
        }
      }
    }

    [data-content-type='column']:not(.content) {
      width: 100% !important;
      flex-direction: row;
      position: relative;
      background: linear-gradient(125deg, $primary 33.66%, #d7150c 118.95%);
      color: $white;
      padding: 1.8rem 2.2rem 2.2rem 2.4rem;

      @include media-breakpoint-up(lg) {
        padding: 2rem 3rem 3.6rem 10.6rem;
        max-width: 639px;
        margin-left: auto;
      }

      @include media-breakpoint-up(xl) {
        padding: 0.8rem 3rem 3.6rem 13.6rem;

        &:first-child,
        &:nth-child(2) {

          &::before {
            content: '';
          }
        }

        &:first-child {

          &::before {
            right: -93px;
            top: 35px;
          }
        }

        &:nth-child(2) {

          &::before {
            left: -93px;
            top: 50%;
            transform: rotateY(180deg);
          }
        }
      }

      &:first-child,
      &:nth-child(2) {

        &::before {
          width: 112px;
          height: 179px;
          position: absolute;
          background-image: url(../images/icon-dotted-arrow.svg);
          background-size: contain;
        }
      }

      &:not(:first-child) {
        margin-top: 1.6rem;

        @include media-breakpoint-up(lg) {
          margin-top: 2.4rem;
        }
      }

      [data-content-type='image'] {
        position: absolute;
        left: 16px;
        width: 50px;
        height: 50px;
        margin: 0;
        top: 18px;

        @include media-breakpoint-up(lg) {
          left: 30px;
        }

        @include media-breakpoint-up(xl) {
          width: 84px;
          height: 84px;
          top: 24px;
        }
      }

      .tile-accordion {

        [data-role='content'] {

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        .allow {

          &.active {
            margin-bottom: 1.4rem;

            h3::after {
              transform: rotate(-180deg);
              top: 4px;
            }
          }
        }

        h3 {
          display: flex;
          align-items: center;
          position: relative;
          padding-left: 5.6rem;
          padding-right: 9rem;
          margin-bottom: 0;
          height: 50px;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0.8rem;
            padding-left: 0;
            padding-right: 0;
            pointer-events: none;
          }

          &::after {
            content: url(../images/icon-dropdown-arrow.svg);
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;
            line-height: 1.09;
            transition: $transition-base;

            @include media-breakpoint-up(lg) {
              content: none;
            }
          }
        }

        p {
          font-size: 1.4rem;
          line-height: 1.428;
          margin: 0;

          @include media-breakpoint-up(lg) {
            font-weight: 300;
            font-size: 1.6rem;
            max-height: 100%;
            line-height: normal;
          }
        }
      }
    }
  }
}
