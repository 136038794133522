.pagination {
  font-size: 1.3rem;

  .page-item {

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:not(.disabled) {

      .prev,
      .next {

        &:hover::before {
          filter: invert(1) grayscale(100%) brightness(200%);
        }
      }
    }

    &.disabled {

      .prev,
      .next {

        &::before {
          opacity: 0.3;
        }
      }
    }

    &.active .page-link {
      font-weight: 400;
    }
  }

  .page-link {
    width: 30px;
    height: 30px;
    line-height: 29.055px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 1px 1px 6px rgba($black, 0.16);

    .label {
      display: none;
    }
  }

  .prev,
  .next {
    position: relative;
    font-size: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M10.06 18.531 9 17.47 14.47 12 9 6.53l1.06-1.06L16.59 12z'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
    }
  }

  .prev::before {
    transform: rotate(180deg);
  }
}
