.nav-toggle.nav-toggle.nav-toggle.nav-toggle {
  display: none;

  @include media-breakpoint-down(lg) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 62px;
    padding: 0;
    border: none;
    border-radius: 0;

    &::before {
      display: none;
    }

    &:focus,
    &:active {
      background: none;
      outline: none;
      box-shadow: none;
      border: none;
    }

    img:last-child {
      display: none;
    }

    .nav-open & img {
      display: none;
    }
  }

  @media print {
    display: none;
  }
}

.nav-sections {

  &,
  .navigation {
    background: none;
  }

  .navigation.navigation {

    .ui-menu-icon.ui-menu-icon {
      display: none;
    }
  }
}
