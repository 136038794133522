.cms-landing-hero {
  background-color: $light;
  max-width: 1920px;
  margin: 0 auto;

  .btn-outline-primary {
    border-width: 2px;

    &:hover {

      a {
        color: $white;
      }
    }
  }

  [data-content-type='column-line'] {
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      height: 672px;
    }

    [data-content-type='column'] {
      order: 1;

      @include media-breakpoint-up(lg) {
        order: 2;
      }

      @include media-breakpoint-down(lg) {
        width: 100% !important;
      }

      &.content {
        order: 2;
        padding: 2.9rem 2.4rem 4.8rem;

        @include media-breakpoint-up(lg) {
          order: 1;
          padding: 10rem 8.2rem 0 0;
          max-width: 661px;
          margin-left: auto;
        }

        .title p {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1;
          color: $text-muted;
          text-transform: uppercase;

          @include media-breakpoint-up(lg) {
            margin-bottom: 1rem;
            font-size: 2.2rem;
          }
        }

        h1 {
          margin: 0 0 1.2rem;
          font-size: 3.6rem;
          font-weight: 600;
          line-height: 1.03;
          letter-spacing: -0.144rem;
          transform: translateX(-2px);
          text-align: left;

          @include media-breakpoint-up(lg) {
            margin: 0 0 2.7rem;
            font-size: 6.8rem;
            letter-spacing: -0.272rem;
            transform: translateX(-4px);
          }
        }

        [data-content-type='text']:not(.title) p {
          font-size: 1.4rem;
          line-height: 1.42;

          @include media-breakpoint-up(lg) {
            font-size: 1.8rem;
            line-height: 1.55;
          }
        }

        [data-content-type='buttons'] {
          display: flex;
          flex-direction: column;
          gap: 1.4rem;
          margin-top: 0.8rem;

          @include media-breakpoint-up(lg) {
            margin-top: 1.8rem;
            gap: 2.4rem;
            flex-direction: row;
          }

          .btn-primary a {
            color: $white;

            &:hover {
              color: $white;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  figure {
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;

    img {

      @include media-breakpoint-up(lg) {
        width: 800px;
        object-fit: cover;
      }
    }
  }
}
