
.products-grid {

  .product-items {
    display: flex;
    flex-wrap: wrap;
  }

  .product-item {
    position: relative;

    &.product-item.product-item {
      padding: 0 1.5rem 2rem 1.5rem;
    }

    .product-item-photo {
      display: block;
      margin: 0 -1.5rem;
    }

    .product-item-info {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .product-item-details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .product-item-inner {
      margin-top: auto;
    }

    .product-sku {
      display: block;
      color: $gray-500;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.5;
    }

    .product-item-name {
      font-size: 1.5rem;
      line-height: 1.7rem;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0.1rem;
    }

    .more-options {
      font-size: 1.3rem;
      color: $dark;
      font-weight: 600;
    }

    .price-box {
      margin: 0;
      display: flex;

      .minimal-price-link,
      .price-label {
        display: none;
      }

      .price {
        font-size: 1.5rem;
        font-weight: 700;
        color: $primary-dark;
      }

      > span {
        margin-right: 0.5rem;
      }

      .old-price {
        text-decoration: none;

        .price {
          text-decoration: line-through;
        }
      }
    }

    .product-item-link {

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        opacity: 0;
        border: solid 1px $gray-50;
        box-shadow: 0 0 10px rgba($black, 0.06);
        transition: opacity 0.3s;
      }

      &:hover {
        text-decoration: none;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    .product-labels {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      padding: 0.8rem;

      span {
        display: inline-block;
        margin-right: 0.1rem;
        margin-bottom: 0.1rem;
        padding: 0.1rem 1.1rem;
        background-color: $white;
        border: 1px solid $secondary;
        color: $secondary;
        white-space: nowrap;
        font-size: 1.3rem;
        line-height: 1.6;
        font-weight: 500;
      }
    }
  }
}
