.slick-slider {
  overflow: hidden;
  max-width: 100vw;

  &.slick-initialized {
    margin: 0;

    .slick-arrow {
      width: 38px;
      height: 48px;
      background-color: $black !important;
      background-image: url(../images/icon-chevron-right-slider.svg) !important;

      &::after,
      &::before {
        content: none;
      }

      &.slick-next {
        right: 7px;
      }

      &.slick-prev {
        left: 7px;
      }
    }

    .slick-list {
      margin: 0 -0.6rem;

      .slick-slide {
        margin: 0 0.6rem;
        float: left !important;

        &:not(.slick-active) {

          @include media-breakpoint-down(lg) {
            opacity: 0.4;
          }
        }
      }
    }

    .slick-dots {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1.6rem;
      padding-top: 2.8rem;

      .slick-active button {
        background-color: $black;
      }

      li {
        margin: 0;
        width: auto;
        height: auto;

        button {
          width: 8px;
          height: 8px;
          border-radius: unset;
          background-color: rgba($black, 0.1);
          border: unset;

          &::before {
            content: none;
          }
        }
      }
    }
  }
}
