.page-layout-2columns-left {

  .columns {
    display: flex;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    &::after {
      display: none;
    }
  }

  .column.main {
    flex: 0 1 100%;
    width: auto;
    padding-left: 0;

    @include media-breakpoint-up(lg) {
      flex-basis: 650px;
      margin-left: auto;
    }

    @include media-breakpoint-up(xl) {
      flex-basis: 965px;
      margin-right: auto;
    }
  }

  .sidebar-main {

    @include media-breakpoint-up(lg) {
      order: -1;
      flex: 0 1 287px;
      margin-right: 2rem;
      padding-right: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 4rem;
    }
  }
}
