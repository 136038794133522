.discount {
  padding-bottom: 6rem;
  max-width: 1920px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    padding-bottom: 8rem;
  }

  [data-content-type='banner'] {
    margin: 0 2.4rem;

    .pagebuilder-overlay.pagebuilder-poster-overlay {
      min-height: initial !important;
      height: 270px;
      padding: 0 3.2rem;

      @include media-breakpoint-up(lg) {
        height: 240px;
      }

      [data-element='content'] {
        position: relative;

        @include media-breakpoint-up(lg) {
          max-width: 1018px;
          margin-left: auto;
          margin-right: 4.2rem;
        }

        p {
          color: $white;
          font-weight: 300;
          font-size: 1.4rem;
          line-height: 1.42;
          padding-right: 4rem;

          @include media-breakpoint-up(lg) {
            font-size: 2rem;
            line-height: 1.5;
            max-width: 65%;
            margin: 0;
            padding-right: 0;
          }

          &:first-child {
            font-size: 3.2rem;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1.5rem;

            @include media-breakpoint-up(lg) {
              font-size: 5.6rem;
              letter-spacing: -0.224rem;
              max-width: 100%;
              margin-bottom: 1.9rem;
            }
          }
        }

        >div {

          @include media-breakpoint-up(lg) {
            position: absolute;
            display: flex;
            right: 0;
            bottom: 0;
          }

          a {
            display: block;
            color: $white;
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: uppercase;
            background-color: $dark;
            letter-spacing: 0.128rem;
            padding: 0.4rem 1.6rem;
            height: 41px;
            text-align: center;
            width: 100%;

            &:hover {
              text-decoration: none;
            }

            @include media-breakpoint-up(lg) {
              font-size: 1.6rem;
              padding: 1rem 2rem;
              height: 51px;
            }
          }
        }
      }
    }
  }

  [data-content-type='text'] p {
    font-size: 1rem;
    line-height: 1;
    font-weight: 300;
    margin: 1rem 2.4rem 0;
    text-align: right;

    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }
  }
}
