.menu-content {
  background: $gray-100;
  position: relative;

  @include media-breakpoint-up(md) {
    z-index: -1;
  }

  .menu-content-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  [data-content-type=row][data-appearance=contained] {
    flex-grow: 1;

    [data-element=inner] {
      padding: 0;
    }
  }

  .product-count {
    color: $gray-550;
  }

  a {
    color: $gray-950;

    &.is-hot {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &::before {
        background: url(../images/icon-heat.svg);
        background-size: cover;
        content: '';
        display: block;
        width: 18px;
        height: 20px;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }
  }
}

.desktop-menu-dropdown-toggle {
  border: 0;
  background: none;
  margin: 0.6rem 1.8rem 0.6rem 0;
  min-width: 200px;
  padding-left: 0;
  border-right: 1px solid $border-color;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.menu-static-links {
  flex-grow: 1;
  display: flex;

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }

  > ul {
    display: flex;
    align-items: center;
    z-index: 9;

    &.link-left {
      margin-right: auto;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    > li {
      display: flex;
      align-items: center;
      align-self: stretch;
      margin: 0;
      padding: 1rem 1.5rem 1rem;

      @include media-breakpoint-up(xl) {
        padding: 1rem 1.8rem 1rem;
      }

      &.help-dropdown {
        padding-right: 2rem;
      }
    }
  }
}

.help-dropdown {
  position: relative;
  background: url(../images/icon-down-red.svg);
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer;

  .help-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    padding: 1.8rem 2rem;
    border: 1px solid $border-color;
    background: $white;
    min-width: 218px;

    > ul {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }
  }

  &:hover .help-dropdown-content {
    display: block;
  }
}

@include media-breakpoint-up(lg) {

  .nav-sections {
    width: 100%;
    display: none;
    background-color: $light;
    position: absolute;
    margin-bottom: 0;
    left: 0;
    top: 100%;
    box-shadow: 0 2px 4px 0 rgba($black, 0.15);

    .navigation {
      max-width: none;
      padding-left: 0;
      padding-right: 0;

      .show-all {
        display: none !important;
      }

      >.container {
        padding: 0;
      }

      > .container > ul {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        padding: 2rem 0;
      }

      li.level0 {
        position: static;
        margin-right: 0;
        max-width: 200px;
        border-right: 1px solid $border-color;

        > a.level-top {
          font-size: 1.5rem;
          color: $gray-950;
          font-weight: 400;
          border: none;
          padding: 0.5rem 2rem 0.5rem 3.5rem;
          display: block;
          line-height: 20px;
          white-space: nowrap;

          &.is-hot {
            display: flex;
          }
        }

        &:hover {

          &::before,
          ul.level0 {
            opacity: 1;
            visibility: visible;
          }

          > a {
            font-weight: 700;
          }
        }
      }

      .level0:hover::after {
        display: none !important;
      }

      ul.level0 {
        position: absolute;
        top: 0 !important;
        left: 200px !important;
        right: 0 !important;
        display: flex !important;
        padding: 2rem;
        justify-content: flex-start;
        transition: 0.2s ease-out 0.2s;
        background: none;
        opacity: 0;
        visibility: hidden;
        flex-wrap: wrap;
        border: none;
        box-shadow: none;
        height: 279px;

        li.level1 {
          margin-right: 1rem;

          @include media-breakpoint-only(xl) {
            margin-right: 9rem;
          }

          > a {
            font-size: 1.5rem;
            color: $gray-950;
            background-color: transparent;
            border-color: transparent;
            border: none;
            padding: 0.5rem 1.6rem;
            line-height: 20px;
            font-weight: 400;

            &:hover {
              font-weight: 700;
            }

            .ui-icon-carat-1-e {
              display: none;
            }
          }
        }

        a:hover {
          text-decoration: underline;
        }
      }

      ul.level1.level1.level1 {
        position: static;
        top: auto !important;
        left: auto !important;
        box-shadow: none;
        display: block !important;
        border: none;

        li.level2 > a {
          font-size: 1.3rem;
          font-weight: 400;
          color: $body-color;
          background-color: transparent;
          border-color: transparent;
          border: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {

  .nav-open {

    .amsearch-wrapper-block {
      z-index: 0;
    }

    .nav-sections {
      left: 0;
      height: 100vh;
      height: 100dvh;
      overscroll-behavior: contain;
      background: $white;

      @media (min-width: 468px) {
        max-width: 414px;
      }
    }

    .nav-toggle::after {
      height: 100vh;
    }
  }

  .nav-before-open .page-wrapper {
    overflow: visible;
  }

  .nav-open .page-wrapper {

    @media (min-width: 468px) {
      left: 414px;
    }
  }

  .nav-sections {

    .nav-sections-items {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .navigation {
      padding: 0;
      margin-top: 0;
      margin-bottom: 10px;

      .mobile-menu-closer-container {
        text-align: right;
        height: 60px;

        .mobile-menu-closer {
          padding: 12px 15px;
        }
      }

      > ul.child-open {
        margin-top: -60px !important;
      }

      a {
        padding: 15px 30px !important;
        border: none !important;
        line-height: 1.5 !important;

        span:not(.ui-menu-icon) {
          margin: 0 !important;
        }
      }

      .show-all a {
        padding: 1.65rem 3rem !important;
        font-size: 1.8rem;
        text-align: center;
        font-weight: 600;
        background-color: $black;
        color: $white !important;
      }

      &.navigation ul {
        margin: 0;
        padding: 0;
      }

      .level0 {
        border: none;
      }
    }

    .section-item-title {
      display: none !important;
    }

    .section-item-content {
      float: none;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 0;
      margin-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .all-category {
      display: none !important;
    }

    ul.submenu.submenu {
      padding-left: 0;
    }

    .navigation.navigation .level-top,
    a {
      text-transform: none;
    }

    .navigation .parent {

      a {
        position: relative;
      }

      .mobile-menu-toggle {
        display: inline-block;
        width: 60px;
        height: 60px;
        background-image: url(../images/icon-chevron-right.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);

        &.toggle-back:not(.x-toggle) {
          background-image: url(../images/icon-chevron-right-white.svg);
          right: auto;
          left: 5px;
          transform: scaleX(-1) translateY(-50%);
        }

        &.x-toggle {
          background-image: url(../images/icon-close-white.svg);
        }

        &.toggle-open {
          width: 52px;
          height: 50px;
          background-size: 24px 24px;
          top: 53%;
        }
      }

      & > a::after {
        display: none !important;
      }
    }

    .navigation ul.submenu li:not(.mobile-open) {

      & > a {
        display: none !important;
      }
    }

    .navigation ul.child-open {

      &.child-open.child-open > li > a {
        display: none !important;
      }

      & > li.mobile-open > ul,
      & > li.mobile-open > ul > li > a {
        display: block !important;
      }
    }

    a,
    .level-top.level-top.level-top {
      color: #000 !important;
      font-size: 1.6rem;
      font-weight: 400;
      border-bottom: 0;

      &.is-hot {
        display: flex;
      }
    }

    .submenu .active > a,
    .level0.active > a,
    .level0.has-active > a {
      border-color: transparent !important;
    }
  }
}

.extra-menu {
  margin-top: auto;
  background-color: $primary-dark;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 1.5rem 3rem;
      margin: 0;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);

      a {
        display: flex;
        align-items: center;
        color: $white !important;
        font-weight: 600;

        svg,
        img {
          margin-top: 0.1rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .sign-in img {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}
