@font-face {
  font-family: 'Muli';
  src:
    url('../fonts/muli-webfont.woff2') format('woff2'),
    url('../fonts/muli-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src:
    url('../fonts/muli-bold-webfont.woff2') format('woff2'),
    url('../fonts/muli-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src:
    url('../fonts/muli-semibold-webfont.woff2') format('woff2'),
    url('../fonts/muli-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src:
    url('../fonts/muli-light-webfont.woff2') format('woff2'),
    url('../fonts/muli-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
