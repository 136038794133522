.negotiable_quote-quote-index {

  .table-wrapper {

    td a {
      color: $body-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .toolbar {

    .pages {

      @include media-breakpoint-down(md) {
        float: none;
      }

      .pages-label {
        display: none;
      }

      .pages-items {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 0.5rem;

        li {

          &.pages-item-previous,
          &.pages-item-next,
          &.current .label {
            display: none;
          }

          a {
            color: $body-color;
          }
        }
      }
    }
  }

  .limiter {
    margin-top: -1rem;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
