.negotiable_quote-quote-send {

  .page-title-wrapper {
    margin-top: 2rem;
  }

  .negotiable-quote-success {
    margin-bottom: 4rem;
  }
}
