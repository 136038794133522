// Offsets scroll on anchor links (so header doesn't hide it)
html {
  overscroll-behavior: none;

  @include media-breakpoint-up(lg) {
    scroll-margin-top: 22.5rem;
  }
}

body {
  font-size: 1.5rem;
}

.navigation, .breadcrumb, .page-header .header.panel, .header.content, .search-autocomplete, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {  /* stylelint-disable-line */
  @extend .container;
}

.page-layout-cms-full-width .page-main,
[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  max-width: 1920px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

[data-content-type='row'][data-appearance='full-width'] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

[data-content-type='row'][data-appearance='contained'] {
  max-width: map-get($container-max-widths, 'xl');

  [data-element='inner'] {
    margin: 0;
    padding: 0 1rem;
  }
}

::selection {
  background-color: $primary;
  color: $white;
}

.column.main.main {
  padding-bottom: 0;
}

.loader-container {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($light, 0.3);
}

div.mage-error {
  color: #e02b27;
}
