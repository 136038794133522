.block-search {
  position: static;
  float: none;
  flex-grow: 1;
  max-width: 800px;
  width: auto;
  margin: auto;
  padding: 0;

  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    max-width: 100%;
    width: 100%;
    padding: 1.5rem;
    background: $white;
    box-shadow: inset $box-shadow;
  }

  .input-group {

    .form-control {
      left: auto;
      margin: 0;
      font-size: $input-btn-font-size-lg;

      @include media-breakpoint-down(lg) {
        min-height: 4.5rem;
        padding: $input-padding-y-sm $input-padding-x;
      }
    }

    .btn {

      @include media-breakpoint-down(lg) {
        padding: 1.25rem 2rem;
      }
    }
  }
}

.search-autocomplete {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0 !important;
  display: none !important;
  background: $white;
  border-top: 1px solid $gray-200;
  box-shadow: $box-shadow-sm !important;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0;

  &::after {
    content: '';
    background: rgba($black, 0.5);
    position: fixed;
    top: 129px;
    right: 0;
    bottom: 0;
    left: auto;
    width: 100%;
    z-index: -1;
    pointer-events: none;
  }

  @include media-breakpoint-up(lg) {
    top: 83px;
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 123px;
    bottom: 123px;
    overflow-y: auto;
    overscroll-behavior: none;
  }

  .minisearch:focus-within &:not(:empty) {
    display: flex !important;
    flex-direction: column;
  }

  .suggestions-header {
    width: 100%;
    margin-bottom: 1.2rem;
    text-align: left;
  }

  .suggestion {
    margin: 0 1rem 1.2rem 0;
    padding: 1rem 1.5rem;
    background-color: $gray-200;
    color: $dark;
    border-radius: $border-radius-pill;
    font-size: 1.2rem;
    text-align: left;
    transition: $transition-base;

    &:hover {
      background-color: $dark;
      color: $gray-100;
    }
  }

  .popover-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    overflow: revert;
    height: 100%;

    @include media-breakpoint-down(md) {
      display: block;
      flex-grow: 1;
      height: auto;
    }
  }

  .suggestions-container {
    display: none;
    // display: flex; keeping styles for when we may want to toggle this back on
    flex-wrap: wrap;
    margin: 0;
    padding: 3.6rem 0 3.6rem 3.8rem;

    @include media-breakpoint-up(md) {
      max-width: 216px;
      width: 100%;
    }

    @include media-breakpoint-only(xl) {
      max-width: 366px;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
      width: 100%;
      padding: 3.6rem 2.9rem 0 2.9rem;
    }
  }

  .products-container {
    width: 100%;
    row-gap: 2.6rem;
    padding: 3.6rem 4rem 9.8rem 3.8rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);

    @include media-breakpoint-up(lg) {
      row-gap: 3.5rem;
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(3, 1fr);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr;
      width: 100%;
      row-gap: 2.3rem;
      margin-bottom: 6rem;
      padding: 3.6rem 2.9rem 4rem 2.9rem;

      .product-result {
        padding: 0;
      }
    }

    @include media-breakpoint-only(xs) {
      grid-template-columns: 1fr;
    }
  }

  .product-result {
    grid-template-columns: 89px auto;
    grid-template-rows: auto;
    grid-template-areas:
      'image productName'
      'image price';

    &:hover {
      background: none;
      text-decoration: underline;
    }

    > div {
      align-self: flex-end;
    }

    img {
      object-fit: contain;
      height: 89px;
    }

    .product-name {
      font-weight: 400;
      line-height: 1.3;
    }

    .product-price {
      align-self: flex-start;
      color: $primary-dark;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }

  .view-all-footer.view-all-footer {
    position: absolute;
    bottom: 3.7rem;
    right: 4rem;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    color: $body-color;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    border: $btn-border-width solid transparent;

    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size, $btn-border-radius);
    @include transition($btn-transition);
    @include button-variant(
      $white,
      $dark,
      $color: $dark,
      $hover-background: shade-color($dark, $btn-hover-bg-shade-amount),
      $hover-border: shade-color($dark, $btn-hover-border-shade-amount),
      $hover-color: $white
    );

    @include media-breakpoint-down(sm) {
      position: static;
      margin: 0 3.2rem;
      display: block;
      transform: translateY(-5.5rem);
    }
  }
}

.catalogsearch-result-index {

  .product-item {

    .price-container {

      .weee {

        &::after {
          content: '';
        }
      }
    }
  }
}
