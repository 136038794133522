.messages {

  .btn-close,
  .overlay-close {
    display: none;
  }

  .messages {
    position: relative;
    z-index: 0;
  }

  &.message-list:not(:empty) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    padding-right: 6.8rem;
    padding-bottom: 3.6rem;
    z-index: $zindex-modal-backdrop;

    @include media-breakpoint-down(md) {
      padding-right: 1.6rem;
      padding-left: 1.6rem;
      padding-bottom: 8.8rem;
    }

    .message-container {

      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }

      &:first-child .overlay-close {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: -1;
        display: block;
        background-color: $modal-backdrop-bg;
        opacity: $modal-backdrop-opacity;
      }
    }
  }
}

.message.message {
  position: relative;
  z-index: $zindex-modal;
  max-width: 485px;
  margin-bottom: 0;
  padding: 2.95rem 4rem 2.95rem 1.5rem;
  background-color: $white;
  box-shadow: $box-shadow-lg;
  border-radius: $border-radius-lg;
  border-left-width: 8px;
  border-left-style: solid;
  font-weight: 500;
  font-size: 1.3rem;
  color: $body-color;

  &:last-child {
    margin-bottom: 0;
  }

  &.error,
  &.success,
  &.info,
  &.warning {

    &::before {
      display: block;
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 0.9rem;
    }

    .btn-close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      display: block;
    }
  }

  &.error {
    border-left-color: $danger;

    &::before {
      content: 'Attention';
    }
  }

  &.success {
    border-left-color: $success;

    &::before {
      content: 'Success';
    }
  }

  &.info {
    border-left-color: $info;

    &::before {
      content: 'Info';
    }
  }

  &.warning {
    border-left-color: $warning;

    &::before {
      content: 'Warning';
    }
  }

  &.demo {
    border: none;
    max-width: none;
  }

  > *:first-child::before {
    content: '';
    width: auto;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
