.tooltip-inner {
  line-height: 1.25;
}

.small-tooltip {
  background-image: url(../images/icon-question.svg);
  display: inline-block;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
