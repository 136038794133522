.page-layout-cms-landing {

  .page-main-full-width .columns {
    max-width: 100%;
  }

  .suppliers {
    text-align: center;
    padding: 2rem 0;

    @include media-breakpoint-up(lg) {
      padding: 4rem 0;
    }

    h3 {
      line-height: 1.06;
      font-size: 1.5rem;
      letter-spacing: 0.06rem;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0 6rem;

      @include media-breakpoint-up(lg) {
        font-size: 2.2rem;
        margin-bottom: 3.2rem;
      }
    }

    [data-content-type='image'] {
      margin: 0 2.4rem;
    }
  }

  .btn-primary a {
    color: $white;

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }

  .btn-outline-primary a:hover {
    text-decoration: none;
  }
}
