.page-header .amsearch-input-wrapper {
  width: 100%;

  .amsearch-input {
    width: 100%;
    height: 45px;
    border-radius: 0.3rem 0 0 0.3rem;
    padding: 1.9rem 3rem 2rem 2rem;
    border-color: $border-color;
    transition: none;

    @include media-breakpoint-up(lg) {
      height: 65px;
      padding: 1.9rem 3rem 2rem 18rem;
    }

    @include media-breakpoint-only(xs) {
      font-size: 1.6rem;
    }
  }

  .-loupe {
    display: none !important;
  }

  .-close {
    padding: 1.6rem 2.2rem;
    filter: brightness(0%);

    &:hover {
      filter: brightness(100%);
    }
  }
}

.amsearch-button.-search {
  align-self: stretch;
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: $dark;
  border-color: $dark;
  opacity: 1;
  margin-left: -1px;
  width: 95px;
  flex-shrink: 0;
  padding: 0;
  transition: none;

  @include media-breakpoint-up(lg) {
    width: 136px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 1.2rem;
  }

  &:hover {
    background-color: $black;
    border-color: $black;
  }
}

.amsearch-highlight {
  font-weight: inherit;
  color: inherit;
}

.amsearch-form-block,
.amsearch-wrapper-block {
  position: static;
}

.amsearch-wrapper-block.amsearch-wrapper-block {
  float: none;
  flex-grow: 1;
  max-width: 800px;
  margin: auto;

  &::before {
    width: 100% !important;
    margin: 0 !important;
    left: 0;
  }

  .selected-category-name {
    position: absolute;
    left: 0;
    top: 1.25rem;
    z-index: 1;
    cursor: pointer;
    height: 4rem;
    width: 15.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-right: 1px solid $border-color;
  }

  .category-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    margin-top: 0.2rem;
    width: 19.8rem;
    background: $white;
    border: 1px solid $border-color;
    padding: 1.5rem 2.2rem;
    z-index: 1;

    li {
      margin: 0;
      cursor: pointer;

      &:hover {
        font-weight: 700;
      }

      &.is-hot {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &::before {
          background: url(../images/icon-heat.svg);
          background-size: cover;
          content: '';
          display: block;
          width: 18px;
          height: 20px;
        }
      }

      a {
        color: $body-color;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    order: 1;
    width: 100%;
    max-width: 100%;
    padding: 1.5rem;
    box-shadow: inset 0 3px 6px rgba($black, 0.16);

    .category-dropdown {
      display: none;
    }
  }
}

.amsearch-form-block {
  max-width: 100%;
  width: auto !important;
  transform: none;
  position: relative;

  &.-opened {
    z-index: 100;
  }

  &:hover .amsearch-input {
    background-color: $light;
  }

  &:focus-within {

    .amsearch-input {
      border-color: $primary-dark $dark $primary-dark $primary-dark;
      border-width: 2px 1px 2px 2px;
      outline: none;
      padding-left: 1.9rem;

      @include media-breakpoint-up(lg) {
        padding-left: 18rem;
      }
    }

    .amsearch-button.-search {
      border-color: $primary-dark $primary-dark $primary-dark $dark;
      border-width: 2px;
    }
  }
}

.-amsearch-overlay-opened {

  @include media-breakpoint-down(md) {
    height: 100vh;
    height: 100lvh;
    overflow-y: hidden;
    overscroll-behavior: contain;

    .page-wrapper {
      height: 100vh;
    }
  }
}

.amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section {
  width: auto;
  max-height: 80vh;
  left: 0;
  right: 0;
  animation: none;
  border-radius: 0;
  margin-top: -1px;
  padding: 0 2.9rem;
  box-shadow: none;

  @include media-breakpoint-down(md) {
    max-height: calc(100vh - 200px);
  }

  @include media-breakpoint-up(md) {
    padding: 0 2rem;
    box-shadow: 0 2px 4px rgba(84, 95, 125, 0.12), 0 5px 8px rgba(155, 159, 174, 0.1), inset 1px 1px 0 #eaeaea;

    .amsearch-products-section {
      padding-bottom: 8rem;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    padding: 0 4rem;

    .amsearch-items-section:not(:first-child:last-child) {
      width: 25%;
      max-width: none;
      padding-top: 3rem;
    }

    .amsearch-products-section {
      width: 75%;
      padding-top: 3rem;

      &:only-child {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-only(xl) {
    left: -2.2rem;
    right: -2.2rem;
  }
}

.amsearch-item-container {
  padding-bottom: 3.2rem;

  .amsearch-block-header {
    padding: 0 0 1.3rem 0;
  }
}

.amsearch-products-section {

  .amsearch-block-header {
    padding: 0 0 1.3rem 1.3rem;
  }

  @include media-breakpoint-down(md) {
    border-top: none;
    padding-top: 0;
    padding-bottom: 13rem;

    .amsearch-block-header {
      padding-left: 0;
    }

    &:only-child {
      padding-top: 2rem;
    }
  }
}

.amsearch-block-header {

  .amsearch-title {
    text-transform: none;
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.amsearch-products-section.-grid:not(.-slider) {

  .amsearch-product-list {
    display: flex;
    width: 100%;

    .amsearch-item {
      max-width: 100%;
      width: 100%;

      @include media-breakpoint-up(md) {
        max-width: 50%;
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 33.3%;
        width: 33.3%;
      }

      &.product-item {
        display: flex;
        flex-wrap: nowrap;
        padding: 1.3rem;

        @include media-breakpoint-down(md) {
          padding-left: 0;
          padding-right: 0;

          + .product-item {
            padding-top: 0;
          }
        }

        &:hover {
          background-color: transparent;
        }

        .amsearch-image {
          width: 120px;
          padding-right: 0.9rem;
          padding-bottom: 0;
        }

        .amsearch-description {
          padding-top: 1rem;
        }

        .amsearch-sku-block {
          font-size: 1.3rem;
          font-weight: 700;
          color: $gray-500;
          line-height: 1.4;
          padding-bottom: 0;
        }

        .product-item-link {
          font-size: 1.5rem;
          font-weight: 400;
          color: $gray-800;
          line-height: 1.3;
          padding-bottom: 0;
        }

        .price-box {
          color: $primary-dark;
          padding-bottom: 0;

          span {
            flex-basis: auto;
            padding: 0;
          }

          .price-final_price,
          .normal-price,
          .price {
            color: $primary-dark;
            font-size: 1.3rem;
            font-weight: 700;
            line-height: 1.7;
          }

          .old-price,
          .special-price {
            display: inline-block;
            flex-basis: auto;
            margin-right: 0.5rem;
            line-height: 1.2;
          }

          .old-price {
            text-decoration: none;

            .price {
              text-decoration: line-through;
            }
          }
        }

        .price-label,
        .minimal-price-link {
          display: none;
        }

        .more-colours {
          font-weight: 600;
          font-size: 1.3rem;
          line-height: 1.4;
        }
      }
    }
  }
}

.amsearch-products-section:not(:first-child) {
  border-left: none;
}

.amsearch-item-container .amsearch-tab-items {
  display: flex !important;
  flex-wrap: wrap;

  .amsearch-item {
    display: inline-block;

    &:hover {
      background-color: transparent;
    }

    .amsearch-link {
      padding: 0.2rem 1.5rem;
      margin: 0 1rem 1rem 0;
      background-color: $gray-100;
      white-space: nowrap;
      border-radius: 50px;
      line-height: 2.084;
      font-size: 1.2rem;
      color: $dark;

      &:hover {
        background-color: $gray-900;
        color: $white;
      }
    }
  }
}

.amsearch-products-section .amsearch-block-header .amsearch-link {
  color: $dark;
  padding: 0.36rem 3.25rem;
  border: solid 1px $dark;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  position: absolute;
  bottom: 3.7rem;
  right: 0;
  z-index: 10;
  background-color: $white;
  color: $dark;

  @include media-breakpoint-down(md) {
    left: 0.4rem;
    right: 0.4rem;
    bottom: 5.5rem;
    text-align: center;
  }

  &:hover {
    background-color: $gray-900;
    color: $white;
    text-decoration: none;
  }

  .amsearch-count::before,
  .amsearch-count::after {
    display: none;
  }
}

.amsearch-collapsible-title {

  &::after {
    display: none !important;
  }
}

.amsearch-overlay-block {
  background-color: rgba(30, 31, 30, 0.7);
  z-index: 1;

  @include media-breakpoint-only(xs) {
    height: 100lvh;
  }
}

.amsearch-message-block {
  margin-left: 0;
  margin-right: 0;
}

body.-amsearch-overlay-opened {
  overflow: hidden;
  touch-action: none;
}

body.-amsearch-overlay-opened .logo,
body.-amsearch-overlay-opened .nav-sections,
body.-amsearch-overlay-opened .menu-content,
body.-amsearch-overlay-opened .page-header .panel.wrapper {
  z-index: 2;
}

body.-amsearch-overlay-opened .page-header .header-content {
  z-index: 3;
}

.amshopby-filter-current .amshopby-items {

  .amshopby-item .amshopby-remove.amshopby-remove.amshopby-remove {
    padding-top: 0;
    padding-bottom: 0;
    height: 28px;
    line-height: 28px;

    .btn-close {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
}
