.testimonials {
  display: flex;
  flex-direction: column;
  max-width: 1368px;
  margin: 0 auto;

  &[data-content-type=row][data-appearance=full-width] {
    padding: 0 2.4rem !important;
  }

  [data-content-type='column-group'] {
    padding: 6.1rem 0 6rem;

    @include media-breakpoint-up(lg) {
      padding: 10rem 0;
    }
  }

  [data-content-type='column-line'] {
    gap: 2.4rem;

    @include media-breakpoint-up(lg) {
      gap: 4.3rem;
    }

    [data-content-type='column'] {
      border: 2px solid $gray-200;
      min-height: 420px;
      padding: 2.6rem 2.6rem 3.2rem;

      @include media-breakpoint-up(lg) {
        padding: 4rem 5.6rem 6.9rem;
        min-height: 472px;
      }

      [data-content-type='html'] > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          gap: 4rem;
        }

        img {
          max-height: 42px;

          @include media-breakpoint-up(lg) {
            max-height: 53px;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 1.5rem;
          font-weight: 600;
          letter-spacing: 0.08rem;
          text-align: center;
          margin: 0;
          line-height: 1.33;

          @include media-breakpoint-up(lg) {
            font-size: 2.2rem;
            text-align: left;
          }

          span {
            display: block;
            font-size: 1.2rem;
            line-height: 1.66;
            font-weight: 300;
            text-transform: capitalize;

            @include media-breakpoint-up(lg) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }

  [data-content-type='text'] {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.2rem;
    flex: 1;

    @include media-breakpoint-up(lg) {
      align-items: center;
      margin-bottom: 0;
    }

    &::before {
      content: '';
      background-image: url(../images/icon-quote.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 33px;
      height: 20px;
      display: inline-block;
      margin: 0 auto 1.4rem;

      @include media-breakpoint-up(lg) {
        margin: 0 auto 1.9rem 0;
        width: 46px;
        height: 28px;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 1.375;
      font-weight: 300;
      margin: 0;
      text-align: center;

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.5;
        text-align: left;
      }
    }
  }
}
