/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #6F75FF;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #FFB5B5;
  --red: #FF1A1A;
  --orange: #fd7e14;
  --yellow: #FFEAB5;
  --green: #198754;
  --teal: #20c997;
  --cyan: #B9FFE4;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #363836;
  --gray-100: #E6E6E6;
  --gray-200: #e9ecef;
  --gray-300: #D3D3D3;
  --gray-400: #ced4da;
  --gray-500: #B2B2B2;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #363836;
  --gray-900: #212221;
  --primary: #FF1A1A;
  --secondary: #6F75FF;
  --success: #B9FFE4;
  --info: #B5D7FF;
  --warning: #FFEAB5;
  --danger: #FFD8D8;
  --light: #F7F7F7;
  --dark: #212221;
  --white: #fff;
  --primary-rgb: 255, 26, 26;
  --secondary-rgb: 111, 117, 255;
  --success-rgb: 185, 255, 228;
  --info-rgb: 181, 215, 255;
  --warning-rgb: 255, 234, 181;
  --danger-rgb: 255, 216, 216;
  --light-rgb: 247, 247, 247;
  --dark-rgb: 33, 34, 33;
  --white-rgb: 255, 255, 255;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --body-color-rgb: 38, 38, 38;
  --body-bg-rgb: 255, 255, 255;
  --font-sans-serif: "Muli", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --body-font-family: var(--font-sans-serif);
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --body-line-height: 2;
  --body-color: #262626;
  --body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--body-color);
  text-align: var(--body-text-align);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 700;
  line-height: 1.25; }

h1, .h1 {
  font-size: 4rem; }

h2, .h2 {
  font-size: 2.8rem; }

h3, .h3 {
  font-size: 2.2rem; }

h4, .h4 {
  font-size: 1.8rem; }

h5, .h5 {
  font-size: 1.6rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fff; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #FF1A1A;
  text-decoration: none; }
  a:hover {
    color: #cd0000;
    text-decoration: underline; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #FFB5B5;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212221;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit; }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.5rem;
  font-weight: 700; }

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-3 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.25; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.25; }

.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.25; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #D3D3D3;
  border-radius: 0.2rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .search-autocomplete, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--gutter-x, 1.5rem);
  padding-left: var(--gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .search-autocomplete, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm {
    max-width: 550px; } }

@media (min-width: 768px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .search-autocomplete, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm, .container-md {
    max-width: 740px; } }

@media (min-width: 1024px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .search-autocomplete, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm, .container-md, .container-lg {
    max-width: 1000px; } }

@media (min-width: 1366px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .search-autocomplete, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1322px; } }

.row {
  --gutter-x: 3rem;
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gutter-y));
  margin-right: calc(-.5 * var(--gutter-x));
  margin-left: calc(-.5 * var(--gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--gutter-x) * .5);
    padding-left: calc(var(--gutter-x) * .5);
    margin-top: var(--gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --gutter-x: 0; }

.g-0,
.gy-0 {
  --gutter-y: 0; }

.g-1,
.gx-1 {
  --gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --gutter-x: 1rem; }

.g-3,
.gy-3 {
  --gutter-y: 1rem; }

.g-4,
.gx-4 {
  --gutter-x: 2rem; }

.g-4,
.gy-4 {
  --gutter-y: 2rem; }

.g-5,
.gx-5 {
  --gutter-x: 4.5rem; }

.g-5,
.gy-5 {
  --gutter-y: 4.5rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --gutter-x: 2rem; }
  .g-sm-4,
  .gy-sm-4 {
    --gutter-y: 2rem; }
  .g-sm-5,
  .gx-sm-5 {
    --gutter-x: 4.5rem; }
  .g-sm-5,
  .gy-sm-5 {
    --gutter-y: 4.5rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --gutter-x: 2rem; }
  .g-md-4,
  .gy-md-4 {
    --gutter-y: 2rem; }
  .g-md-5,
  .gx-md-5 {
    --gutter-x: 4.5rem; }
  .g-md-5,
  .gy-md-5 {
    --gutter-y: 4.5rem; } }

@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --gutter-x: 2rem; }
  .g-lg-4,
  .gy-lg-4 {
    --gutter-y: 2rem; }
  .g-lg-5,
  .gx-lg-5 {
    --gutter-x: 4.5rem; }
  .g-lg-5,
  .gy-lg-5 {
    --gutter-y: 4.5rem; } }

@media (min-width: 1366px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --gutter-x: 2rem; }
  .g-xl-4,
  .gy-xl-4 {
    --gutter-y: 2rem; }
  .g-xl-5,
  .gx-xl-5 {
    --gutter-x: 4.5rem; }
  .g-xl-5,
  .gy-xl-5 {
    --gutter-y: 4.5rem; } }

.form-label {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: #000; }

.col-form-label {
  padding-top: calc(1.55rem + 1px);
  padding-bottom: calc(1.55rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.3;
  color: #000; }

.col-form-label-lg {
  padding-top: calc(2.175rem + 1px);
  padding-bottom: calc(2.175rem + 1px);
  font-size: 1.5rem; }

.col-form-label-sm {
  padding-top: calc(0.87rem + 1px);
  padding-bottom: calc(0.87rem + 1px);
  font-size: 1.2rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 1.55rem 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3;
  color: #262626;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #B2B2B2;
  appearance: none;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #262626;
    background-color: #fff;
    border-color: #000;
    outline: 0;
    box-shadow: none; }
  .form-control::-webkit-date-and-time-value {
    height: 1.3em; }
  .form-control::placeholder {
    color: #B2B2B2;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 1.55rem 1.5rem;
    margin: -1.55rem -1.5rem;
    margin-inline-end: 1.5rem;
    color: #262626;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 1.55rem 1.5rem;
    margin: -1.55rem -1.5rem;
    margin-inline-end: 1.5rem;
    color: #262626;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1.55rem 0;
  margin-bottom: 0;
  line-height: 1.3;
  color: #262626;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.3em + 1.74rem + 2px);
  padding: 0.87rem 2.93rem;
  font-size: 1.2rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.87rem 2.93rem;
    margin: -0.87rem -2.93rem;
    margin-inline-end: 2.93rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.87rem 2.93rem;
    margin: -0.87rem -2.93rem;
    margin-inline-end: 2.93rem; }

.form-control-lg {
  min-height: calc(1.3em + 4.35rem + 2px);
  padding: 2.175rem 2rem;
  font-size: 1.5rem;
  border-radius: 0.2rem; }
  .form-control-lg::file-selector-button {
    padding: 2.175rem 2rem;
    margin: -2.175rem -2rem;
    margin-inline-end: 2rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 2.175rem 2rem;
    margin: -2.175rem -2rem;
    margin-inline-end: 2rem; }

textarea.form-control {
  min-height: calc(1.3em + 3.1rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.3em + 1.74rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.3em + 4.35rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 1.55rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.3em;
    border-radius: 0.2rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.3em;
    border-radius: 0.2rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 1.55rem 4.65rem 1.55rem 1.55rem;
  -moz-padding-start: calc(1.55rem - 3px);
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3;
  color: #262626;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363836' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.55rem center;
  background-size: 16px 12px;
  border: 1px solid #B2B2B2;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  .form-select:focus {
    border-color: #000;
    outline: 0;
    box-shadow: none; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1.55rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #262626; }

.form-select-sm {
  padding-top: 0.87rem;
  padding-bottom: 0.87rem;
  padding-left: 2.93rem;
  font-size: 1.2rem;
  border-radius: 0.2rem; }

.form-select-lg {
  padding-top: 2.175rem;
  padding-bottom: 2.175rem;
  padding-left: 2rem;
  font-size: 1.5rem;
  border-radius: 0.2rem; }

.form-check {
  display: block;
  min-height: 2rem;
  padding-left: 3.4rem;
  margin-bottom: 1rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -3.4rem; }

.form-check-input {
  width: 2.4rem;
  height: 2.4rem;
  margin-top: -0.2rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #B2B2B2;
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.3rem; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #000;
    outline: 0;
    box-shadow: none; }
  .form-check-input:checked {
    background-color: #000;
    border-color: #000; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3e%3cpath stroke='%23fff' d='M18.708 7.708l-8.354 8.353L6 11.708l.707-.707 3.647 3.646L18 7.001z' fill-rule='evenodd'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #FF1A1A;
    border-color: #FF1A1A;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 1; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #FF1A1A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    .form-range::-webkit-slider-thumb:active {
      background-color: #ffbaba; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #D3D3D3;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #FF1A1A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    .form-range::-moz-range-thumb:active {
      background-color: #ffbaba; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #D3D3D3;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #B2B2B2; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #B2B2B2; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 1.5rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
  .form-floating > .form-control {
    padding: 1rem 1.5rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1.55rem 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3;
  color: #262626;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #B2B2B2;
  border-radius: 0.2rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 2.175rem 2rem;
  font-size: 1.5rem;
  border-radius: 0.2rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.87rem 2.93rem;
  font-size: 1.2rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 6.2rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #B9FFE4; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1.25rem 1rem;
  margin-top: .1rem;
  font-size: 1.3rem;
  color: #000;
  background-color: rgba(185, 255, 228, 0.9);
  border-radius: 0.3rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #B9FFE4;
  padding-right: calc(1.3em + 3.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23B9FFE4' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.775rem) center;
  background-size: calc(0.65em + 1.55rem) calc(0.65em + 1.55rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #B9FFE4;
    box-shadow: 0 0 0 0.25rem rgba(185, 255, 228, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.3em + 3.1rem);
  background-position: top calc(0.325em + 0.775rem) right calc(0.325em + 0.775rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #B9FFE4; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 8.525rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363836' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23B9FFE4' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1.55rem center, center right 4.65rem;
    background-size: 16px 12px, calc(0.65em + 1.55rem) calc(0.65em + 1.55rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #B9FFE4;
    box-shadow: 0 0 0 0.25rem rgba(185, 255, 228, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #B9FFE4; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #B9FFE4; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(185, 255, 228, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #B9FFE4; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #FFD8D8; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1.25rem 1rem;
  margin-top: .1rem;
  font-size: 1.3rem;
  color: #000;
  background-color: rgba(255, 216, 216, 0.9);
  border-radius: 0.3rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FFD8D8;
  padding-right: calc(1.3em + 3.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FFD8D8'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FFD8D8' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.775rem) center;
  background-size: calc(0.65em + 1.55rem) calc(0.65em + 1.55rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #FFD8D8;
    box-shadow: 0 0 0 0.25rem rgba(255, 216, 216, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.3em + 3.1rem);
  background-position: top calc(0.325em + 0.775rem) right calc(0.325em + 0.775rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #FFD8D8; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 8.525rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363836' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FFD8D8'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FFD8D8' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1.55rem center, center right 4.65rem;
    background-size: 16px 12px, calc(0.65em + 1.55rem) calc(0.65em + 1.55rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #FFD8D8;
    box-shadow: 0 0 0 0.25rem rgba(255, 216, 216, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #FFD8D8; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #FFD8D8; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 216, 216, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #FFD8D8; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.3;
  color: #262626;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1.55rem 4.85rem;
  font-size: 1.3rem;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover {
    color: #262626;
    text-decoration: none; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: none; }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 1; }

.btn-primary {
  color: #000;
  background-color: #FF1A1A;
  border-color: #FF1A1A; }
  .btn-primary:hover {
    color: #000;
    background-color: #ff3c3c;
    border-color: #ff3131; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #000;
    background-color: #ff3c3c;
    border-color: #ff3131;
    box-shadow: 0 0 0 0.25rem rgba(217, 22, 22, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #000;
    background-color: #ff4848;
    border-color: #ff3131; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 22, 22, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #FF1A1A;
    border-color: #FF1A1A; }

.btn-secondary {
  color: #000;
  background-color: #6F75FF;
  border-color: #6F75FF; }
  .btn-secondary:hover {
    color: #000;
    background-color: #858aff;
    border-color: #7d83ff; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #000;
    background-color: #858aff;
    border-color: #7d83ff;
    box-shadow: 0 0 0 0.25rem rgba(94, 99, 217, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #000;
    background-color: #8c91ff;
    border-color: #7d83ff; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(94, 99, 217, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #000;
    background-color: #6F75FF;
    border-color: #6F75FF; }

.btn-success {
  color: #000;
  background-color: #B9FFE4;
  border-color: #B9FFE4; }
  .btn-success:hover {
    color: #000;
    background-color: #c4ffe8;
    border-color: #c0ffe7; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #000;
    background-color: #c4ffe8;
    border-color: #c0ffe7;
    box-shadow: 0 0 0 0.25rem rgba(157, 217, 194, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #c7ffe9;
    border-color: #c0ffe7; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(157, 217, 194, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #B9FFE4;
    border-color: #B9FFE4; }

.btn-info {
  color: #000;
  background-color: #B5D7FF;
  border-color: #B5D7FF; }
  .btn-info:hover {
    color: #000;
    background-color: #c0ddff;
    border-color: #bcdbff; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #c0ddff;
    border-color: #bcdbff;
    box-shadow: 0 0 0 0.25rem rgba(154, 183, 217, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #c4dfff;
    border-color: #bcdbff; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(154, 183, 217, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #B5D7FF;
    border-color: #B5D7FF; }

.btn-warning {
  color: #000;
  background-color: #FFEAB5;
  border-color: #FFEAB5; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffedc0;
    border-color: #ffecbc; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffedc0;
    border-color: #ffecbc;
    box-shadow: 0 0 0 0.25rem rgba(217, 199, 154, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffeec4;
    border-color: #ffecbc; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 199, 154, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #FFEAB5;
    border-color: #FFEAB5; }

.btn-danger {
  color: #000;
  background-color: #FFD8D8;
  border-color: #FFD8D8; }
  .btn-danger:hover {
    color: #000;
    background-color: #ffdede;
    border-color: #ffdcdc; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #000;
    background-color: #ffdede;
    border-color: #ffdcdc;
    box-shadow: 0 0 0 0.25rem rgba(217, 184, 184, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #000;
    background-color: #ffe0e0;
    border-color: #ffdcdc; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 184, 184, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #000;
    background-color: #FFD8D8;
    border-color: #FFD8D8; }

.btn-light {
  color: #000;
  background-color: #F7F7F7;
  border-color: #F7F7F7; }
  .btn-light:hover {
    color: #000;
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f8f8f8;
    border-color: #f8f8f8;
    box-shadow: 0 0 0 0.25rem rgba(210, 210, 210, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9f9f9;
    border-color: #f8f8f8; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(210, 210, 210, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #F7F7F7;
    border-color: #F7F7F7; }

.btn-dark {
  color: #fff;
  background-color: #212221;
  border-color: #212221; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1b1c1b;
    border-color: #1b1c1b; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #1b1c1b;
    border-color: #1b1c1b;
    box-shadow: 0 0 0 0.25rem rgba(66, 67, 66, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1b1a;
    border-color: #191a19; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 67, 66, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #212221;
    border-color: #212221; }

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #000;
    background-color: white;
    border-color: white; }
  .btn-check:focus + .btn-white, .btn-white:focus {
    color: #000;
    background-color: white;
    border-color: white;
    box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-check:checked + .btn-white,
  .btn-check:active + .btn-white, .btn-white:active, .btn-white.active,
  .show > .btn-white.dropdown-toggle {
    color: #000;
    background-color: white;
    border-color: white; }
    .btn-check:checked + .btn-white:focus,
    .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-white:disabled, .btn-white.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff; }

.btn-outline-primary {
  color: #FF1A1A;
  border-color: #FF1A1A; }
  .btn-outline-primary:hover {
    color: #000;
    background-color: #FF1A1A;
    border-color: #FF1A1A; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 26, 26, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #000;
    background-color: #FF1A1A;
    border-color: #FF1A1A; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 26, 26, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #FF1A1A;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6F75FF;
  border-color: #6F75FF; }
  .btn-outline-secondary:hover {
    color: #000;
    background-color: #6F75FF;
    border-color: #6F75FF; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(111, 117, 255, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #000;
    background-color: #6F75FF;
    border-color: #6F75FF; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(111, 117, 255, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6F75FF;
    background-color: transparent; }

.btn-outline-success {
  color: #B9FFE4;
  border-color: #B9FFE4; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #B9FFE4;
    border-color: #B9FFE4; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(185, 255, 228, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #B9FFE4;
    border-color: #B9FFE4; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(185, 255, 228, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #B9FFE4;
    background-color: transparent; }

.btn-outline-info {
  color: #B5D7FF;
  border-color: #B5D7FF; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #B5D7FF;
    border-color: #B5D7FF; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(181, 215, 255, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #B5D7FF;
    border-color: #B5D7FF; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(181, 215, 255, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #B5D7FF;
    background-color: transparent; }

.btn-outline-warning {
  color: #FFEAB5;
  border-color: #FFEAB5; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #FFEAB5;
    border-color: #FFEAB5; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 234, 181, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #FFEAB5;
    border-color: #FFEAB5; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 234, 181, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #FFEAB5;
    background-color: transparent; }

.btn-outline-danger {
  color: #FFD8D8;
  border-color: #FFD8D8; }
  .btn-outline-danger:hover {
    color: #000;
    background-color: #FFD8D8;
    border-color: #FFD8D8; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 216, 216, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #FFD8D8;
    border-color: #FFD8D8; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 216, 216, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #FFD8D8;
    background-color: transparent; }

.btn-outline-light {
  color: #F7F7F7;
  border-color: #F7F7F7; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #F7F7F7;
    border-color: #F7F7F7; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(247, 247, 247, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #F7F7F7;
    border-color: #F7F7F7; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(247, 247, 247, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #F7F7F7;
    background-color: transparent; }

.btn-outline-dark {
  color: #212221;
  border-color: #212221; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212221;
    border-color: #212221; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 34, 33, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #212221;
    border-color: #212221; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 34, 33, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #212221;
    background-color: transparent; }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
  .btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-check:checked + .btn-outline-white,
  .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
    .btn-check:checked + .btn-outline-white:focus,
    .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white:disabled, .btn-outline-white.disabled {
    color: #fff;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #FF1A1A;
  text-decoration: none; }
  .btn-link:hover {
    color: #cd0000;
    text-decoration: underline; }
  .btn-link:focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg {
  padding: 2.175rem 2rem;
  font-size: 1.5rem;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.87rem 2.76rem;
  font-size: 1.2rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #FF1A1A;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  .nav-link:hover, .nav-link:focus {
    color: #cd0000;
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #D3D3D3; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #D3D3D3;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #D3D3D3 #D3D3D3 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.2rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #FF1A1A; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.2rem - 1px);
      border-top-right-radius: calc(0.2rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.2rem - 1px);
      border-bottom-left-radius: calc(0.2rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.2rem - 1px) calc(0.2rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.2rem - 1px) calc(0.2rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.2rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px); }

.card-group > .card {
  margin-bottom: 1.5rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #262626;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  .accordion-button:not(.collapsed) {
    color: #e61717;
    background-color: #ffe8e8;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e61717'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23262626'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #000;
    outline: 0;
    box-shadow: none; }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.2rem - 1px);
      border-top-right-radius: calc(0.2rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.2rem - 1px);
      border-bottom-left-radius: calc(0.2rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.875rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.875rem;
    color: #262626;
    content: var(--breadcrumb-divider, none) /* rtl: var(--breadcrumb-divider, none) */; }

.breadcrumb-item.active {
  color: #262626; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #262626;
  background-color: #fff;
  border: 0 solid #D3D3D3;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #FF1A1A;
    border-color: #D3D3D3; }
  .page-link:focus {
    z-index: 3;
    color: #cd0000;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: none; }

.page-item:not(:first-child) .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #FF1A1A;
  border-color: #FF1A1A; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #D3D3D3; }

.page-link {
  padding: 0 0; }

.page-item:first-child .page-link {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%; }

.page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.75rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.3rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem; }

.btn-close {
  box-sizing: content-box;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0 0;
  color: #000;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='m17.031 7.949-1.061-1.06-3.97 3.97-3.97-3.97-1.06 1.06 3.97 3.97-3.97 3.97 1.06 1.061L12 12.98l3.97 3.97 1.061-1.061-3.97-3.97z'/%3E%3C/svg%3E") center/2.4rem auto no-repeat;
  border: 0;
  border-radius: 0.2rem;
  opacity: 1; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.7; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0px 0px 3px rgba(255, 26, 26, 0.16);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.875; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.3rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 220px;
  padding: 1.25rem 1rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3rem; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #1E1F1E; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.41; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 3rem; }
  .offcanvas-header .btn-close {
    padding: 1.5rem 1.5rem;
    margin-top: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -1.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 2; }

.offcanvas-body {
  flex-grow: 1;
  padding: 3rem 3rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 414px;
  border-right: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 414px;
  border-left: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #FF1A1A; }
  .link-primary:hover, .link-primary:focus {
    color: #ff4848; }

.link-secondary {
  color: #6F75FF; }
  .link-secondary:hover, .link-secondary:focus {
    color: #8c91ff; }

.link-success {
  color: #B9FFE4; }
  .link-success:hover, .link-success:focus {
    color: #c7ffe9; }

.link-info {
  color: #B5D7FF; }
  .link-info:hover, .link-info:focus {
    color: #c4dfff; }

.link-warning {
  color: #FFEAB5; }
  .link-warning:hover, .link-warning:focus {
    color: #ffeec4; }

.link-danger {
  color: #FFD8D8; }
  .link-danger:hover, .link-danger:focus {
    color: #ffe0e0; }

.link-light {
  color: #F7F7F7; }
  .link-light:hover, .link-light:focus {
    color: #f9f9f9; }

.link-dark {
  color: #212221; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1b1a; }

.link-white {
  color: #fff; }
  .link-white:hover, .link-white:focus {
    color: white; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --aspect-ratio: 100%; }

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1024px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1366px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important; }

.shadow-sm {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16) !important; }

.shadow-lg {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #B2B2B2 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #B2B2B2 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #B2B2B2 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #B2B2B2 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #B2B2B2 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #FF1A1A !important; }

.border-secondary {
  border-color: #6F75FF !important; }

.border-success {
  border-color: #B9FFE4 !important; }

.border-info {
  border-color: #B5D7FF !important; }

.border-warning {
  border-color: #FFEAB5 !important; }

.border-danger {
  border-color: #FFD8D8 !important; }

.border-light {
  border-color: #F7F7F7 !important; }

.border-dark {
  border-color: #212221 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 2rem !important; }

.gap-5 {
  gap: 4.5rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 2rem !important; }

.m-5 {
  margin: 4.5rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 2rem !important; }

.mt-5 {
  margin-top: 4.5rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 2rem !important; }

.me-5 {
  margin-right: 4.5rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 2rem !important; }

.mb-5 {
  margin-bottom: 4.5rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 2rem !important; }

.ms-5 {
  margin-left: 4.5rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -2rem !important; }

.m-n5 {
  margin: -4.5rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -2rem !important;
  margin-left: -2rem !important; }

.mx-n5 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important; }

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -2rem !important; }

.mt-n5 {
  margin-top: -4.5rem !important; }

.me-n1 {
  margin-right: -0.25rem !important; }

.me-n2 {
  margin-right: -0.5rem !important; }

.me-n3 {
  margin-right: -1rem !important; }

.me-n4 {
  margin-right: -2rem !important; }

.me-n5 {
  margin-right: -4.5rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -2rem !important; }

.mb-n5 {
  margin-bottom: -4.5rem !important; }

.ms-n1 {
  margin-left: -0.25rem !important; }

.ms-n2 {
  margin-left: -0.5rem !important; }

.ms-n3 {
  margin-left: -1rem !important; }

.ms-n4 {
  margin-left: -2rem !important; }

.ms-n5 {
  margin-left: -4.5rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 2rem !important; }

.p-5 {
  padding: 4.5rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 2rem !important; }

.pt-5 {
  padding-top: 4.5rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 2rem !important; }

.pe-5 {
  padding-right: 4.5rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 2rem !important; }

.pb-5 {
  padding-bottom: 4.5rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 2rem !important; }

.ps-5 {
  padding-left: 4.5rem !important; }

.font-monospace {
  font-family: var(--font-monospace) !important; }

.fs-1 {
  font-size: 4rem !important; }

.fs-2 {
  font-size: 2.8rem !important; }

.fs-3 {
  font-size: 2.2rem !important; }

.fs-4 {
  font-size: 1.8rem !important; }

.fs-5 {
  font-size: 1.6rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 2 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --text-opacity: 1;
  color: rgba(var(--primary-rgb), var(--text-opacity)) !important; }

.text-secondary {
  --text-opacity: 1;
  color: rgba(var(--secondary-rgb), var(--text-opacity)) !important; }

.text-success {
  --text-opacity: 1;
  color: rgba(var(--success-rgb), var(--text-opacity)) !important; }

.text-info {
  --text-opacity: 1;
  color: rgba(var(--info-rgb), var(--text-opacity)) !important; }

.text-warning {
  --text-opacity: 1;
  color: rgba(var(--warning-rgb), var(--text-opacity)) !important; }

.text-danger {
  --text-opacity: 1;
  color: rgba(var(--danger-rgb), var(--text-opacity)) !important; }

.text-light {
  --text-opacity: 1;
  color: rgba(var(--light-rgb), var(--text-opacity)) !important; }

.text-dark {
  --text-opacity: 1;
  color: rgba(var(--dark-rgb), var(--text-opacity)) !important; }

.text-white {
  --text-opacity: 1;
  color: rgba(var(--white-rgb), var(--text-opacity)) !important; }

.text-black {
  --text-opacity: 1;
  color: rgba(var(--black-rgb), var(--text-opacity)) !important; }

.text-body {
  --text-opacity: 1;
  color: rgba(var(--body-color-rgb), var(--text-opacity)) !important; }

.text-muted {
  --text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --text-opacity: 0.25; }

.text-opacity-50 {
  --text-opacity: 0.5; }

.text-opacity-75 {
  --text-opacity: 0.75; }

.text-opacity-100 {
  --text-opacity: 1; }

.bg-primary {
  --bg-opacity: 1;
  background-color: rgba(var(--primary-rgb), var(--bg-opacity)) !important; }

.bg-secondary {
  --bg-opacity: 1;
  background-color: rgba(var(--secondary-rgb), var(--bg-opacity)) !important; }

.bg-success {
  --bg-opacity: 1;
  background-color: rgba(var(--success-rgb), var(--bg-opacity)) !important; }

.bg-info {
  --bg-opacity: 1;
  background-color: rgba(var(--info-rgb), var(--bg-opacity)) !important; }

.bg-warning {
  --bg-opacity: 1;
  background-color: rgba(var(--warning-rgb), var(--bg-opacity)) !important; }

.bg-danger {
  --bg-opacity: 1;
  background-color: rgba(var(--danger-rgb), var(--bg-opacity)) !important; }

.bg-light {
  --bg-opacity: 1;
  background-color: rgba(var(--light-rgb), var(--bg-opacity)) !important; }

.bg-dark {
  --bg-opacity: 1;
  background-color: rgba(var(--dark-rgb), var(--bg-opacity)) !important; }

.bg-white {
  --bg-opacity: 1;
  background-color: rgba(var(--white-rgb), var(--bg-opacity)) !important; }

.bg-black {
  --bg-opacity: 1;
  background-color: rgba(var(--black-rgb), var(--bg-opacity)) !important; }

.bg-body {
  --bg-opacity: 1;
  background-color: rgba(var(--body-bg-rgb), var(--bg-opacity)) !important; }

.bg-transparent {
  --bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bg-opacity: 0.1; }

.bg-opacity-25 {
  --bg-opacity: 0.25; }

.bg-opacity-50 {
  --bg-opacity: 0.5; }

.bg-opacity-75 {
  --bg-opacity: 0.75; }

.bg-opacity-100 {
  --bg-opacity: 1; }

.bg-gradient {
  background-image: var(--gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.2rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.2rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important; }

.rounded-end {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 2rem !important; }
  .gap-sm-5 {
    gap: 4.5rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 2rem !important; }
  .m-sm-5 {
    margin: 4.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 2rem !important; }
  .mt-sm-5 {
    margin-top: 4.5rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 2rem !important; }
  .me-sm-5 {
    margin-right: 4.5rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 2rem !important; }
  .mb-sm-5 {
    margin-bottom: 4.5rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 2rem !important; }
  .ms-sm-5 {
    margin-left: 4.5rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -2rem !important; }
  .m-sm-n5 {
    margin: -4.5rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -2rem !important; }
  .mt-sm-n5 {
    margin-top: -4.5rem !important; }
  .me-sm-n1 {
    margin-right: -0.25rem !important; }
  .me-sm-n2 {
    margin-right: -0.5rem !important; }
  .me-sm-n3 {
    margin-right: -1rem !important; }
  .me-sm-n4 {
    margin-right: -2rem !important; }
  .me-sm-n5 {
    margin-right: -4.5rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -2rem !important; }
  .mb-sm-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-sm-n1 {
    margin-left: -0.25rem !important; }
  .ms-sm-n2 {
    margin-left: -0.5rem !important; }
  .ms-sm-n3 {
    margin-left: -1rem !important; }
  .ms-sm-n4 {
    margin-left: -2rem !important; }
  .ms-sm-n5 {
    margin-left: -4.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 2rem !important; }
  .p-sm-5 {
    padding: 4.5rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 2rem !important; }
  .pt-sm-5 {
    padding-top: 4.5rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 2rem !important; }
  .pe-sm-5 {
    padding-right: 4.5rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 2rem !important; }
  .pb-sm-5 {
    padding-bottom: 4.5rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 2rem !important; }
  .ps-sm-5 {
    padding-left: 4.5rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 2rem !important; }
  .gap-md-5 {
    gap: 4.5rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 2rem !important; }
  .m-md-5 {
    margin: 4.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 2rem !important; }
  .mt-md-5 {
    margin-top: 4.5rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 2rem !important; }
  .me-md-5 {
    margin-right: 4.5rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 2rem !important; }
  .mb-md-5 {
    margin-bottom: 4.5rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 2rem !important; }
  .ms-md-5 {
    margin-left: 4.5rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -2rem !important; }
  .m-md-n5 {
    margin: -4.5rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-md-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -2rem !important; }
  .mt-md-n5 {
    margin-top: -4.5rem !important; }
  .me-md-n1 {
    margin-right: -0.25rem !important; }
  .me-md-n2 {
    margin-right: -0.5rem !important; }
  .me-md-n3 {
    margin-right: -1rem !important; }
  .me-md-n4 {
    margin-right: -2rem !important; }
  .me-md-n5 {
    margin-right: -4.5rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -2rem !important; }
  .mb-md-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-md-n1 {
    margin-left: -0.25rem !important; }
  .ms-md-n2 {
    margin-left: -0.5rem !important; }
  .ms-md-n3 {
    margin-left: -1rem !important; }
  .ms-md-n4 {
    margin-left: -2rem !important; }
  .ms-md-n5 {
    margin-left: -4.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 2rem !important; }
  .p-md-5 {
    padding: 4.5rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 2rem !important; }
  .pt-md-5 {
    padding-top: 4.5rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 2rem !important; }
  .pe-md-5 {
    padding-right: 4.5rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 2rem !important; }
  .pb-md-5 {
    padding-bottom: 4.5rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 2rem !important; }
  .ps-md-5 {
    padding-left: 4.5rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 2rem !important; }
  .gap-lg-5 {
    gap: 4.5rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 2rem !important; }
  .m-lg-5 {
    margin: 4.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 2rem !important; }
  .mt-lg-5 {
    margin-top: 4.5rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 2rem !important; }
  .me-lg-5 {
    margin-right: 4.5rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 2rem !important; }
  .mb-lg-5 {
    margin-bottom: 4.5rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 2rem !important; }
  .ms-lg-5 {
    margin-left: 4.5rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -2rem !important; }
  .m-lg-n5 {
    margin: -4.5rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -2rem !important; }
  .mt-lg-n5 {
    margin-top: -4.5rem !important; }
  .me-lg-n1 {
    margin-right: -0.25rem !important; }
  .me-lg-n2 {
    margin-right: -0.5rem !important; }
  .me-lg-n3 {
    margin-right: -1rem !important; }
  .me-lg-n4 {
    margin-right: -2rem !important; }
  .me-lg-n5 {
    margin-right: -4.5rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -2rem !important; }
  .mb-lg-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-lg-n1 {
    margin-left: -0.25rem !important; }
  .ms-lg-n2 {
    margin-left: -0.5rem !important; }
  .ms-lg-n3 {
    margin-left: -1rem !important; }
  .ms-lg-n4 {
    margin-left: -2rem !important; }
  .ms-lg-n5 {
    margin-left: -4.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 2rem !important; }
  .p-lg-5 {
    padding: 4.5rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 2rem !important; }
  .pt-lg-5 {
    padding-top: 4.5rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 2rem !important; }
  .pe-lg-5 {
    padding-right: 4.5rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 2rem !important; }
  .pb-lg-5 {
    padding-bottom: 4.5rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 2rem !important; }
  .ps-lg-5 {
    padding-left: 4.5rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1366px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 2rem !important; }
  .gap-xl-5 {
    gap: 4.5rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 2rem !important; }
  .m-xl-5 {
    margin: 4.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 2rem !important; }
  .mt-xl-5 {
    margin-top: 4.5rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 2rem !important; }
  .me-xl-5 {
    margin-right: 4.5rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 2rem !important; }
  .mb-xl-5 {
    margin-bottom: 4.5rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 2rem !important; }
  .ms-xl-5 {
    margin-left: 4.5rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -2rem !important; }
  .m-xl-n5 {
    margin: -4.5rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -2rem !important; }
  .mt-xl-n5 {
    margin-top: -4.5rem !important; }
  .me-xl-n1 {
    margin-right: -0.25rem !important; }
  .me-xl-n2 {
    margin-right: -0.5rem !important; }
  .me-xl-n3 {
    margin-right: -1rem !important; }
  .me-xl-n4 {
    margin-right: -2rem !important; }
  .me-xl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -2rem !important; }
  .mb-xl-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-xl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xl-n3 {
    margin-left: -1rem !important; }
  .ms-xl-n4 {
    margin-left: -2rem !important; }
  .ms-xl-n5 {
    margin-left: -4.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 2rem !important; }
  .p-xl-5 {
    padding: 4.5rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 2rem !important; }
  .pt-xl-5 {
    padding-top: 4.5rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 2rem !important; }
  .pe-xl-5 {
    padding-right: 4.5rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 2rem !important; }
  .pb-xl-5 {
    padding-bottom: 4.5rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 2rem !important; }
  .ps-xl-5 {
    padding-left: 4.5rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

html {
  font-size: 0.625rem; }

body {
  font-size: 1.5rem; }

@font-face {
  font-family: 'Muli';
  src: url("../fonts/muli-webfont.woff2") format("woff2"), url("../fonts/muli-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Muli';
  src: url("../fonts/muli-bold-webfont.woff2") format("woff2"), url("../fonts/muli-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Muli';
  src: url("../fonts/muli-semibold-webfont.woff2") format("woff2"), url("../fonts/muli-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Muli';
  src: url("../fonts/muli-light-webfont.woff2") format("woff2"), url("../fonts/muli-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

.text-sm {
  font-size: 1.1rem; }

.messages .btn-close,
.messages .overlay-close {
  display: none; }

.messages .messages {
  position: relative;
  z-index: 0; }

.messages.message-list:not(:empty) {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  padding-right: 6.8rem;
  padding-bottom: 3.6rem;
  z-index: 1050; }
  @media (max-width: 767.98px) {
    .messages.message-list:not(:empty) {
      padding-right: 1.6rem;
      padding-left: 1.6rem;
      padding-bottom: 8.8rem; } }
  .messages.message-list:not(:empty) .message-container:not(:last-child) {
    margin-bottom: 1.6rem; }
  .messages.message-list:not(:empty) .message-container:first-child .overlay-close {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: -1;
    display: block;
    background-color: #1E1F1E;
    opacity: 0.41; }

.message.message {
  position: relative;
  z-index: 1055;
  max-width: 485px;
  margin-bottom: 0;
  padding: 2.95rem 4rem 2.95rem 1.5rem;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  border-radius: 0.3rem;
  border-left-width: 8px;
  border-left-style: solid;
  font-weight: 500;
  font-size: 1.3rem;
  color: #262626; }
  .message.message:last-child {
    margin-bottom: 0; }
  .message.message.error::before, .message.message.success::before, .message.message.info::before, .message.message.warning::before {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.9rem; }
  .message.message.error .btn-close, .message.message.success .btn-close, .message.message.info .btn-close, .message.message.warning .btn-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: block; }
  .message.message.error {
    border-left-color: #FFD8D8; }
    .message.message.error::before {
      content: 'Attention'; }
  .message.message.success {
    border-left-color: #B9FFE4; }
    .message.message.success::before {
      content: 'Success'; }
  .message.message.info {
    border-left-color: #B5D7FF; }
    .message.message.info::before {
      content: 'Info'; }
  .message.message.warning {
    border-left-color: #FFEAB5; }
    .message.message.warning::before {
      content: 'Warning'; }
  .message.message.demo {
    border: none;
    max-width: none; }
  .message.message > *:first-child::before {
    content: '';
    width: auto;
    margin: 0; }
  .message.message a {
    color: inherit;
    text-decoration: underline; }
    .message.message a:hover {
      text-decoration: none; }

.breadcrumb {
  margin-top: 0.8rem;
  color: #262626; }
  @media (max-width: 767.98px) {
    .breadcrumb {
      margin-top: 1.5rem;
      justify-content: center; } }
  .breadcrumb a {
    color: inherit;
    text-decoration: underline; }
    .breadcrumb a:hover {
      text-decoration: none; }
  .breadcrumb .breadcrumb-item {
    margin-bottom: 0; }
    .breadcrumb .breadcrumb-item:not(:last-child) a {
      font-weight: 700; }
    .breadcrumb .breadcrumb-item:not(:last-child)::after {
      content: '';
      width: 22px;
      height: 22px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M10.06 18.531 9 17.47 14.47 12 9 6.53l1.06-1.06L16.59 12z'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      float: right;
      opacity: 0.35;
      margin-top: 1px;
      margin-right: -8px; }

.btn.btn {
  border-width: 1px; }

.btn.btn-primary, .btn.btn-secondary, .btn.btn-dark, .btn.btn-outline-primary, .btn.btn-outline-dark {
  text-transform: uppercase; }
  .btn.btn-primary:hover, .btn.btn-secondary:hover, .btn.btn-dark:hover, .btn.btn-outline-primary:hover, .btn.btn-outline-dark:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .btn.btn-primary.btn-sm, .btn.btn-secondary.btn-sm, .btn.btn-dark.btn-sm, .btn.btn-outline-primary.btn-sm, .btn.btn-outline-dark.btn-sm {
    text-transform: none; }
    .btn.btn-primary.btn-sm:hover, .btn.btn-secondary.btn-sm:hover, .btn.btn-dark.btn-sm:hover, .btn.btn-outline-primary.btn-sm:hover, .btn.btn-outline-dark.btn-sm:hover {
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16); }

.btn:focus-visible {
  outline: none; }

.btn-outline-primary:hover,
.btn-outline-secondary:hover {
  color: #fff; }

.btn-primary,
.action.primary,
.action.action-update,
.action.update,
.action-primary.action-accept {
  color: #fff;
  background-color: #FF1A1A;
  border-color: #FF1A1A; }
  .btn-primary:hover,
  .action.primary:hover,
  .action.action-update:hover,
  .action.update:hover,
  .action-primary.action-accept:hover {
    color: #fff;
    background-color: #d11515;
    border-color: #d11515; }
  .btn-check:focus + .btn-primary, .btn-primary:focus, .btn-check:focus +
  .action.primary,
  .action.primary:focus, .btn-check:focus +
  .action.action-update,
  .action.action-update:focus, .btn-check:focus +
  .action.update,
  .action.update:focus, .btn-check:focus +
  .action-primary.action-accept,
  .action-primary.action-accept:focus {
    color: #fff;
    background-color: #d11515;
    border-color: #d11515;
    box-shadow: 0 0 0 0.25rem rgba(255, 60, 60, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle, .btn-check:checked +
  .action.primary,
  .btn-check:active +
  .action.primary,
  .action.primary:active,
  .action.primary.active,
  .show >
  .action.primary.dropdown-toggle, .btn-check:checked +
  .action.action-update,
  .btn-check:active +
  .action.action-update,
  .action.action-update:active,
  .action.action-update.active,
  .show >
  .action.action-update.dropdown-toggle, .btn-check:checked +
  .action.update,
  .btn-check:active +
  .action.update,
  .action.update:active,
  .action.update.active,
  .show >
  .action.update.dropdown-toggle, .btn-check:checked +
  .action-primary.action-accept,
  .btn-check:active +
  .action-primary.action-accept,
  .action-primary.action-accept:active,
  .action-primary.action-accept.active,
  .show >
  .action-primary.action-accept.dropdown-toggle {
    color: #fff;
    background-color: #cc1515;
    border-color: #bf1414; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus, .btn-check:checked +
    .action.primary:focus,
    .btn-check:active +
    .action.primary:focus,
    .action.primary:active:focus,
    .action.primary.active:focus,
    .show >
    .action.primary.dropdown-toggle:focus, .btn-check:checked +
    .action.action-update:focus,
    .btn-check:active +
    .action.action-update:focus,
    .action.action-update:active:focus,
    .action.action-update.active:focus,
    .show >
    .action.action-update.dropdown-toggle:focus, .btn-check:checked +
    .action.update:focus,
    .btn-check:active +
    .action.update:focus,
    .action.update:active:focus,
    .action.update.active:focus,
    .show >
    .action.update.dropdown-toggle:focus, .btn-check:checked +
    .action-primary.action-accept:focus,
    .btn-check:active +
    .action-primary.action-accept:focus,
    .action-primary.action-accept:active:focus,
    .action-primary.action-accept.active:focus,
    .show >
    .action-primary.action-accept.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 60, 60, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled,
  .action.primary:disabled,
  .action.primary.disabled,
  .action.action-update:disabled,
  .action.action-update.disabled,
  .action.update:disabled,
  .action.update.disabled,
  .action-primary.action-accept:disabled,
  .action-primary.action-accept.disabled {
    color: #B2B2B2;
    background-color: #D3D3D3;
    border-color: #D3D3D3; }

.btn-secondary,
.action.secondary,
.action.action-cancel,
.action.reload {
  color: #fff;
  background-color: #6F75FF;
  border-color: #6F75FF; }
  .btn-secondary:hover,
  .action.secondary:hover,
  .action.action-cancel:hover,
  .action.reload:hover {
    color: #fff;
    background-color: #5b60d1;
    border-color: #5b60d1; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-check:focus +
  .action.secondary,
  .action.secondary:focus, .btn-check:focus +
  .action.action-cancel,
  .action.action-cancel:focus, .btn-check:focus +
  .action.reload,
  .action.reload:focus {
    color: #fff;
    background-color: #5b60d1;
    border-color: #5b60d1;
    box-shadow: 0 0 0 0.25rem rgba(133, 138, 255, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle, .btn-check:checked +
  .action.secondary,
  .btn-check:active +
  .action.secondary,
  .action.secondary:active,
  .action.secondary.active,
  .show >
  .action.secondary.dropdown-toggle, .btn-check:checked +
  .action.action-cancel,
  .btn-check:active +
  .action.action-cancel,
  .action.action-cancel:active,
  .action.action-cancel.active,
  .show >
  .action.action-cancel.dropdown-toggle, .btn-check:checked +
  .action.reload,
  .btn-check:active +
  .action.reload,
  .action.reload:active,
  .action.reload.active,
  .show >
  .action.reload.dropdown-toggle {
    color: #fff;
    background-color: #595ecc;
    border-color: #5358bf; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus, .btn-check:checked +
    .action.secondary:focus,
    .btn-check:active +
    .action.secondary:focus,
    .action.secondary:active:focus,
    .action.secondary.active:focus,
    .show >
    .action.secondary.dropdown-toggle:focus, .btn-check:checked +
    .action.action-cancel:focus,
    .btn-check:active +
    .action.action-cancel:focus,
    .action.action-cancel:active:focus,
    .action.action-cancel.active:focus,
    .show >
    .action.action-cancel.dropdown-toggle:focus, .btn-check:checked +
    .action.reload:focus,
    .btn-check:active +
    .action.reload:focus,
    .action.reload:active:focus,
    .action.reload.active:focus,
    .show >
    .action.reload.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(133, 138, 255, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled,
  .action.secondary:disabled,
  .action.secondary.disabled,
  .action.action-cancel:disabled,
  .action.action-cancel.disabled,
  .action.reload:disabled,
  .action.reload.disabled {
    color: #B2B2B2;
    background-color: #D3D3D3;
    border-color: #D3D3D3; }

.btn-white {
  color: #212221;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #fff;
    background-color: #212221;
    border-color: #212221; }
  .btn-check:focus + .btn-white, .btn-white:focus {
    color: #fff;
    background-color: #212221;
    border-color: #212221;
    box-shadow: 0 0 0 0.25rem rgba(222, 222, 222, 0.5); }
  .btn-check:checked + .btn-white,
  .btn-check:active + .btn-white, .btn-white:active, .btn-white.active,
  .show > .btn-white.dropdown-toggle {
    color: #000;
    background-color: white;
    border-color: white; }
    .btn-check:checked + .btn-white:focus,
    .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(222, 222, 222, 0.5); }
  .btn-white:disabled, .btn-white.disabled {
    color: #B2B2B2;
    background-color: #D3D3D3;
    border-color: #D3D3D3; }

.action-primary::before,
.action-secondary::before,
.action::before {
  display: none !important; }

.action-primary.primary, .action-primary.secondary, .action-primary.action-update, .action-primary.action-cancel, .action-primary.reload, .action-primary.update, .action-primary.action-accept,
.action-secondary.primary,
.action-secondary.secondary,
.action-secondary.action-update,
.action-secondary.action-cancel,
.action-secondary.reload,
.action-secondary.update,
.action-secondary.action-accept,
.action.primary,
.action.secondary,
.action.action-update,
.action.action-cancel,
.action.reload,
.action.update,
.action.action-accept {
  text-transform: uppercase;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  padding: 1.55rem 4.85rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .action-primary.primary:hover, .action-primary.primary:focus, .action-primary.secondary:hover, .action-primary.secondary:focus, .action-primary.action-update:hover, .action-primary.action-update:focus, .action-primary.action-cancel:hover, .action-primary.action-cancel:focus, .action-primary.reload:hover, .action-primary.reload:focus, .action-primary.update:hover, .action-primary.update:focus, .action-primary.action-accept:hover, .action-primary.action-accept:focus,
  .action-secondary.primary:hover,
  .action-secondary.primary:focus,
  .action-secondary.secondary:hover,
  .action-secondary.secondary:focus,
  .action-secondary.action-update:hover,
  .action-secondary.action-update:focus,
  .action-secondary.action-cancel:hover,
  .action-secondary.action-cancel:focus,
  .action-secondary.reload:hover,
  .action-secondary.reload:focus,
  .action-secondary.update:hover,
  .action-secondary.update:focus,
  .action-secondary.action-accept:hover,
  .action-secondary.action-accept:focus,
  .action.primary:hover,
  .action.primary:focus,
  .action.secondary:hover,
  .action.secondary:focus,
  .action.action-update:hover,
  .action.action-update:focus,
  .action.action-cancel:hover,
  .action.action-cancel:focus,
  .action.reload:hover,
  .action.reload:focus,
  .action.update:hover,
  .action.update:focus,
  .action.action-accept:hover,
  .action.action-accept:focus {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .action-primary.primary:focus-visible, .action-primary.secondary:focus-visible, .action-primary.action-update:focus-visible, .action-primary.action-cancel:focus-visible, .action-primary.reload:focus-visible, .action-primary.update:focus-visible, .action-primary.action-accept:focus-visible,
  .action-secondary.primary:focus-visible,
  .action-secondary.secondary:focus-visible,
  .action-secondary.action-update:focus-visible,
  .action-secondary.action-cancel:focus-visible,
  .action-secondary.reload:focus-visible,
  .action-secondary.update:focus-visible,
  .action-secondary.action-accept:focus-visible,
  .action.primary:focus-visible,
  .action.secondary:focus-visible,
  .action.action-update:focus-visible,
  .action.action-cancel:focus-visible,
  .action.reload:focus-visible,
  .action.update:focus-visible,
  .action.action-accept:focus-visible {
    outline: none; }

.action-primary.clear, .action-primary.back, .action-primary.link,
.action-secondary.clear,
.action-secondary.back,
.action-secondary.link,
.action.clear,
.action.back,
.action.link {
  background: none;
  border: none;
  text-decoration: underline;
  font-weight: normal; }
  .action-primary.clear:hover, .action-primary.clear:focus, .action-primary.back:hover, .action-primary.back:focus, .action-primary.link:hover, .action-primary.link:focus,
  .action-secondary.clear:hover,
  .action-secondary.clear:focus,
  .action-secondary.back:hover,
  .action-secondary.back:focus,
  .action-secondary.link:hover,
  .action-secondary.link:focus,
  .action.clear:hover,
  .action.clear:focus,
  .action.back:hover,
  .action.back:focus,
  .action.link:hover,
  .action.link:focus {
    text-decoration: none; }

.action-primary.continue, .action-primary.update,
.action-secondary.continue,
.action-secondary.update,
.action.continue,
.action.update {
  padding-left: 4.85rem !important;
  padding-right: 4.85rem !important; }

.btn-close:hover {
  border: none; }

.olark-launch-button-wrapper.olark-launch-button-wrapper {
  z-index: 1040 !important; }

#olark-container .olark-top-bar {
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important; }

.input-text,
.select {
  display: block;
  width: 100%;
  height: 50px;
  padding: 1.55rem 1.5rem;
  font-weight: 400;
  line-height: 1.3;
  color: #262626;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #B2B2B2;
  /* To get around issues with increasing font size on computer/browser */
  font-size: 1.3rem;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .input-text.select,
  .select.select {
    padding: 0.5rem 1.5rem; }
  .input-text:focus,
  .select:focus {
    color: #262626;
    background-color: #fff;
    border-color: #000;
    outline: 0;
    /* stylelint-disable-line */
    box-shadow: none; }
  .input-text::placeholder,
  .select::placeholder {
    color: #B2B2B2;
    opacity: 1; }
  .input-text:disabled, .input-text[readonly],
  .select:disabled,
  .select[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .input-text:not(:first-child),
  .select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    label + .input-text:not(:first-child), label +
    .select:not(:first-child) {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem; }
  .input-text:not(:last-child),
  .select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.form-control,
.input-text,
.select {
  border-width: 1px;
  transition: all 0.2s ease-in-out; }
  .form-control:focus,
  .input-text:focus,
  .select:focus {
    box-shadow: none; }
  .form-control.mage-error,
  .input-text.mage-error,
  .select.mage-error {
    border-color: #FFD8D8; }

div.mage-error.mage-error {
  width: 100%;
  margin-top: 0;
  padding-left: 0.2rem;
  font-size: 1.2rem; }

textarea.input-text {
  height: auto; }

select {
  appearance: none;
  display: block;
  width: 100%;
  height: auto;
  padding: 1.55rem 4.65rem 1.55rem 1.55rem;
  -moz-padding-start: calc(1.55rem - 3px);
  font-weight: 400;
  line-height: 1.3;
  color: #262626;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363836' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.55rem center;
  background-size: 16px 12px;
  border: 1px solid #B2B2B2;
  font-size: 1.3rem;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  select:focus {
    border-color: #000;
    outline: 0;
    /* stylelint-disable-line */
    box-shadow: none; }
  select[multiple], select[size]:not([size="1"]) {
    padding-right: 1.55rem;
    background-image: none; }
  select:disabled {
    background-color: #e9ecef; }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #262626; }

.label {
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: #000;
  font-size: 1.3rem; }

.fieldset {
  margin-bottom: 2.9rem; }
  .fieldset .legend,
  .fieldset .field:not(.choice) > .control,
  .fieldset .field:not(.choice) > .label {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    text-align: left; }
  .fieldset .street .control .label {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important; }
  .fieldset .legend {
    margin-bottom: 1rem; }
  .fieldset .field {
    margin-bottom: 1.2rem; }
    .fieldset .field .field::before {
      display: none; }
    .fieldset .field.choice::before {
      display: none; }
    .fieldset .field.required > .label, .fieldset .field._required > .label {
      display: flex;
      flex-wrap: wrap; }
      .fieldset .field.required > .label::after, .fieldset .field._required > .label::after {
        content: '(Required)';
        color: inherit;
        font-size: inherit;
        margin: 0 0 0 0.3rem; }

.actions-toolbar.actions-toolbar.actions-toolbar.actions-toolbar {
  margin-left: 0; }

.form-check {
  line-height: 1.5;
  font-size: 1.3rem; }
  .form-check .form-check-input[type='checkbox']:not(:checked):hover {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3e%3cpath stroke='%23EAEAEA' d='M18.708 7.708l-8.354 8.353L6 11.708l.707-.707 3.647 3.646L18 7.001z' fill-rule='evenodd'/%3e%3c/svg%3e"); }
  .form-check .form-check-input[type='radio'] {
    margin-top: -0.3rem;
    background-size: 1.4rem; }
    .form-check .form-check-input[type='radio']:not(:checked):hover {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23EAEAEA'/%3e%3c/svg%3e"); }

.custom-email::placeholder,
.custom-password::placeholder {
  color: #262626; }

.field.email .control::before {
  background-image: url(../images/icon-email.svg); }

.field.password .control::before {
  background-image: url(../images/icon-password.svg); }

.field.email .control, .field.password .control {
  position: relative; }
  .field.email .control::before, .field.password .control::before {
    content: '';
    position: absolute;
    top: 1.3rem;
    left: 1.5rem;
    z-index: 3;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    pointer-events: none; }

.field.email .form-control,
.field.email .input-text, .field.password .form-control,
.field.password .input-text {
  padding-left: 4.9rem; }

.modals-overlay {
  background-color: rgba(30, 31, 30, 0.41); }

@media (min-width: 768px) {
  .modal-popup .modal-header,
  .modal-popup .modal-content,
  .modal-popup .modal-footer {
    padding-left: 5.9rem;
    padding-right: 5.9rem; } }

.modal-popup .modal-title {
  font-weight: 400;
  font-size: 2.8rem;
  border-bottom: none; }

.modal-popup .action-close {
  width: 50px;
  height: 50px;
  background: url(../images/icon-close.svg);
  background-size: 32px 32px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  font-size: 0; }

.modal-popup.modal-slide .modal-footer {
  border-top: none; }

.nav-toggle.nav-toggle.nav-toggle.nav-toggle {
  display: none; }
  @media (max-width: 1023.98px) {
    .nav-toggle.nav-toggle.nav-toggle.nav-toggle {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 62px;
      padding: 0;
      border: none;
      border-radius: 0; }
      .nav-toggle.nav-toggle.nav-toggle.nav-toggle::before {
        display: none; }
      .nav-toggle.nav-toggle.nav-toggle.nav-toggle:focus, .nav-toggle.nav-toggle.nav-toggle.nav-toggle:active {
        background: none;
        outline: none;
        box-shadow: none;
        border: none; }
      .nav-toggle.nav-toggle.nav-toggle.nav-toggle img:last-child {
        display: none; }
      .nav-open .nav-toggle.nav-toggle.nav-toggle.nav-toggle img {
        display: none; } }
  @media print {
    .nav-toggle.nav-toggle.nav-toggle.nav-toggle {
      display: none; } }

.nav-sections,
.nav-sections .navigation {
  background: none; }

.nav-sections .navigation.navigation .ui-menu-icon.ui-menu-icon {
  display: none; }

.offcanvas-header {
  position: relative;
  display: block;
  padding: 1.2rem;
  background-color: #000;
  text-align: center;
  color: #fff; }
  .offcanvas-header .btn-close {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    margin-top: 0;
    margin-bottom: 0;
    background-size: 32px 32px; }

.offcanvas-title {
  font-size: 1.8rem; }

/* Allowing responsive offcanvas (disable on desktop, enable on mobile */
@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: 414px;
      border-right: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: 414px;
      border-left: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: 30vh;
      max-height: 100%;
      border-bottom: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: 30vh;
      max-height: 100%;
      border-top: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    background-color: transparent !important;
    transform: none;
    width: auto; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; }
    .offcanvas-sm + .offcanvas-backdrop {
      display: none; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: 414px;
      border-right: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: 414px;
      border-left: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: auto;
      max-height: 100%;
      border-bottom: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: auto;
      max-height: 100%;
      border-top: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    background-color: transparent !important;
    transform: none;
    width: auto; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; }
    .offcanvas-md + .offcanvas-backdrop {
      display: none; } }

@media (max-width: 1023.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: 414px;
      border-right: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: 414px;
      border-left: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: auto;
      max-height: 100%;
      border-bottom: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: auto;
      max-height: 100%;
      border-top: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 1024px) {
  .offcanvas-lg {
    background-color: transparent !important;
    transform: none;
    width: auto; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; }
    .offcanvas-lg + .offcanvas-backdrop {
      display: none; } }

@media (max-width: 1365.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: 414px;
      border-right: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: 414px;
      border-left: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: auto;
      max-height: 100%;
      border-bottom: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: auto;
      max-height: 100%;
      border-top: 0 solid rgba(0, 0, 0, 0.2);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1366px) {
  .offcanvas-xl {
    background-color: transparent !important;
    transform: none;
    width: auto; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; }
    .offcanvas-xl + .offcanvas-backdrop {
      display: none; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: 414px;
    border-right: 0 solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: 414px;
    border-left: 0 solid rgba(0, 0, 0, 0.2);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: auto;
    max-height: 100%;
    border-bottom: 0 solid rgba(0, 0, 0, 0.2);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: auto;
    max-height: 100%;
    border-top: 0 solid rgba(0, 0, 0, 0.2);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.pagination {
  font-size: 1.3rem; }
  .pagination .page-item:not(:last-child) {
    margin-right: 1rem; }
  .pagination .page-item:not(.disabled) .prev:hover::before,
  .pagination .page-item:not(.disabled) .next:hover::before {
    filter: invert(1) grayscale(100%) brightness(200%); }
  .pagination .page-item.disabled .prev::before,
  .pagination .page-item.disabled .next::before {
    opacity: 0.3; }
  .pagination .page-item.active .page-link {
    font-weight: 400; }
  .pagination .page-link {
    width: 30px;
    height: 30px;
    line-height: 29.055px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16); }
    .pagination .page-link .label {
      display: none; }
  .pagination .prev,
  .pagination .next {
    position: relative;
    font-size: 0; }
    .pagination .prev::before,
    .pagination .next::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M10.06 18.531 9 17.47 14.47 12 9 6.53l1.06-1.06L16.59 12z'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 24px; }
  .pagination .prev::before {
    transform: rotate(180deg); }

@media (min-width: 1366px) {
  .category-grid .pagebuilder-column-group {
    margin: 0 -1.15rem;
    padding: 0 2.3rem; } }

.category-grid .pagebuilder-column {
  padding: 0 1.7rem 3rem; }
  @media (min-width: 768px) {
    .category-grid .pagebuilder-column {
      padding: 0 2.3rem 2.3rem; } }
  .category-grid .pagebuilder-column .category-block {
    padding: 2rem; }

.category-grid .pagebuilder-banner-wrapper {
  min-height: 0 !important;
  background-position: top !important;
  background-size: contain !important; }

.category-grid [data-element='content'] {
  min-height: 0;
  overflow: visible; }

.category-grid .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto;
  padding: 100% 0 0; }

.category-grid ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .category-grid ul li {
    margin-bottom: 3rem; }

.category-grid .category-block {
  position: relative;
  padding: 1.5rem;
  background-color: #fff;
  text-align: left;
  transition: all 0.2s ease-in-out; }
  @media (min-width: 768px) {
    .category-grid .category-block {
      padding: 2.5rem; } }
  .category-grid .category-block:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06); }
  .category-grid .category-block .cat-image {
    text-align: center; }
  .category-grid .category-block .cat-name a,
  .category-grid .category-block .pagebuilder-overlay a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #262626;
    font-weight: 700;
    font-size: 1.5rem; }
    @media (max-width: 767.98px) {
      .category-grid .category-block .cat-name a,
      .category-grid .category-block .pagebuilder-overlay a {
        font-size: 1.3rem; } }
    .category-grid .category-block .cat-name a:hover,
    .category-grid .category-block .pagebuilder-overlay a:hover {
      text-decoration: none; }
      .category-grid .category-block .cat-name a:hover strong,
      .category-grid .category-block .cat-name a:hover span, .category-grid .category-block .cat-name a:hover::after,
      .category-grid .category-block .pagebuilder-overlay a:hover strong,
      .category-grid .category-block .pagebuilder-overlay a:hover span,
      .category-grid .category-block .pagebuilder-overlay a:hover::after {
        transform: translateY(-10px); }
    .category-grid .category-block .cat-name a::before,
    .category-grid .category-block .pagebuilder-overlay a::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .category-grid .category-block .cat-name a strong,
    .category-grid .category-block .cat-name a span,
    .category-grid .category-block .pagebuilder-overlay a strong,
    .category-grid .category-block .pagebuilder-overlay a span {
      transition: transform 0.3s ease-out; }
    .category-grid .category-block .cat-name a::after,
    .category-grid .category-block .pagebuilder-overlay a::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='m16.297 7.794-1.414 1.414 6.293 6.293H7.59v2h13.586l-6.293 6.293 1.414 1.414 8.707-8.707z'/%3E%3C/svg%3E");
      background-size: 24px 24px;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-top: 0.1rem;
      margin-right: 0.5rem;
      transition: transform 0.3s ease-out; }
  .category-grid .category-block .pagebuilder-overlay p {
    margin-bottom: 1.3rem; }
  .category-grid .category-block .pagebuilder-overlay ul {
    display: flex;
    flex-wrap: wrap; }
    .category-grid .category-block .pagebuilder-overlay ul li {
      margin: 0 1rem 1.3rem 0; }
    .category-grid .category-block .pagebuilder-overlay ul a {
      position: relative;
      z-index: 1;
      padding: 0.37rem 1.365rem;
      line-height: 1.8;
      font-weight: 400;
      font-size: 1.2rem;
      background-color: #E6E6E6;
      border-radius: 50rem;
      transition: all 0.2s ease-in-out; }
      .category-grid .category-block .pagebuilder-overlay ul a::before, .category-grid .category-block .pagebuilder-overlay ul a::after {
        display: none; }
      .category-grid .category-block .pagebuilder-overlay ul a:hover {
        background-color: #212221;
        color: #F7F7F7; }

.category-banner p:last-child a:only-child {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  color: #fff;
  background-color: #FF1A1A;
  border-color: #FF1A1A;
  padding: 1.55rem 4.85rem;
  font-size: 1.3rem;
  border-radius: 0.2rem; }
  .category-banner p:last-child a:only-child:hover {
    color: #fff;
    background-color: #d11515;
    border-color: #d11515; }
  .btn-check:focus + .category-banner p:last-child a:only-child, .category-banner p:last-child a:only-child:focus {
    color: #fff;
    background-color: #d11515;
    border-color: #d11515;
    box-shadow: 0 0 0 0.25rem rgba(255, 60, 60, 0.5); }
  .btn-check:checked + .category-banner p:last-child a:only-child,
  .btn-check:active + .category-banner p:last-child a:only-child, .category-banner p:last-child a:only-child:active, .category-banner p:last-child a:only-child.active,
  .show > .category-banner p:last-child a:only-child.dropdown-toggle {
    color: #fff;
    background-color: #cc1515;
    border-color: #bf1414; }
    .btn-check:checked + .category-banner p:last-child a:only-child:focus,
    .btn-check:active + .category-banner p:last-child a:only-child:focus, .category-banner p:last-child a:only-child:active:focus, .category-banner p:last-child a:only-child.active:focus,
    .show > .category-banner p:last-child a:only-child.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 60, 60, 0.5); }
  .category-banner p:last-child a:only-child:disabled, .category-banner p:last-child a:only-child.disabled {
    color: #B2B2B2;
    background-color: #D3D3D3;
    border-color: #D3D3D3; }

.catalog-category-view .product-item .price-container .weee::after {
  content: ''; }

.menu-content {
  background: #E6E6E6;
  position: relative; }
  @media (min-width: 768px) {
    .menu-content {
      z-index: -1; } }
  .menu-content .menu-content-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0; }
  .menu-content [data-content-type=row][data-appearance=contained] {
    flex-grow: 1; }
    .menu-content [data-content-type=row][data-appearance=contained] [data-element=inner] {
      padding: 0; }
  .menu-content .product-count {
    color: #9e9e9e; }
  .menu-content a {
    color: #1e1e1e; }
    .menu-content a.is-hot {
      display: flex;
      align-items: center;
      gap: 0.5rem; }
      .menu-content a.is-hot::before {
        background: url(../images/icon-heat.svg);
        background-size: cover;
        content: '';
        display: block;
        width: 18px;
        height: 20px; }
  .menu-content ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .menu-content ul li {
      margin: 0; }

.desktop-menu-dropdown-toggle {
  border: 0;
  background: none;
  margin: 0.6rem 1.8rem 0.6rem 0;
  min-width: 200px;
  padding-left: 0;
  border-right: 1px solid #B2B2B2;
  display: flex;
  align-items: center;
  gap: 0.2rem; }

.menu-static-links {
  flex-grow: 1;
  display: flex; }
  @media (max-width: 1023.98px) {
    .menu-static-links {
      justify-content: center; } }
  .menu-static-links > ul {
    display: flex;
    align-items: center;
    z-index: 9; }
    .menu-static-links > ul.link-left {
      margin-right: auto; }
      @media (max-width: 1023.98px) {
        .menu-static-links > ul.link-left {
          display: none; } }
    .menu-static-links > ul > li {
      display: flex;
      align-items: center;
      align-self: stretch;
      margin: 0;
      padding: 1rem 1.5rem 1rem; }
      @media (min-width: 1366px) {
        .menu-static-links > ul > li {
          padding: 1rem 1.8rem 1rem; } }
      .menu-static-links > ul > li.help-dropdown {
        padding-right: 2rem; }

.help-dropdown {
  position: relative;
  background: url(../images/icon-down-red.svg);
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer; }
  .help-dropdown .help-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    padding: 1.8rem 2rem;
    border: 1px solid #B2B2B2;
    background: #fff;
    min-width: 218px; }
    .help-dropdown .help-dropdown-content > ul {
      flex-direction: column;
      gap: 0;
      align-items: flex-start; }
  .help-dropdown:hover .help-dropdown-content {
    display: block; }

@media (min-width: 1024px) {
  .nav-sections {
    width: 100%;
    display: none;
    background-color: #F7F7F7;
    position: absolute;
    margin-bottom: 0;
    left: 0;
    top: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15); }
    .nav-sections .navigation {
      max-width: none;
      padding-left: 0;
      padding-right: 0; }
      .nav-sections .navigation .show-all {
        display: none !important; }
      .nav-sections .navigation > .container, .nav-sections .navigation > .navigation, .nav-sections .navigation > .breadcrumb, .nav-sections .page-header .navigation > .header.panel, .page-header .nav-sections .navigation > .header.panel, .nav-sections .navigation > .header.content, .nav-sections .navigation > .search-autocomplete, .nav-sections .page-wrapper.navigation > .widget, .nav-sections .page-wrapper.navigation > .page-bottom, .nav-sections .navigation > .block.category.event, .nav-sections .navigation > .top-container, .nav-sections .navigation > .page-main {
        padding: 0; }
      .nav-sections .navigation > .container > ul, .nav-sections .navigation > .navigation > ul, .nav-sections .navigation > .breadcrumb > ul, .nav-sections .page-header .navigation > .header.panel > ul, .page-header .nav-sections .navigation > .header.panel > ul, .nav-sections .navigation > .header.content > ul, .nav-sections .navigation > .search-autocomplete > ul, .nav-sections .page-wrapper.navigation > .widget > ul, .nav-sections .page-wrapper.navigation > .page-bottom > ul, .nav-sections .navigation > .block.category.event > ul, .nav-sections .navigation > .top-container > ul, .nav-sections .navigation > .page-main > ul {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        padding: 2rem 0; }
      .nav-sections .navigation li.level0 {
        position: static;
        margin-right: 0;
        max-width: 200px;
        border-right: 1px solid #B2B2B2; }
        .nav-sections .navigation li.level0 > a.level-top {
          font-size: 1.5rem;
          color: #1e1e1e;
          font-weight: 400;
          border: none;
          padding: 0.5rem 2rem 0.5rem 3.5rem;
          display: block;
          line-height: 20px;
          white-space: nowrap; }
          .nav-sections .navigation li.level0 > a.level-top.is-hot {
            display: flex; }
        .nav-sections .navigation li.level0:hover::before,
        .nav-sections .navigation li.level0:hover ul.level0 {
          opacity: 1;
          visibility: visible; }
        .nav-sections .navigation li.level0:hover > a {
          font-weight: 700; }
      .nav-sections .navigation .level0:hover::after {
        display: none !important; }
      .nav-sections .navigation ul.level0 {
        position: absolute;
        top: 0 !important;
        left: 200px !important;
        right: 0 !important;
        display: flex !important;
        padding: 2rem;
        justify-content: flex-start;
        transition: 0.2s ease-out 0.2s;
        background: none;
        opacity: 0;
        visibility: hidden;
        flex-wrap: wrap;
        border: none;
        box-shadow: none;
        height: 279px; }
        .nav-sections .navigation ul.level0 li.level1 {
          margin-right: 1rem; } }
        @media (min-width: 1024px) and (min-width: 1366px) {
          .nav-sections .navigation ul.level0 li.level1 {
            margin-right: 9rem; } }

@media (min-width: 1024px) {
          .nav-sections .navigation ul.level0 li.level1 > a {
            font-size: 1.5rem;
            color: #1e1e1e;
            background-color: transparent;
            border-color: transparent;
            border: none;
            padding: 0.5rem 1.6rem;
            line-height: 20px;
            font-weight: 400; }
            .nav-sections .navigation ul.level0 li.level1 > a:hover {
              font-weight: 700; }
            .nav-sections .navigation ul.level0 li.level1 > a .ui-icon-carat-1-e {
              display: none; }
        .nav-sections .navigation ul.level0 a:hover {
          text-decoration: underline; }
      .nav-sections .navigation ul.level1.level1.level1 {
        position: static;
        top: auto !important;
        left: auto !important;
        box-shadow: none;
        display: block !important;
        border: none; }
        .nav-sections .navigation ul.level1.level1.level1 li.level2 > a {
          font-size: 1.3rem;
          font-weight: 400;
          color: #262626;
          background-color: transparent;
          border-color: transparent;
          border: none; } }

@media (max-width: 1023.98px) {
  .nav-open .amsearch-wrapper-block {
    z-index: 0; }
  .nav-open .nav-sections {
    left: 0;
    height: 100vh;
    height: 100dvh;
    overscroll-behavior: contain;
    background: #fff; } }
  @media (max-width: 1023.98px) and (min-width: 468px) {
    .nav-open .nav-sections {
      max-width: 414px; } }

@media (max-width: 1023.98px) {
  .nav-open .nav-toggle::after {
    height: 100vh; }
  .nav-before-open .page-wrapper {
    overflow: visible; } }

@media (max-width: 1023.98px) and (min-width: 468px) {
  .nav-open .page-wrapper {
    left: 414px; } }

@media (max-width: 1023.98px) {
  .nav-sections .nav-sections-items {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .nav-sections .navigation {
    padding: 0;
    margin-top: 0;
    margin-bottom: 10px; }
    .nav-sections .navigation .mobile-menu-closer-container {
      text-align: right;
      height: 60px; }
      .nav-sections .navigation .mobile-menu-closer-container .mobile-menu-closer {
        padding: 12px 15px; }
    .nav-sections .navigation > ul.child-open {
      margin-top: -60px !important; }
    .nav-sections .navigation a {
      padding: 15px 30px !important;
      border: none !important;
      line-height: 1.5 !important; }
      .nav-sections .navigation a span:not(.ui-menu-icon) {
        margin: 0 !important; }
    .nav-sections .navigation .show-all a {
      padding: 1.65rem 3rem !important;
      font-size: 1.8rem;
      text-align: center;
      font-weight: 600;
      background-color: #000;
      color: #fff !important; }
    .nav-sections .navigation.navigation ul {
      margin: 0;
      padding: 0; }
    .nav-sections .navigation .level0 {
      border: none; }
  .nav-sections .section-item-title {
    display: none !important; }
  .nav-sections .section-item-content {
    float: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 0;
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0; }
  .nav-sections .all-category {
    display: none !important; }
  .nav-sections ul.submenu.submenu {
    padding-left: 0; }
  .nav-sections .navigation.navigation .level-top,
  .nav-sections a {
    text-transform: none; }
  .nav-sections .navigation .parent a {
    position: relative; }
  .nav-sections .navigation .parent .mobile-menu-toggle {
    display: inline-block;
    width: 60px;
    height: 60px;
    background-image: url(../images/icon-chevron-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%); }
    .nav-sections .navigation .parent .mobile-menu-toggle.toggle-back:not(.x-toggle) {
      background-image: url(../images/icon-chevron-right-white.svg);
      right: auto;
      left: 5px;
      transform: scaleX(-1) translateY(-50%); }
    .nav-sections .navigation .parent .mobile-menu-toggle.x-toggle {
      background-image: url(../images/icon-close-white.svg); }
    .nav-sections .navigation .parent .mobile-menu-toggle.toggle-open {
      width: 52px;
      height: 50px;
      background-size: 24px 24px;
      top: 53%; }
  .nav-sections .navigation .parent > a::after {
    display: none !important; }
  .nav-sections .navigation ul.submenu li:not(.mobile-open) > a {
    display: none !important; }
  .nav-sections .navigation ul.child-open.child-open.child-open > li > a {
    display: none !important; }
  .nav-sections .navigation ul.child-open > li.mobile-open > ul,
  .nav-sections .navigation ul.child-open > li.mobile-open > ul > li > a {
    display: block !important; }
  .nav-sections a,
  .nav-sections .level-top.level-top.level-top {
    color: #000 !important;
    font-size: 1.6rem;
    font-weight: 400;
    border-bottom: 0; }
    .nav-sections a.is-hot,
    .nav-sections .level-top.level-top.level-top.is-hot {
      display: flex; }
  .nav-sections .submenu .active > a,
  .nav-sections .level0.active > a,
  .nav-sections .level0.has-active > a {
    border-color: transparent !important; } }

.extra-menu {
  margin-top: auto;
  background-color: #D31A1A; }
  .extra-menu ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .extra-menu ul li {
      padding: 1.5rem 3rem;
      margin: 0;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
      .extra-menu ul li a {
        display: flex;
        align-items: center;
        color: #fff !important;
        font-weight: 600; }
        .extra-menu ul li a svg,
        .extra-menu ul li a img {
          margin-top: 0.1rem;
          margin-right: 1rem; }
  .extra-menu .sign-in img {
    filter: invert(1) grayscale(100%) brightness(200%); }

.products-grid .product-items {
  display: flex;
  flex-wrap: wrap; }

.products-grid .product-item {
  position: relative; }
  .products-grid .product-item.product-item.product-item {
    padding: 0 1.5rem 2rem 1.5rem; }
  .products-grid .product-item .product-item-photo {
    display: block;
    margin: 0 -1.5rem; }
  .products-grid .product-item .product-item-info {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .products-grid .product-item .product-item-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
  .products-grid .product-item .product-item-inner {
    margin-top: auto; }
  .products-grid .product-item .product-sku {
    display: block;
    color: #B2B2B2;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.5; }
  .products-grid .product-item .product-item-name {
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.1rem; }
  .products-grid .product-item .more-options {
    font-size: 1.3rem;
    color: #212221;
    font-weight: 600; }
  .products-grid .product-item .price-box {
    margin: 0;
    display: flex; }
    .products-grid .product-item .price-box .minimal-price-link,
    .products-grid .product-item .price-box .price-label {
      display: none; }
    .products-grid .product-item .price-box .price {
      font-size: 1.5rem;
      font-weight: 700;
      color: #D31A1A; }
    .products-grid .product-item .price-box > span {
      margin-right: 0.5rem; }
    .products-grid .product-item .price-box .old-price {
      text-decoration: none; }
      .products-grid .product-item .price-box .old-price .price {
        text-decoration: line-through; }
  .products-grid .product-item .product-item-link::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    border: solid 1px #F7F7F7;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
    transition: opacity 0.3s; }
  .products-grid .product-item .product-item-link:hover {
    text-decoration: none; }
  .products-grid .product-item .product-item-link:hover::before {
    opacity: 1; }
  .products-grid .product-item .product-labels {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    padding: 0.8rem; }
    .products-grid .product-item .product-labels span {
      display: inline-block;
      margin-right: 0.1rem;
      margin-bottom: 0.1rem;
      padding: 0.1rem 1.1rem;
      background-color: #fff;
      border: 1px solid #6F75FF;
      color: #6F75FF;
      white-space: nowrap;
      font-size: 1.3rem;
      line-height: 1.6;
      font-weight: 500; }

.block-search {
  position: static;
  float: none;
  flex-grow: 1;
  max-width: 800px;
  width: auto;
  margin: auto;
  padding: 0; }
  @media (max-width: 1023.98px) {
    .block-search {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      max-width: 100%;
      width: 100%;
      padding: 1.5rem;
      background: #fff;
      box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16); } }
  .block-search .input-group .form-control {
    left: auto;
    margin: 0;
    font-size: 1.5rem; }
    @media (max-width: 1023.98px) {
      .block-search .input-group .form-control {
        min-height: 4.5rem;
        padding: 0.87rem 1.5rem; } }
  @media (max-width: 1023.98px) {
    .block-search .input-group .btn {
      padding: 1.25rem 2rem; } }

.search-autocomplete {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0 !important;
  display: none !important;
  background: #fff;
  border-top: 1px solid #e9ecef;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16) !important;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0; }
  .search-autocomplete::after {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 129px;
    right: 0;
    bottom: 0;
    left: auto;
    width: 100%;
    z-index: -1;
    pointer-events: none; }
  @media (min-width: 1024px) {
    .search-autocomplete {
      top: 83px; } }
  @media (max-width: 767.98px) {
    .search-autocomplete {
      position: fixed;
      top: 123px;
      bottom: 123px;
      overflow-y: auto;
      overscroll-behavior: none; } }
  .minisearch:focus-within .search-autocomplete:not(:empty) {
    display: flex !important;
    flex-direction: column; }
  .search-autocomplete .suggestions-header {
    width: 100%;
    margin-bottom: 1.2rem;
    text-align: left; }
  .search-autocomplete .suggestion {
    margin: 0 1rem 1.2rem 0;
    padding: 1rem 1.5rem;
    background-color: #e9ecef;
    color: #212221;
    border-radius: 50rem;
    font-size: 1.2rem;
    text-align: left;
    transition: all 0.2s ease-in-out; }
    .search-autocomplete .suggestion:hover {
      background-color: #212221;
      color: #E6E6E6; }
  .search-autocomplete .popover-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    overflow: revert;
    height: 100%; }
    @media (max-width: 767.98px) {
      .search-autocomplete .popover-container {
        display: block;
        flex-grow: 1;
        height: auto; } }
  .search-autocomplete .suggestions-container {
    display: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 3.6rem 0 3.6rem 3.8rem; }
    @media (min-width: 768px) {
      .search-autocomplete .suggestions-container {
        max-width: 216px;
        width: 100%; } }
    @media (min-width: 1366px) {
      .search-autocomplete .suggestions-container {
        max-width: 366px; } }
    @media (max-width: 767.98px) {
      .search-autocomplete .suggestions-container {
        max-width: 100%;
        width: 100%;
        padding: 3.6rem 2.9rem 0 2.9rem; } }
  .search-autocomplete .products-container {
    width: 100%;
    row-gap: 2.6rem;
    padding: 3.6rem 4rem 9.8rem 3.8rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr); }
    @media (min-width: 1024px) {
      .search-autocomplete .products-container {
        row-gap: 3.5rem; } }
    @media (max-width: 1023.98px) {
      .search-autocomplete .products-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: repeat(3, 1fr); } }
    @media (max-width: 767.98px) {
      .search-autocomplete .products-container {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        row-gap: 2.3rem;
        margin-bottom: 6rem;
        padding: 3.6rem 2.9rem 4rem 2.9rem; }
        .search-autocomplete .products-container .product-result {
          padding: 0; } }
    @media (max-width: 575.98px) {
      .search-autocomplete .products-container {
        grid-template-columns: 1fr; } }
  .search-autocomplete .product-result {
    grid-template-columns: 89px auto;
    grid-template-rows: auto;
    grid-template-areas: 'image productName' 'image price'; }
    .search-autocomplete .product-result:hover {
      background: none;
      text-decoration: underline; }
    .search-autocomplete .product-result > div {
      align-self: flex-end; }
    .search-autocomplete .product-result img {
      object-fit: contain;
      height: 89px; }
    .search-autocomplete .product-result .product-name {
      font-weight: 400;
      line-height: 1.3; }
    .search-autocomplete .product-result .product-price {
      align-self: flex-start;
      color: #D31A1A;
      font-size: 1.3rem;
      font-weight: 700; }
  .search-autocomplete .view-all-footer.view-all-footer {
    position: absolute;
    bottom: 3.7rem;
    right: 4rem;
    font-weight: 700;
    line-height: 1.3;
    color: #262626;
    text-align: center;
    border: 1px solid transparent;
    padding: 0.87rem 2.76rem;
    font-size: 1.3rem;
    border-radius: 0.2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #212221;
    background-color: #fff;
    border-color: #212221; }
    .search-autocomplete .view-all-footer.view-all-footer:hover {
      color: #fff;
      background-color: #1b1c1b;
      border-color: #1b1c1b; }
    .btn-check:focus + .search-autocomplete .view-all-footer.view-all-footer, .search-autocomplete .view-all-footer.view-all-footer:focus {
      color: #fff;
      background-color: #1b1c1b;
      border-color: #1b1c1b;
      box-shadow: 0 0 0 0.25rem rgba(33, 34, 33, 0.5); }
    .btn-check:checked + .search-autocomplete .view-all-footer.view-all-footer,
    .btn-check:active + .search-autocomplete .view-all-footer.view-all-footer, .search-autocomplete .view-all-footer.view-all-footer:active, .search-autocomplete .view-all-footer.view-all-footer.active,
    .show > .search-autocomplete .view-all-footer.view-all-footer.dropdown-toggle {
      color: #000;
      background-color: white;
      border-color: #373837; }
      .btn-check:checked + .search-autocomplete .view-all-footer.view-all-footer:focus,
      .btn-check:active + .search-autocomplete .view-all-footer.view-all-footer:focus, .search-autocomplete .view-all-footer.view-all-footer:active:focus, .search-autocomplete .view-all-footer.view-all-footer.active:focus,
      .show > .search-autocomplete .view-all-footer.view-all-footer.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(33, 34, 33, 0.5); }
    .search-autocomplete .view-all-footer.view-all-footer:disabled, .search-autocomplete .view-all-footer.view-all-footer.disabled {
      color: #000;
      background-color: #fff;
      border-color: #212221; }
    @media (max-width: 575.98px) {
      .search-autocomplete .view-all-footer.view-all-footer {
        position: static;
        margin: 0 3.2rem;
        display: block;
        transform: translateY(-5.5rem); } }

.catalogsearch-result-index .product-item .price-container .weee::after {
  content: ''; }

.page-header .amsearch-input-wrapper {
  width: 100%; }
  .page-header .amsearch-input-wrapper .amsearch-input {
    width: 100%;
    height: 45px;
    border-radius: 0.3rem 0 0 0.3rem;
    padding: 1.9rem 3rem 2rem 2rem;
    border-color: #B2B2B2;
    transition: none; }
    @media (min-width: 1024px) {
      .page-header .amsearch-input-wrapper .amsearch-input {
        height: 65px;
        padding: 1.9rem 3rem 2rem 18rem; } }
    @media (max-width: 575.98px) {
      .page-header .amsearch-input-wrapper .amsearch-input {
        font-size: 1.6rem; } }
  .page-header .amsearch-input-wrapper .-loupe {
    display: none !important; }
  .page-header .amsearch-input-wrapper .-close {
    padding: 1.6rem 2.2rem;
    filter: brightness(0%); }
    .page-header .amsearch-input-wrapper .-close:hover {
      filter: brightness(100%); }

.amsearch-button.-search {
  align-self: stretch;
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: #212221;
  border-color: #212221;
  opacity: 1;
  margin-left: -1px;
  width: 95px;
  flex-shrink: 0;
  padding: 0;
  transition: none; }
  @media (min-width: 1024px) {
    .amsearch-button.-search {
      width: 136px; } }
  @media (max-width: 1023.98px) {
    .amsearch-button.-search {
      font-size: 1.2rem; } }
  .amsearch-button.-search:hover {
    background-color: #000;
    border-color: #000; }

.amsearch-highlight {
  font-weight: inherit;
  color: inherit; }

.amsearch-form-block,
.amsearch-wrapper-block {
  position: static; }

.amsearch-wrapper-block.amsearch-wrapper-block {
  float: none;
  flex-grow: 1;
  max-width: 800px;
  margin: auto; }
  .amsearch-wrapper-block.amsearch-wrapper-block::before {
    width: 100% !important;
    margin: 0 !important;
    left: 0; }
  .amsearch-wrapper-block.amsearch-wrapper-block .selected-category-name {
    position: absolute;
    left: 0;
    top: 1.25rem;
    z-index: 1;
    cursor: pointer;
    height: 4rem;
    width: 15.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-right: 1px solid #B2B2B2; }
  .amsearch-wrapper-block.amsearch-wrapper-block .category-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    margin-top: 0.2rem;
    width: 19.8rem;
    background: #fff;
    border: 1px solid #B2B2B2;
    padding: 1.5rem 2.2rem;
    z-index: 1; }
    .amsearch-wrapper-block.amsearch-wrapper-block .category-list li {
      margin: 0;
      cursor: pointer; }
      .amsearch-wrapper-block.amsearch-wrapper-block .category-list li:hover {
        font-weight: 700; }
      .amsearch-wrapper-block.amsearch-wrapper-block .category-list li.is-hot {
        display: flex;
        align-items: center;
        gap: 0.5rem; }
        .amsearch-wrapper-block.amsearch-wrapper-block .category-list li.is-hot::before {
          background: url(../images/icon-heat.svg);
          background-size: cover;
          content: '';
          display: block;
          width: 18px;
          height: 20px; }
      .amsearch-wrapper-block.amsearch-wrapper-block .category-list li a {
        color: #262626; }
        .amsearch-wrapper-block.amsearch-wrapper-block .category-list li a:hover {
          text-decoration: none; }
  @media (max-width: 1023.98px) {
    .amsearch-wrapper-block.amsearch-wrapper-block {
      order: 1;
      width: 100%;
      max-width: 100%;
      padding: 1.5rem;
      box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16); }
      .amsearch-wrapper-block.amsearch-wrapper-block .category-dropdown {
        display: none; } }

.amsearch-form-block {
  max-width: 100%;
  width: auto !important;
  transform: none;
  position: relative; }
  .amsearch-form-block.-opened {
    z-index: 100; }
  .amsearch-form-block:hover .amsearch-input {
    background-color: #F7F7F7; }
  .amsearch-form-block:focus-within .amsearch-input {
    border-color: #D31A1A #212221 #D31A1A #D31A1A;
    border-width: 2px 1px 2px 2px;
    outline: none;
    padding-left: 1.9rem; }
    @media (min-width: 1024px) {
      .amsearch-form-block:focus-within .amsearch-input {
        padding-left: 18rem; } }
  .amsearch-form-block:focus-within .amsearch-button.-search {
    border-color: #D31A1A #D31A1A #D31A1A #212221;
    border-width: 2px; }

@media (max-width: 767.98px) {
  .-amsearch-overlay-opened {
    height: 100vh;
    height: 100lvh;
    overflow-y: hidden;
    overscroll-behavior: contain; }
    .-amsearch-overlay-opened .page-wrapper {
      height: 100vh; } }

.amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section {
  width: auto;
  max-height: 80vh;
  left: 0;
  right: 0;
  animation: none;
  border-radius: 0;
  margin-top: -1px;
  padding: 0 2.9rem;
  box-shadow: none; }
  @media (max-width: 767.98px) {
    .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section {
      max-height: calc(100vh - 200px); } }
  @media (min-width: 768px) {
    .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section {
      padding: 0 2rem;
      box-shadow: 0 2px 4px rgba(84, 95, 125, 0.12), 0 5px 8px rgba(155, 159, 174, 0.1), inset 1px 1px 0 #eaeaea; }
      .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section .amsearch-products-section {
        padding-bottom: 8rem; } }
  @media (min-width: 1024px) {
    .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section {
      margin-top: 0;
      padding: 0 4rem; }
      .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section .amsearch-items-section:not(:first-child:last-child) {
        width: 25%;
        max-width: none;
        padding-top: 3rem; }
      .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section .amsearch-products-section {
        width: 75%;
        padding-top: 3rem; }
        .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section .amsearch-products-section:only-child {
          width: 100%; } }
  @media (min-width: 1366px) {
    .amsearch-result-section.amsearch-result-section.amsearch-result-section.amsearch-result-section {
      left: -2.2rem;
      right: -2.2rem; } }

.amsearch-item-container {
  padding-bottom: 3.2rem; }
  .amsearch-item-container .amsearch-block-header {
    padding: 0 0 1.3rem 0; }

.amsearch-products-section .amsearch-block-header {
  padding: 0 0 1.3rem 1.3rem; }

@media (max-width: 767.98px) {
  .amsearch-products-section {
    border-top: none;
    padding-top: 0;
    padding-bottom: 13rem; }
    .amsearch-products-section .amsearch-block-header {
      padding-left: 0; }
    .amsearch-products-section:only-child {
      padding-top: 2rem; } }

.amsearch-block-header .amsearch-title {
  text-transform: none;
  font-size: 1.5rem;
  font-weight: 700; }

.amsearch-products-section.-grid:not(.-slider) .amsearch-product-list {
  display: flex;
  width: 100%; }
  .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item {
    max-width: 100%;
    width: 100%; }
    @media (min-width: 768px) {
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item {
        max-width: 50%;
        width: 50%; } }
    @media (min-width: 1024px) {
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item {
        max-width: 33.3%;
        width: 33.3%; } }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item {
      display: flex;
      flex-wrap: nowrap;
      padding: 1.3rem; }
      @media (max-width: 767.98px) {
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item {
          padding-left: 0;
          padding-right: 0; }
          .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item + .product-item {
            padding-top: 0; } }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item:hover {
        background-color: transparent; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .amsearch-image {
        width: 120px;
        padding-right: 0.9rem;
        padding-bottom: 0; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .amsearch-description {
        padding-top: 1rem; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .amsearch-sku-block {
        font-size: 1.3rem;
        font-weight: 700;
        color: #B2B2B2;
        line-height: 1.4;
        padding-bottom: 0; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .product-item-link {
        font-size: 1.5rem;
        font-weight: 400;
        color: #363836;
        line-height: 1.3;
        padding-bottom: 0; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box {
        color: #D31A1A;
        padding-bottom: 0; }
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box span {
          flex-basis: auto;
          padding: 0; }
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box .price-final_price,
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box .normal-price,
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box .price {
          color: #D31A1A;
          font-size: 1.3rem;
          font-weight: 700;
          line-height: 1.7; }
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box .old-price,
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box .special-price {
          display: inline-block;
          flex-basis: auto;
          margin-right: 0.5rem;
          line-height: 1.2; }
        .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box .old-price {
          text-decoration: none; }
          .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-box .old-price .price {
            text-decoration: line-through; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .price-label,
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .minimal-price-link {
        display: none; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-product-list .amsearch-item.product-item .more-colours {
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.4; }

.amsearch-products-section:not(:first-child) {
  border-left: none; }

.amsearch-item-container .amsearch-tab-items {
  display: flex !important;
  flex-wrap: wrap; }
  .amsearch-item-container .amsearch-tab-items .amsearch-item {
    display: inline-block; }
    .amsearch-item-container .amsearch-tab-items .amsearch-item:hover {
      background-color: transparent; }
    .amsearch-item-container .amsearch-tab-items .amsearch-item .amsearch-link {
      padding: 0.2rem 1.5rem;
      margin: 0 1rem 1rem 0;
      background-color: #E6E6E6;
      white-space: nowrap;
      border-radius: 50px;
      line-height: 2.084;
      font-size: 1.2rem;
      color: #212221; }
      .amsearch-item-container .amsearch-tab-items .amsearch-item .amsearch-link:hover {
        background-color: #212221;
        color: #fff; }

.amsearch-products-section .amsearch-block-header .amsearch-link {
  color: #212221;
  padding: 0.36rem 3.25rem;
  border: solid 1px #212221;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  position: absolute;
  bottom: 3.7rem;
  right: 0;
  z-index: 10;
  background-color: #fff;
  color: #212221; }
  @media (max-width: 767.98px) {
    .amsearch-products-section .amsearch-block-header .amsearch-link {
      left: 0.4rem;
      right: 0.4rem;
      bottom: 5.5rem;
      text-align: center; } }
  .amsearch-products-section .amsearch-block-header .amsearch-link:hover {
    background-color: #212221;
    color: #fff;
    text-decoration: none; }
  .amsearch-products-section .amsearch-block-header .amsearch-link .amsearch-count::before,
  .amsearch-products-section .amsearch-block-header .amsearch-link .amsearch-count::after {
    display: none; }

.amsearch-collapsible-title::after {
  display: none !important; }

.amsearch-overlay-block {
  background-color: rgba(30, 31, 30, 0.7);
  z-index: 1; }
  @media (max-width: 575.98px) {
    .amsearch-overlay-block {
      height: 100lvh; } }

.amsearch-message-block {
  margin-left: 0;
  margin-right: 0; }

body.-amsearch-overlay-opened {
  overflow: hidden;
  touch-action: none; }

body.-amsearch-overlay-opened .logo,
body.-amsearch-overlay-opened .nav-sections,
body.-amsearch-overlay-opened .menu-content,
body.-amsearch-overlay-opened .page-header .panel.wrapper {
  z-index: 2; }

body.-amsearch-overlay-opened .page-header .header-content {
  z-index: 3; }

.amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove.amshopby-remove.amshopby-remove {
  padding-top: 0;
  padding-bottom: 0;
  height: 28px;
  line-height: 28px; }
  .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove.amshopby-remove.amshopby-remove .btn-close {
    margin-top: auto !important;
    margin-bottom: auto !important; }

.spinner-lg {
  width: 4rem;
  height: 4rem; }

.fotorama__spinner.fotorama__spinner--show {
  display: inline-block;
  vertical-align: text-bottom;
  width: 4rem;
  height: 4rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  color: #FF1A1A !important;
  background-image: none;
  transform: none; }

.tooltip-inner {
  line-height: 1.25; }

.small-tooltip {
  background-image: url(../images/icon-question.svg);
  display: inline-block;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; }

.ose-store-notice {
  min-height: 4.5rem; }
  .ose-store-notice .notice-content {
    height: 100%; }
    .ose-store-notice .notice-content > p {
      height: 100%;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }

.page-layout-2columns-left .columns {
  display: flex; }
  @media (min-width: 1024px) {
    .page-layout-2columns-left .columns {
      flex-wrap: nowrap; } }
  .page-layout-2columns-left .columns::after {
    display: none; }

.page-layout-2columns-left .column.main {
  flex: 0 1 100%;
  width: auto;
  padding-left: 0; }
  @media (min-width: 1024px) {
    .page-layout-2columns-left .column.main {
      flex-basis: 650px;
      margin-left: auto; } }
  @media (min-width: 1366px) {
    .page-layout-2columns-left .column.main {
      flex-basis: 965px;
      margin-right: auto; } }

@media (min-width: 1024px) {
  .page-layout-2columns-left .sidebar-main {
    order: -1;
    flex: 0 1 287px;
    margin-right: 2rem;
    padding-right: 0; } }

@media (min-width: 1366px) {
  .page-layout-2columns-left .sidebar-main {
    margin-right: 4rem; } }

.prefooter {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 4rem 2rem;
  background-color: #D31A1A;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300; }
  @media (min-width: 768px) {
    .prefooter {
      padding: 2.775rem 0;
      padding: 3rem 0.5rem;
      font-size: 1.6rem; }
      .prefooter > div {
        max-width: 1150px; } }
  @media (max-width: 767.98px) {
    .prefooter {
      line-height: 1.6;
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  .prefooter [data-element='inner'] {
    flex-direction: row !important;
    align-items: center; }
  .prefooter [data-content-type='text'] {
    flex-grow: 1; }
    .prefooter [data-content-type='text'] *:last-child {
      margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .prefooter [data-content-type='html'] {
      position: absolute;
      top: 4rem;
      right: 1.9rem; } }
  .prefooter h2, .prefooter .h2 {
    margin-bottom: 2.5rem;
    font-size: 2.4rem; }
    @media (min-width: 768px) {
      .prefooter h2, .prefooter .h2 {
        margin-bottom: 0.5rem;
        font-size: 2.2rem; } }
  .prefooter .btn {
    padding: 1.5rem 3.85rem;
    font-size: 1.4rem;
    white-space: nowrap; }
    @media (max-width: 767.98px) {
      .prefooter .btn {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (max-width: 345px) {
      .prefooter .btn {
        padding-left: 2.3rem;
        padding-right: 2.3rem; } }

.page-footer .footer.content {
  max-width: none;
  margin: 0;
  padding: 0; }

.page-footer .footer-container {
  padding: 7.4rem 0 6.2rem;
  background-color: #212221;
  color: #fff;
  font-size: 1.3rem; }
  @media (max-width: 767.98px) {
    .page-footer .footer-container {
      padding: 0; } }
  @media (min-width: 768px) {
    .page-footer .footer-container .footer-links {
      max-width: 1165px; } }
  .page-footer .footer-container a {
    color: inherit !important; }

.page-footer .footer {
  border-top: none; }

@media (max-width: 767.98px) {
  .page-footer .footer-links {
    max-width: 100%; } }

.page-footer .accordion {
  justify-content: space-between; }
  .page-footer .accordion h2, .page-footer .accordion .h2 {
    font-size: 2rem; }
  .page-footer .accordion [data-element='inner'][data-element='inner'] {
    padding-left: 0;
    padding-right: 0; }
  .page-footer .accordion a {
    display: block;
    margin-bottom: 0.7rem;
    font-weight: 300; }
    @media (max-width: 767.98px) {
      .page-footer .accordion a {
        margin-bottom: 1.7rem; } }
  .page-footer .accordion .btn-link {
    border: none;
    font-size: 1.6rem;
    color: inherit; }
    .page-footer .accordion .btn-link:hover, .page-footer .accordion .btn-link:focus, .page-footer .accordion .btn-link:active {
      background: none;
      border: none;
      box-shadow: none; }
  @media (min-width: 1024px) {
    .page-footer .accordion .footer-link:nth-child(1) ul, .page-footer .accordion .footer-link:nth-child(2) ul {
      column-count: 2; }
    .page-footer .accordion .footer-link:nth-child(1) ul {
      max-width: 300px; }
    .page-footer .accordion .footer-link:nth-child(2) ul {
      max-width: 435px; } }
  @media (min-width: 768px) {
    .page-footer .accordion {
      font-weight: 700; }
      .page-footer .accordion .collapse,
      .page-footer .accordion .collapsing {
        display: block !important;
        height: auto !important; }
      .page-footer .accordion .btn-link {
        font-weight: 400;
        margin-bottom: 1.9rem;
        padding: 0.6rem;
        text-decoration: none; }
        .page-footer .accordion .btn-link:hover, .page-footer .accordion .btn-link:focus, .page-footer .accordion .btn-link:active {
          text-decoration: none;
          cursor: default;
          box-shadow: none !important; }
        .page-footer .accordion .btn-link img {
          display: none; } }
  @media (max-width: 767.98px) {
    .page-footer .accordion {
      margin-bottom: 2.3rem;
      margin-left: -3rem;
      margin-right: -3rem;
      font-weight: normal; }
      .page-footer .accordion .footer-content {
        padding: 2rem 2rem 1rem;
        border-top: 1px solid #363736; }
      .page-footer .accordion .footer-link {
        padding: 0; }
        .page-footer .accordion .footer-link:nth-child(1) ul, .page-footer .accordion .footer-link:nth-child(2) ul {
          column-count: 2; }
      .page-footer .accordion .btn-link.btn-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: -1px;
        padding: 1.8rem 2.4rem 1.8rem 3.4rem;
        font-weight: 700;
        border-top: 1px solid #363736;
        border-bottom: 1px solid #363736;
        font-weight: normal;
        text-decoration: none; }
        .page-footer .accordion .btn-link.btn-link, .page-footer .accordion .btn-link.btn-link:hover, .page-footer .accordion .btn-link.btn-link:focus, .page-footer .accordion .btn-link.btn-link:active {
          color: #fff; }
        .page-footer .accordion .btn-link.btn-link:focus {
          box-shadow: none; }
        .page-footer .accordion .btn-link.btn-link:not(.collapsed) img {
          transform: rotate(180deg); }
      .page-footer .accordion ul {
        padding-bottom: 0.8rem; }
      .page-footer .accordion [data-content-type='text'] {
        padding: 1.8rem 2.4rem 1.8rem 3.4rem; } }

.page-footer .text-headings {
  font-weight: 300; }
  .page-footer .text-headings p:first-child {
    margin-bottom: 1.2rem; }
    .page-footer .text-headings p:first-child a {
      font-weight: 600;
      font-size: 2.1rem; }
  .page-footer .text-headings strong {
    font-weight: 700; }
  @media (max-width: 767.98px) {
    .page-footer .text-headings [data-element='inner'][data-element='inner'] {
      padding-left: 0.4rem;
      padding-right: 0.4rem; }
    .page-footer .text-headings [data-content-type='text'] {
      display: flex;
      flex-wrap: wrap; }
    .page-footer .text-headings p:first-child {
      width: 100%; }
      .page-footer .text-headings p:first-child a {
        font-size: 2.2rem; }
    .page-footer .text-headings p:not(:last-child) {
      margin-right: 8.6rem; } }
  @media (min-width: 1366px) {
    .page-footer .text-headings [data-content-type='text'] {
      margin-right: -4rem; } }

.page-footer .social-links {
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (max-width: 767.98px) {
    .page-footer .social-links {
      padding-left: 0.4rem;
      padding-right: 0.4rem; } }
  .page-footer .social-links div {
    padding: 0 !important; }
  .page-footer .social-links ul {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    padding-left: 0; }
    .page-footer .social-links ul li + li {
      margin-left: 1.75rem; }
    @media (max-width: 1023.98px) {
      .page-footer .social-links ul li + li {
        margin-left: 2.6rem; } }
  .page-footer .social-links a {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    width: 34px;
    height: 34px;
    transition: opacity 0.2s;
    background-size: auto 34px; }
    .page-footer .social-links a[href*='facebook'] {
      background-image: url("../images/icon-facebook.svg"); }
    .page-footer .social-links a[href*='linkedin'] {
      background-image: url("../images/icon-linkedin.svg"); }
    .page-footer .social-links a[href*='instagram'] {
      background-image: url("../images/icon-instagram.svg"); }
    .page-footer .social-links a:hover {
      opacity: 0.7; }

@media (min-width: 768px) {
  .page-footer .copyright {
    margin: 4rem 0 -4rem; } }

.page-header {
  position: sticky;
  top: 0;
  z-index: 900;
  background: #fff;
  border-bottom: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 0; }
  @media (max-width: 1023.98px) {
    .page-header {
      box-shadow: none;
      position: static; } }
  .page-header .panel.wrapper {
    border-bottom: none; }
  .page-header .panel.wrapper,
  .page-header .header-content {
    position: relative;
    z-index: 1;
    background: white; }
  @media (max-width: 1023.98px) {
    .page-header .panel.header {
      display: none; } }
  @media (min-width: 1024px) {
    .page-header .panel.header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 3.3rem; }
      .page-header .panel.header .panel.wrapper {
        padding-bottom: 0.1rem; }
      .page-header .panel.header [data-content-type='row'] {
        margin: 0 0 0 2rem !important; }
      .page-header .panel.header [data-element='inner'] {
        padding: 0; }
      .page-header .panel.header ul {
        list-style: none;
        display: flex;
        padding-left: 0;
        margin-left: 1.2rem; }
      .page-header .panel.header li {
        margin-bottom: 0; }
        .page-header .panel.header li:not(:last-child)::after {
          content: '|';
          display: inline-block;
          margin: 0 1rem 0 0.7rem;
          transform: translateY(-1px); }
      .page-header .panel.header a {
        color: #262626;
        font-size: 1.3rem;
        font-weight: 700; } }
  .page-header .header.content {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 0.1rem; }
    @media (min-width: 1024px) {
      .page-header .header.content {
        flex-wrap: nowrap;
        padding: 1.5rem 0; }
        .page-header .header.content .logo {
          flex-shrink: 0;
          margin: 0 4rem 0 0; } }
    @media (min-width: 1024px) {
      .page-header .header.content .logo {
        margin-right: 7.1rem; } }
    @media (max-width: 1023.98px) {
      .page-header .header.content {
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        padding: 0; }
        .page-header .header.content::before, .page-header .header.content::after {
          display: none; }
        .page-header .header.content .logo {
          position: absolute;
          top: 15px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 114.28px;
          height: 30.47px; } }

@media (min-width: 1024px) {
  .header-links {
    /* TODO: Can be changed to 2rem after search is put back in */
    margin-left: auto; } }

.header.links {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0; }
  .header.links .minicart-wrapper {
    float: none; }
    @media (min-width: 1024px) {
      .header.links .minicart-wrapper {
        margin-bottom: 0; } }
    @media (max-width: 1023.98px) {
      .header.links .minicart-wrapper {
        margin: 0; } }
  .header.links .btn-link {
    position: relative;
    border: none; }
  @media (max-width: 1023.98px) {
    .header.links .action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 62px; }
      .header.links .action img {
        width: 34px;
        height: 34px; } }
  .header.links .action img {
    transition: opacity 0.15s linear;
    display: block;
    margin: 0 auto 0.5rem; }
  .header.links .action span {
    line-height: 1;
    font-size: 1.3rem;
    color: #1e1e1e; }
  .header.links .action:hover img {
    opacity: 0.7; }
  .header.links .counter.qty {
    position: static; }
    .header.links .counter.qty .loading-mask {
      display: none; }
    .header.links .counter.qty .counter-number:not(:empty) {
      position: absolute;
      top: 0.4rem;
      left: 2.7rem;
      height: 2.2rem;
      min-width: 2.2rem;
      padding: 0 6px;
      border-radius: 99px;
      border: 1px solid #fff;
      background-color: #D31A1A;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.6; }
      @media (max-width: 1023.98px) {
        .header.links .counter.qty .counter-number:not(:empty) {
          top: 1.1rem;
          left: auto;
          right: 1rem; } }

html {
  overscroll-behavior: none; }
  @media (min-width: 1024px) {
    html {
      scroll-margin-top: 22.5rem; } }

body {
  font-size: 1.5rem; }

.navigation, .breadcrumb, .page-header .header.panel, .header.content, .search-autocomplete, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {
  /* stylelint-disable-line */ }

.page-layout-cms-full-width .page-main,
[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  max-width: 1920px;
  padding-left: 0 !important;
  padding-right: 0 !important; }

[data-content-type='row'][data-appearance='full-width'] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

[data-content-type='row'][data-appearance='contained'] {
  max-width: 1322px; }
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    margin: 0;
    padding: 0 1rem; }

::selection {
  background-color: #FF1A1A;
  color: #fff; }

.column.main.main {
  padding-bottom: 0; }

.loader-container {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(247, 247, 247, 0.3); }

div.mage-error {
  color: #e02b27; }

.cms-page-view .breadcrumb {
  margin-bottom: 0.8rem; }

.cms-page-view .page-main-full-width {
  margin: 0 auto 1.9rem; }
  @media (min-width: 768px) {
    .cms-page-view .page-main-full-width {
      line-height: 1.765;
      font-size: 1.7rem;
      width: 100%; }
      .cms-page-view .page-main-full-width .columns {
        max-width: 1293px;
        margin: 0 auto; } }

.cms-page-view .page-main {
  margin-bottom: 4rem; }
  @media (min-width: 1024px) {
    .cms-page-view .page-main {
      margin-bottom: 9rem; } }

.cms-page-view [data-appearance='full-bleed'] img {
  width: 100%; }

.cms-page-view .main [data-appearance='contained'] {
  max-width: 871px; }

@media (max-width: 767.98px) {
  .cms-page-view .main [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    padding: 0 3.5rem; } }

.cms-page-view h1, .cms-page-view .h1 {
  margin-top: 1.7rem;
  margin-bottom: 3.6rem;
  font-size: 2.8rem;
  text-align: center; }

.cms-page-view h2, .cms-page-view .h2 {
  margin-bottom: 1.6rem;
  font-size: 1.8rem; }

.cms-page-view p {
  margin-bottom: 1.9rem; }

.cms-page-view .branding-emphasis.branding-emphasis.branding-emphasis.branding-emphasis {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  padding: 2.5rem 3rem;
  background-color: #F7F7F7; }
  @media (max-width: 767.98px) {
    .cms-page-view .branding-emphasis.branding-emphasis.branding-emphasis.branding-emphasis {
      margin: 2rem 3.5rem 2.5rem; } }
  .cms-page-view .branding-emphasis.branding-emphasis.branding-emphasis.branding-emphasis h2, .cms-page-view .branding-emphasis.branding-emphasis.branding-emphasis.branding-emphasis .h2,
  .cms-page-view .branding-emphasis.branding-emphasis.branding-emphasis.branding-emphasis p {
    margin-bottom: 1rem; }

@media (min-width: 1024px) {
  .cms-page-view .cms-right {
    padding-right: 0 !important; }
    .cms-page-view .cms-right figure {
      margin-right: calc(((100vw - 871px) / 2) * -1); }
      .cms-page-view .cms-right figure img {
        width: 100%; } }

.discount {
  padding-bottom: 6rem;
  max-width: 1920px;
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .discount {
      padding-bottom: 8rem; } }
  .discount [data-content-type='banner'] {
    margin: 0 2.4rem; }
    .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay {
      min-height: initial !important;
      height: 270px;
      padding: 0 3.2rem; }
      @media (min-width: 1024px) {
        .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay {
          height: 240px; } }
      .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] {
        position: relative; }
        @media (min-width: 1024px) {
          .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] {
            max-width: 1018px;
            margin-left: auto;
            margin-right: 4.2rem; } }
        .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] p {
          color: #fff;
          font-weight: 300;
          font-size: 1.4rem;
          line-height: 1.42;
          padding-right: 4rem; }
          @media (min-width: 1024px) {
            .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] p {
              font-size: 2rem;
              line-height: 1.5;
              max-width: 65%;
              margin: 0;
              padding-right: 0; } }
          .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] p:first-child {
            font-size: 3.2rem;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1.5rem; }
            @media (min-width: 1024px) {
              .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] p:first-child {
                font-size: 5.6rem;
                letter-spacing: -0.224rem;
                max-width: 100%;
                margin-bottom: 1.9rem; } }
        @media (min-width: 1024px) {
          .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] > div {
            position: absolute;
            display: flex;
            right: 0;
            bottom: 0; } }
        .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] > div a {
          display: block;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 500;
          text-transform: uppercase;
          background-color: #212221;
          letter-spacing: 0.128rem;
          padding: 0.4rem 1.6rem;
          height: 41px;
          text-align: center;
          width: 100%; }
          .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] > div a:hover {
            text-decoration: none; }
          @media (min-width: 1024px) {
            .discount [data-content-type='banner'] .pagebuilder-overlay.pagebuilder-poster-overlay [data-element='content'] > div a {
              font-size: 1.6rem;
              padding: 1rem 2rem;
              height: 51px; } }
  .discount [data-content-type='text'] p {
    font-size: 1rem;
    line-height: 1;
    font-weight: 300;
    margin: 1rem 2.4rem 0;
    text-align: right; }
    @media (min-width: 1024px) {
      .discount [data-content-type='text'] p {
        font-size: 1.2rem; } }

.brands,
.suppliers {
  padding: 6rem 0 4rem;
  text-align: center;
  max-width: 1384px;
  margin: 0 auto; }
  .brands [data-content-type='image'],
  .suppliers [data-content-type='image'] {
    margin: 0 2.4rem; }
  @media (min-width: 1024px) {
    .brands,
    .suppliers {
      padding: 8rem 0; } }
  .brands h3, .brands .h3,
  .suppliers h3,
  .suppliers .h3 {
    line-height: 1.06;
    font-size: 1.5rem;
    letter-spacing: 0.06rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 6rem;
    margin-bottom: 2rem; }
    @media (min-width: 1024px) {
      .brands h3, .brands .h3,
      .suppliers h3,
      .suppliers .h3 {
        font-size: 2.2rem;
        margin-bottom: 3.6rem; } }
  .brands-carousel,
  .suppliers-carousel {
    margin: 0 auto;
    max-width: 100vw;
    visibility: hidden;
    transition: opacity 0.15s linear;
    opacity: 0; }
    .brands-carousel.slick-initialized,
    .suppliers-carousel.slick-initialized {
      visibility: visible;
      opacity: 1; }
    .brands-carousel li,
    .suppliers-carousel li {
      display: flex;
      justify-content: center; }
      .brands-carousel li img,
      .suppliers-carousel li img {
        margin: 0 auto;
        width: auto !important; }

.slick-slider {
  overflow: hidden;
  max-width: 100vw; }
  .slick-slider.slick-initialized {
    margin: 0; }
    .slick-slider.slick-initialized .slick-arrow {
      width: 38px;
      height: 48px;
      background-color: #000 !important;
      background-image: url(../images/icon-chevron-right-slider.svg) !important; }
      .slick-slider.slick-initialized .slick-arrow::after, .slick-slider.slick-initialized .slick-arrow::before {
        content: none; }
      .slick-slider.slick-initialized .slick-arrow.slick-next {
        right: 7px; }
      .slick-slider.slick-initialized .slick-arrow.slick-prev {
        left: 7px; }
    .slick-slider.slick-initialized .slick-list {
      margin: 0 -0.6rem; }
      .slick-slider.slick-initialized .slick-list .slick-slide {
        margin: 0 0.6rem;
        float: left !important; }
        @media (max-width: 1023.98px) {
          .slick-slider.slick-initialized .slick-list .slick-slide:not(.slick-active) {
            opacity: 0.4; } }
    .slick-slider.slick-initialized .slick-dots {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1.6rem;
      padding-top: 2.8rem; }
      .slick-slider.slick-initialized .slick-dots .slick-active button {
        background-color: #000; }
      .slick-slider.slick-initialized .slick-dots li {
        margin: 0;
        width: auto;
        height: auto; }
        .slick-slider.slick-initialized .slick-dots li button {
          width: 8px;
          height: 8px;
          border-radius: unset;
          background-color: rgba(0, 0, 0, 0.1);
          border: unset; }
          .slick-slider.slick-initialized .slick-dots li button::before {
            content: none; }

.cms-landing-hero {
  background-color: #F7F7F7;
  max-width: 1920px;
  margin: 0 auto; }
  .cms-landing-hero .btn-outline-primary {
    border-width: 2px; }
    .cms-landing-hero .btn-outline-primary:hover a {
      color: #fff; }
  .cms-landing-hero [data-content-type='column-line'] {
    flex-direction: column; }
    @media (min-width: 1024px) {
      .cms-landing-hero [data-content-type='column-line'] {
        flex-direction: row;
        height: 672px; } }
    .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'] {
      order: 1; }
      @media (min-width: 1024px) {
        .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'] {
          order: 2; } }
      @media (max-width: 1023.98px) {
        .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'] {
          width: 100% !important; } }
      .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content {
        order: 2;
        padding: 2.9rem 2.4rem 4.8rem; }
        @media (min-width: 1024px) {
          .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content {
            order: 1;
            padding: 10rem 8.2rem 0 0;
            max-width: 661px;
            margin-left: auto; } }
        .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content .title p {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1;
          color: #6c757d;
          text-transform: uppercase; }
          @media (min-width: 1024px) {
            .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content .title p {
              margin-bottom: 1rem;
              font-size: 2.2rem; } }
        .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content h1, .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content .h1 {
          margin: 0 0 1.2rem;
          font-size: 3.6rem;
          font-weight: 600;
          line-height: 1.03;
          letter-spacing: -0.144rem;
          transform: translateX(-2px);
          text-align: left; }
          @media (min-width: 1024px) {
            .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content h1, .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content .h1 {
              margin: 0 0 2.7rem;
              font-size: 6.8rem;
              letter-spacing: -0.272rem;
              transform: translateX(-4px); } }
        .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content [data-content-type='text']:not(.title) p {
          font-size: 1.4rem;
          line-height: 1.42; }
          @media (min-width: 1024px) {
            .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content [data-content-type='text']:not(.title) p {
              font-size: 1.8rem;
              line-height: 1.55; } }
        .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content [data-content-type='buttons'] {
          display: flex;
          flex-direction: column;
          gap: 1.4rem;
          margin-top: 0.8rem; }
          @media (min-width: 1024px) {
            .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content [data-content-type='buttons'] {
              margin-top: 1.8rem;
              gap: 2.4rem;
              flex-direction: row; } }
          .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content [data-content-type='buttons'] .btn-primary a {
            color: #fff; }
            .cms-landing-hero [data-content-type='column-line'] [data-content-type='column'].content [data-content-type='buttons'] .btn-primary a:hover {
              color: #fff;
              text-decoration: none; }
  .cms-landing-hero figure {
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%; }
    @media (min-width: 1024px) {
      .cms-landing-hero figure img {
        width: 800px;
        object-fit: cover; } }

.info-tiles {
  max-width: 1368px;
  margin: 0 auto; }
  .info-tiles[data-content-type=row][data-appearance=full-width] {
    padding: 0 2.4rem !important; }
  .info-tiles [data-content-type='column-group']:first-child {
    margin-top: 6rem; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group']:first-child {
        margin-top: 8rem; } }
  .info-tiles [data-content-type='column-group']:not(:first-child) {
    margin-top: 6rem; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group']:not(:first-child) {
        margin-top: 14rem; } }
  .info-tiles [data-content-type='column-group']:nth-child(even) [data-content-type='column-line'] {
    flex-direction: column-reverse; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group']:nth-child(even) [data-content-type='column-line'] {
        flex-direction: row; } }
  .info-tiles [data-content-type='column-group']:nth-child(even) [data-content-type='column']:nth-child(odd) {
    margin-right: 0; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group']:nth-child(even) [data-content-type='column']:nth-child(odd) {
        margin-right: 11.5rem; } }
  .info-tiles [data-content-type='column-group']:nth-child(odd) [data-content-type='column']:nth-child(even) {
    margin-left: 0; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group']:nth-child(odd) [data-content-type='column']:nth-child(even) {
        margin-left: 11.5rem; } }
  .info-tiles [data-content-type='column-group']:last-child {
    margin-bottom: 6rem; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group']:last-child {
        margin-bottom: 14rem; } }
  .info-tiles [data-content-type='column-group'] [data-content-type='column-line'] [data-content-type='column'] {
    width: auto !important;
    justify-content: center !important; }
    .info-tiles [data-content-type='column-group'] [data-content-type='column-line'] [data-content-type='column'] > * {
      max-width: 525px;
      width: 100%; }
    .info-tiles [data-content-type='column-group'] [data-content-type='column-line'] [data-content-type='column'] [data-content-type='image'] {
      width: 100%;
      max-width: 680px; }
  .info-tiles [data-content-type='column-group'] h3, .info-tiles [data-content-type='column-group'] .h3 {
    margin-bottom: 1.2rem;
    margin-top: 2.4rem;
    font-size: 2.6rem;
    line-height: 1.076; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group'] h3, .info-tiles [data-content-type='column-group'] .h3 {
        margin-top: 0;
        margin-bottom: 2rem;
        font-size: 4.6rem;
        line-height: 1.13; } }
  .info-tiles [data-content-type='column-group'] [data-content-type='buttons'] .btn {
    width: 100%; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group'] [data-content-type='buttons'] .btn {
        width: auto; } }
  .info-tiles [data-content-type='column-group'] [data-content-type='text'] p {
    font-size: 1.6rem;
    line-height: 1.375; }
    @media (min-width: 1024px) {
      .info-tiles [data-content-type='column-group'] [data-content-type='text'] p {
        font-size: 1.8rem;
        line-height: 1.55; } }
    .info-tiles [data-content-type='column-group'] [data-content-type='text'] p:last-child {
      margin-bottom: 2.8rem; }
      @media (min-width: 1024px) {
        .info-tiles [data-content-type='column-group'] [data-content-type='text'] p:last-child {
          margin-bottom: 4.4rem; } }

@media (min-width: 1024px) {
  .process {
    padding: 0 2.4rem; } }

.process h2, .process .h2 {
  font-size: 2.6rem !important;
  line-height: 1.07;
  letter-spacing: -0.104rem; }
  @media (min-width: 1024px) {
    .process h2, .process .h2 {
      font-size: 4.6rem !important;
      letter-spacing: -0.224rem; } }
  @media (min-width: 1366px) {
    .process h2, .process .h2 {
      font-size: 5.6rem; } }

.process h3, .process .h3 {
  font-size: 2.2rem;
  line-height: 1.09;
  font-weight: 600; }
  @media (min-width: 1024px) {
    .process h3, .process .h3 {
      font-size: 2.5rem;
      line-height: 1; } }
  @media (min-width: 1366px) {
    .process h3, .process .h3 {
      font-size: 3.2rem; } }

.process [data-content-type='column-group'] {
  max-width: 1540px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 4rem 2.4rem 6.7rem;
  background-position: bottom !important; }
  @media (min-width: 1024px) {
    .process [data-content-type='column-group'] {
      padding: 3.2rem 6.5rem 7rem;
      flex-direction: row;
      background-position: left top; } }
  @media (min-width: 1366px) {
    .process [data-content-type='column-group'] {
      padding: 6.2rem 11rem 7rem; } }
  .process [data-content-type='column-group'] [data-content-type='column-line'] {
    flex-direction: column; }
  @media (min-width: 1024px) {
    .process [data-content-type='column-group'] [data-content-type='column'].content {
      max-width: 517px;
      padding-right: 3rem; } }
  .process [data-content-type='column-group'] [data-content-type='column'].content [data-content-type='text'] {
    margin-bottom: 2.8rem; }
    .process [data-content-type='column-group'] [data-content-type='column'].content [data-content-type='text'] p {
      font-size: 1.4rem;
      line-height: 1.42;
      margin-bottom: 1.4rem; }
      .process [data-content-type='column-group'] [data-content-type='column'].content [data-content-type='text'] p:last-child {
        margin-bottom: 0; }
      @media (min-width: 1024px) {
        .process [data-content-type='column-group'] [data-content-type='column'].content [data-content-type='text'] p {
          font-size: 1.8rem;
          line-height: 1.55; } }
  .process [data-content-type='column-group'] [data-content-type='column']:not(.content) {
    width: 100% !important;
    flex-direction: row;
    position: relative;
    background: linear-gradient(125deg, #FF1A1A 33.66%, #d7150c 118.95%);
    color: #fff;
    padding: 1.8rem 2.2rem 2.2rem 2.4rem; }
    @media (min-width: 1024px) {
      .process [data-content-type='column-group'] [data-content-type='column']:not(.content) {
        padding: 2rem 3rem 3.6rem 10.6rem;
        max-width: 639px;
        margin-left: auto; } }
    @media (min-width: 1366px) {
      .process [data-content-type='column-group'] [data-content-type='column']:not(.content) {
        padding: 0.8rem 3rem 3.6rem 13.6rem; }
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content):first-child::before, .process [data-content-type='column-group'] [data-content-type='column']:not(.content):nth-child(2)::before {
          content: ''; }
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content):first-child::before {
          right: -93px;
          top: 35px; }
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content):nth-child(2)::before {
          left: -93px;
          top: 50%;
          transform: rotateY(180deg); } }
    .process [data-content-type='column-group'] [data-content-type='column']:not(.content):first-child::before, .process [data-content-type='column-group'] [data-content-type='column']:not(.content):nth-child(2)::before {
      width: 112px;
      height: 179px;
      position: absolute;
      background-image: url(../images/icon-dotted-arrow.svg);
      background-size: contain; }
    .process [data-content-type='column-group'] [data-content-type='column']:not(.content):not(:first-child) {
      margin-top: 1.6rem; }
      @media (min-width: 1024px) {
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content):not(:first-child) {
          margin-top: 2.4rem; } }
    .process [data-content-type='column-group'] [data-content-type='column']:not(.content) [data-content-type='image'] {
      position: absolute;
      left: 16px;
      width: 50px;
      height: 50px;
      margin: 0;
      top: 18px; }
      @media (min-width: 1024px) {
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content) [data-content-type='image'] {
          left: 30px; } }
      @media (min-width: 1366px) {
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content) [data-content-type='image'] {
          width: 84px;
          height: 84px;
          top: 24px; } }
    @media (max-width: 767.98px) {
      .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion [data-role='content'] {
        display: none; } }
    .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion .allow.active {
      margin-bottom: 1.4rem; }
      .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion .allow.active h3::after, .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion .allow.active .h3::after {
        transform: rotate(-180deg);
        top: 4px; }
    .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion h3, .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion .h3 {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 5.6rem;
      padding-right: 9rem;
      margin-bottom: 0;
      height: 50px; }
      @media (min-width: 1024px) {
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion h3, .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion .h3 {
          margin-bottom: 0.8rem;
          padding-left: 0;
          padding-right: 0;
          pointer-events: none; } }
      .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion h3::after, .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion .h3::after {
        content: url(../images/icon-dropdown-arrow.svg);
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        line-height: 1.09;
        transition: all 0.2s ease-in-out; }
        @media (min-width: 1024px) {
          .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion h3::after, .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion .h3::after {
            content: none; } }
    .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion p {
      font-size: 1.4rem;
      line-height: 1.428;
      margin: 0; }
      @media (min-width: 1024px) {
        .process [data-content-type='column-group'] [data-content-type='column']:not(.content) .tile-accordion p {
          font-weight: 300;
          font-size: 1.6rem;
          max-height: 100%;
          line-height: normal; } }

.product-cards-carousel {
  position: relative;
  max-width: 1920px;
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .product-cards-carousel::after {
      content: ''; } }
  .product-cards-carousel::after {
    position: absolute;
    background: url(../images/icon-peak-graphic-transparent.svg);
    background-size: contain;
    bottom: 0;
    right: 0;
    width: 382px;
    height: 390px;
    z-index: -1; }
  .product-cards-carousel [data-content-type='image'] {
    margin-bottom: 0; }
  .product-cards-carousel .slick-slider.slick-initialized .slick-dots {
    padding-top: 0; }
  .product-cards-carousel ul:not(.slick-dots) {
    padding: 0;
    visibility: hidden;
    transition: opacity 0.15s linear;
    opacity: 0; }
    .product-cards-carousel ul:not(.slick-dots).slick-initialized {
      visibility: visible;
      opacity: 1; }
      @media (max-width: 1023.98px) {
        .product-cards-carousel ul:not(.slick-dots).slick-initialized .slick-list {
          padding: 0 2.2rem !important; } }
      @media (min-width: 1024px) {
        .product-cards-carousel ul:not(.slick-dots).slick-initialized .slick-list {
          padding: 0 11rem !important; } }
    .product-cards-carousel ul:not(.slick-dots) li {
      padding: 2rem 0;
      max-width: max-content; }
      @media (min-width: 1024px) {
        .product-cards-carousel ul:not(.slick-dots) li {
          max-width: 316px;
          padding: 4.8rem 0 14.8rem; } }
      .product-cards-carousel ul:not(.slick-dots) li a {
        background-color: #212221;
        text-decoration: none;
        color: #fff;
        display: flex;
        flex-direction: column;
        transition: all 0.2s ease-in-out; }
        .product-cards-carousel ul:not(.slick-dots) li a:hover {
          background-color: #FF1A1A;
          box-shadow: 2px 4px 20px 0 rgba(255, 26, 26, 0.8); }
          @media (min-width: 1024px) {
            .product-cards-carousel ul:not(.slick-dots) li a:hover {
              box-shadow: 2px 6px 30px 0 rgba(255, 26, 26, 0.8); } }
        .product-cards-carousel ul:not(.slick-dots) li a p {
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 1;
          padding: 1rem 1.6rem 1.5rem;
          margin-bottom: 0; }
          @media (min-width: 1024px) {
            .product-cards-carousel ul:not(.slick-dots) li a p {
              font-size: 2.5rem;
              padding: 2.2rem 3.1rem; } }
          @media (min-width: 1366px) {
            .product-cards-carousel ul:not(.slick-dots) li a p {
              font-size: 3.2rem; } }

.testimonials {
  display: flex;
  flex-direction: column;
  max-width: 1368px;
  margin: 0 auto; }
  .testimonials[data-content-type=row][data-appearance=full-width] {
    padding: 0 2.4rem !important; }
  .testimonials [data-content-type='column-group'] {
    padding: 6.1rem 0 6rem; }
    @media (min-width: 1024px) {
      .testimonials [data-content-type='column-group'] {
        padding: 10rem 0; } }
  .testimonials [data-content-type='column-line'] {
    gap: 2.4rem; }
    @media (min-width: 1024px) {
      .testimonials [data-content-type='column-line'] {
        gap: 4.3rem; } }
    .testimonials [data-content-type='column-line'] [data-content-type='column'] {
      border: 2px solid #e9ecef;
      min-height: 420px;
      padding: 2.6rem 2.6rem 3.2rem; }
      @media (min-width: 1024px) {
        .testimonials [data-content-type='column-line'] [data-content-type='column'] {
          padding: 4rem 5.6rem 6.9rem;
          min-height: 472px; } }
      .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem; }
        @media (min-width: 1024px) {
          .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div {
            flex-direction: row;
            gap: 4rem; } }
        .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div img {
          max-height: 42px; }
          @media (min-width: 1024px) {
            .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div img {
              max-height: 53px; } }
        .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div p {
          text-transform: uppercase;
          font-size: 1.5rem;
          font-weight: 600;
          letter-spacing: 0.08rem;
          text-align: center;
          margin: 0;
          line-height: 1.33; }
          @media (min-width: 1024px) {
            .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div p {
              font-size: 2.2rem;
              text-align: left; } }
          .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div p span {
            display: block;
            font-size: 1.2rem;
            line-height: 1.66;
            font-weight: 300;
            text-transform: capitalize; }
            @media (min-width: 1024px) {
              .testimonials [data-content-type='column-line'] [data-content-type='column'] [data-content-type='html'] > div p span {
                font-size: 1.6rem; } }
  .testimonials [data-content-type='text'] {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.2rem;
    flex: 1; }
    @media (min-width: 1024px) {
      .testimonials [data-content-type='text'] {
        align-items: center;
        margin-bottom: 0; } }
    .testimonials [data-content-type='text']::before {
      content: '';
      background-image: url(../images/icon-quote.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 33px;
      height: 20px;
      display: inline-block;
      margin: 0 auto 1.4rem; }
      @media (min-width: 1024px) {
        .testimonials [data-content-type='text']::before {
          margin: 0 auto 1.9rem 0;
          width: 46px;
          height: 28px; } }
    .testimonials [data-content-type='text'] p {
      font-size: 1.6rem;
      line-height: 1.375;
      font-weight: 300;
      margin: 0;
      text-align: center; }
      @media (min-width: 1024px) {
        .testimonials [data-content-type='text'] p {
          font-size: 2rem;
          line-height: 1.5;
          text-align: left; } }

.about {
  max-width: 1320px;
  margin: 0 auto; }
  .about .btn-outline-primary {
    border-width: 2px; }
    .about .btn-outline-primary:hover a {
      color: #fff; }
  .about h2[data-content-type='heading'], .about .h2[data-content-type='heading'] {
    font-size: 2.6rem;
    line-height: 1.076;
    font-weight: 600;
    margin-bottom: 1.4rem;
    letter-spacing: -0.104rem; }
    @media (min-width: 1024px) {
      .about h2[data-content-type='heading'], .about .h2[data-content-type='heading'] {
        font-size: 4.6rem;
        line-height: 1.13;
        letter-spacing: -0.184rem;
        margin: 0;
        padding-right: 4rem; } }
  .about [data-content-type='image'] {
    margin-bottom: 0; }
  .about .slick-slider .slick-list {
    padding: 0 5.6rem !important;
    margin: 0 -1.3rem; }
    .about .slick-slider .slick-list .slick-slide {
      margin: 0 1.3rem; }
  .about .slick-slider .slick-arrow {
    top: 100px; }
  .about .slick-slider .slick-dots {
    padding-top: 3.6rem; }
  .about [data-content-type='column-group']:not(.tiles) .pagebuilder-column-line {
    padding: 0 2.4rem; }
    @media (min-width: 1366px) {
      .about [data-content-type='column-group']:not(.tiles) .pagebuilder-column-line {
        padding: 0; } }
  .about [data-content-type='column-group']:not(.tiles) [data-content-type='column'] p {
    font-size: 1.4rem;
    line-height: 1.428;
    font-weight: 300;
    margin-bottom: 3.2rem; }
    @media (min-width: 1024px) {
      .about [data-content-type='column-group']:not(.tiles) [data-content-type='column'] p {
        font-size: 2rem;
        line-height: 1.5;
        margin-bottom: 2.8rem; } }
  .about [data-content-type='column-group']:not(.tiles) [data-content-type='column'] [data-content-type='buttons'] {
    display: flex;
    flex-direction: column;
    gap: 1.2rem; }
    @media (min-width: 1024px) {
      .about [data-content-type='column-group']:not(.tiles) [data-content-type='column'] [data-content-type='buttons'] {
        flex-direction: row;
        gap: 2.4rem; } }
    .about [data-content-type='column-group']:not(.tiles) [data-content-type='column'] [data-content-type='buttons'] a:hover {
      text-decoration: none; }
  .about .tiles {
    margin-top: 4.8rem;
    margin-bottom: 6rem; }
    @media (min-width: 1024px) {
      .about .tiles {
        margin-top: 5.6rem;
        margin-bottom: 13.6rem; } }
    .about .tiles h3, .about .tiles .h3 {
      line-height: 1;
      font-size: 2.2rem;
      margin-bottom: 1rem;
      font-weight: 600; }
      @media (min-width: 1024px) {
        .about .tiles h3, .about .tiles .h3 {
          font-size: 3.2rem; } }
    .about .tiles .pagebuilder-column-line {
      padding: 0 2.4rem; }
      @media (max-width: 1023.98px) {
        .about .tiles .pagebuilder-column-line {
          visibility: hidden;
          transition: opacity 0.15s linear;
          opacity: 0; }
          .about .tiles .pagebuilder-column-line.slick-initialized {
            visibility: visible;
            opacity: 1;
            padding: 0; } }
      @media (min-width: 1024px) {
        .about .tiles .pagebuilder-column-line {
          gap: 3.4rem; } }
      @media (min-width: 1366px) {
        .about .tiles .pagebuilder-column-line {
          padding: 0; } }
      .about .tiles .pagebuilder-column-line [data-content-type='image'] {
        margin-bottom: 2.6rem; }
        @media (min-width: 1024px) {
          .about .tiles .pagebuilder-column-line [data-content-type='image'] {
            margin-bottom: 3.4rem; } }
      @media (min-width: 1024px) {
        .about .tiles .pagebuilder-column-line [data-content-type='text'] {
          padding-right: 1rem; } }
      @media (min-width: 1366px) {
        .about .tiles .pagebuilder-column-line [data-content-type='text'] {
          padding-right: 2.5rem; } }
      .about .tiles .pagebuilder-column-line [data-content-type='text'] p {
        font-size: 1.4rem;
        line-height: 1.428;
        margin-bottom: 0; }
        @media (min-width: 1024px) {
          .about .tiles .pagebuilder-column-line [data-content-type='text'] p {
            font-size: 1.6rem; } }
        .about .tiles .pagebuilder-column-line [data-content-type='text'] p a {
          text-decoration: underline; }

.catalog-product-view .page-main-container {
  overflow-x: hidden; }
  .catalog-product-view .page-main-container .page-title-wrapper .page-title {
    margin-top: 0;
    font-size: 2.2rem;
    line-height: 1.1;
    font-weight: 400; }
    @media (min-width: 768px) {
      .catalog-product-view .page-main-container .page-title-wrapper .page-title {
        font-size: 2.8rem; } }
    @media (max-width: 767.98px) {
      .catalog-product-view .page-main-container .page-title-wrapper .page-title {
        padding: 0 3.2rem; } }
  @media (min-width: 768px) {
    .catalog-product-view .page-main-container > .page-title-wrapper {
      display: none; } }

@media (min-width: 768px) {
  .catalog-product-view .page-main {
    max-width: 1366px;
    padding: 0; } }

@media (max-width: 767.98px) {
  .catalog-product-view .page-main {
    padding: 0 3.2rem; } }

@media (min-width: 768px) {
  .catalog-product-view .column.main {
    display: flex;
    flex-wrap: wrap; } }

.catalog-product-view .product-info-main {
  background-color: #F7F7F7; }
  @media (max-width: 767.98px) {
    .catalog-product-view .product-info-main .page-title-wrapper {
      display: none; } }
  @media (max-width: 767.98px) {
    .catalog-product-view .product-info-main {
      margin-top: 2.7rem;
      margin-left: -3.2rem;
      margin-right: -3.2rem; } }
  @media (max-width: 1023.98px) {
    .catalog-product-view .product-info-main {
      padding: 2.9rem 3.2rem 6.5rem; } }
  @media (min-width: 768px) {
    .catalog-product-view .product-info-main {
      margin-top: -4.6rem;
      margin-bottom: -6.7rem; } }
  @media (min-width: 1024px) {
    .catalog-product-view .product-info-main {
      padding: 5rem 7.5rem;
      width: 41.435%; } }

@media (min-width: 768px) {
  .catalog-product-view .product.media {
    order: -1; } }

@media (min-width: 1024px) {
  .catalog-product-view .product.media {
    width: calc(100% - 41.435%);
    padding-right: 4.9rem;
    padding-left: 14.3984rem; } }

@media (min-width: 1024px) {
  .catalog-product-view ._block-content-loading {
    height: 517px; }
    .catalog-product-view ._block-content-loading .gallery-placeholder__image {
      padding-left: 8.152rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(2rem, -50%); } }

.box-tocart {
  display: flex; }
  .box-tocart .control {
    position: relative; }
    .box-tocart .control:focus-within .qty {
      color: #262626;
      background-color: #fff;
      border-color: #000; }
  .box-tocart .btn-qty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
    .box-tocart .btn-qty[data-direction='minus'] {
      left: 0; }
    .box-tocart .btn-qty[data-direction='add'] {
      right: 0; }
    .box-tocart .btn-qty:focus-visible {
      opacity: 0.7; }
  .box-tocart .field.qty {
    flex-grow: 1;
    max-width: 379px;
    margin-right: 2rem; }
  .box-tocart input.qty.qty.qty.qty {
    margin-top: 0;
    width: 100%;
    border-radius: 0.2rem;
    text-align: center;
    font-size: 1.8rem; }
  .box-tocart .fieldset {
    margin-bottom: 0; }

.toolbar-products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: relative; }
  @media (max-width: 1023.98px) {
    .toolbar-products {
      flex-wrap: wrap;
      padding: 0; } }
  @media (max-width: 1023.98px) {
    .toolbar-products.toolbar-top .show-all-limiter {
      bottom: 0; } }
  .toolbar-products .show-all-limiter {
    order: 3;
    position: absolute;
    right: 0; }
    .toolbar-products .show-all-limiter a {
      color: #262626;
      text-decoration: underline; }
      .toolbar-products .show-all-limiter a:hover {
        text-decoration: none; }
    .toolbar-products .show-all-limiter + .pages {
      padding-left: 7rem;
      padding-right: 7rem; }
      @media (max-width: 1023.98px) {
        .toolbar-products .show-all-limiter + .pages {
          padding-left: 0; } }
      @media (max-width: 767.98px) {
        .toolbar-products .show-all-limiter + .pages {
          padding-left: 7rem; } }
      @media (max-width: 370px) {
        .toolbar-products .show-all-limiter + .pages {
          justify-content: flex-end !important; } }
  .toolbar-products .pages {
    order: 5; }
    .toolbar-products .pages.pages.pages.pages {
      float: none !important;
      display: block; }
      @media (max-width: 767.98px) {
        .toolbar-products .pages.pages.pages.pages {
          width: 100%;
          display: flex;
          justify-content: center; } }
    .toolbar-products .pages #paging-label {
      display: none; }
  .toolbar-products::before, .toolbar-products::after,
  .toolbar-products .modes {
    display: none !important; }
  .toolbar-products .limiter.limiter.limiter {
    float: none;
    order: 1; }
  .toolbar-products .toolbar-amount {
    order: 3;
    padding-top: 0; }
  .toolbar-products .toolbar-sorter {
    float: none;
    order: 1;
    padding: 0; }
    .toolbar-products .toolbar-sorter .sorter-action,
    .toolbar-products .toolbar-sorter .sorter-label {
      display: none; }
    .toolbar-products .toolbar-sorter .sorter-options {
      margin: 0;
      color: #B2B2B2; }
    @media (min-width: 1024px) {
      .toolbar-products .toolbar-sorter .refine-by {
        display: none; } }
    @media (max-width: 1023.98px) {
      .toolbar-products .toolbar-sorter {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        display: flex;
        margin-bottom: 3rem; }
        .toolbar-products .toolbar-sorter .refine-by,
        .toolbar-products .toolbar-sorter .sorter-options {
          width: 50%;
          height: 60px;
          background-color: #F7F7F7;
          border: none;
          font-size: 1.3rem;
          line-height: 1.6rem;
          padding: 2.2rem 2.5rem;
          color: #262626; }
        .toolbar-products .toolbar-sorter .sorter-options {
          margin-right: 1px; }
        .toolbar-products .toolbar-sorter .refine-by {
          margin-left: 1px;
          text-align: left;
          position: relative; }
          .toolbar-products .toolbar-sorter .refine-by::after {
            content: ' ';
            width: 24px;
            height: 24px;
            display: block;
            position: absolute;
            top: 50%;
            right: 17px;
            transform: translateY(-50%);
            background-image: url(../images/icon-filter.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; }
          .toolbar-products .toolbar-sorter .refine-by:active {
            box-shadow: none; } }

.fotorama--fullscreen {
  background-color: rgba(255, 255, 255, 0.9) !important; }
  .fotorama--fullscreen .fotorama__wrap,
  .fotorama--fullscreen .fotorama__stage {
    height: 100% !important; }
  .fotorama--fullscreen .fotorama__nav-wrap,
  .fotorama--fullscreen .fotorama__zoom-in,
  .fotorama--fullscreen .fotorama__zoom-out {
    display: none !important; }
  .fotorama--fullscreen .fotorama__arr__arr,
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    background-position: center !important; }
  .fotorama--fullscreen .fotorama__arr,
  .fotorama--fullscreen .fotorama__thumb__arr,
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: block !important;
    background-color: transparent !important;
    background-repeat: no-repeat; }
    .fotorama--fullscreen .fotorama__arr:hover,
    .fotorama--fullscreen .fotorama__thumb__arr:hover,
    .fotorama--fullscreen .fotorama__fullscreen-icon:hover {
      background-color: rgba(33, 34, 33, 0.2) !important; }
  .fotorama--fullscreen .fotorama__arr {
    width: 80px;
    height: 80px;
    margin: auto 0; }
  .fotorama--fullscreen .fotorama__arr--prev .fotorama__arr__arr,
  .fotorama--fullscreen .fotorama__arr--next .fotorama__arr__arr {
    background-image: url(../images/icon-chevron-right.svg);
    background-size: 32px 32px; }
  .fotorama--fullscreen .fotorama__arr--prev .fotorama__arr__arr {
    transform-origin: 20px 20px;
    transform: rotate(180deg); }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    background-image: url(../images/icon-close.svg);
    background-size: 32px 32px;
    z-index: 1; }
    .fotorama--fullscreen .fotorama__fullscreen-icon::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      height: 100vh; }

@media (min-width: 1024px) {
  .fotorama__stage {
    padding-left: 2rem; } }

@media (max-width: 767.98px) {
  .fotorama__stage .fotorama__arr {
    display: block !important;
    width: 50%;
    background: none; }
    .fotorama__stage .fotorama__arr.fotorama__arr--prev .fotorama__arr__arr {
      transform: translateY(-50%) rotate(180deg);
      left: 0; }
  .fotorama__stage .fotorama__arr__arr {
    background-image: url(../images/icon-chevron-right.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    background-position: right center; } }

.fotorama__stage .fotorama__img {
  position: static;
  transform: none; }

@media (min-width: 768px) {
  .fotorama__nav-wrap--vertical .fotorama__nav--thumbs .fotorama__nav__frame.fotorama__nav__frame {
    padding: 1.5rem 0.4rem; } }

.fotorama__thumb-border.fotorama__thumb-border.fotorama__thumb-border {
  background: none;
  border: 1px solid #212221;
  border-radius: 3px; }

@media (max-width: 767.98px) {
  .magnify-lens {
    display: none !important; } }

.product-info-main .attribute.sku strong {
  display: none; }

.product-info-main .attribute.sku .value {
  color: #262626; }

.product-info-main .product-info-price {
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .product-info-main .product-info-price {
      margin-bottom: 5rem; } }
  .product-info-main .product-info-price .price-label {
    display: none !important; }
  .product-info-main .product-info-price .special-price {
    margin: 0; }
  .product-info-main .product-info-price .old-price {
    order: -1;
    padding-right: 1rem;
    text-decoration: none; }
    .product-info-main .product-info-price .old-price .price {
      text-decoration: line-through; }
  .product-info-main .product-info-price .price-box {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 1rem 0; }
    @media (max-width: 767.98px) {
      .product-info-main .product-info-price .price-box {
        margin-top: 0; } }
    .product-info-main .product-info-price .price-box:empty {
      display: none; }
    .product-info-main .product-info-price .price-box .price-wrapper::after {
      content: 'Price includes GST';
      display: block;
      margin-top: 1rem; }
    .old-price .product-info-main .product-info-price .price-box .price-wrapper {
      display: none; }
    .product-info-main .product-info-price .price-box .price-wrapper .price {
      color: #262626;
      font-size: 2.2rem;
      font-weight: 700; }
      @media (min-width: 768px) {
        .product-info-main .product-info-price .price-box .price-wrapper .price {
          font-size: 2.8rem; } }
      .product-info-main .product-info-price .price-box .price-wrapper .price::after {
        content: ' per unit'; }
    .product-info-main .product-info-price .price-box .old-price .price-wrapper .price::after {
      display: none; }

.product-info-main .box-tocart.box-tocart.box-tocart {
  display: flex; }
  @media (max-width: 767.98px) {
    .product-info-main .box-tocart.box-tocart.box-tocart {
      margin-top: 0.5rem; } }
  .product-info-main .box-tocart.box-tocart.box-tocart .fieldset {
    width: 100%; }
  .product-info-main .box-tocart.box-tocart.box-tocart .field {
    display: flex;
    max-width: none;
    margin: 0;
    padding: 0; }
    .product-info-main .box-tocart.box-tocart.box-tocart .field .control {
      width: 100%; }
      .product-info-main .box-tocart.box-tocart.box-tocart .field .control div.mage-error.mage-error {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: -0.5rem; }
  .product-info-main .box-tocart.box-tocart.box-tocart .label {
    display: none; }
  .product-info-main .box-tocart.box-tocart.box-tocart .actions {
    display: block;
    padding-top: 2rem; }
    .product-info-main .box-tocart.box-tocart.box-tocart .actions .tocart {
      width: 100%; }

.product-info-main .shipping-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.1rem;
  font-size: 1.3rem; }
  .product-info-main .shipping-text img {
    margin-right: 0.4rem; }

.product-info-main .add-multi-products {
  padding-bottom: 1.2rem; }
  @media (max-width: 767.98px) {
    .product-info-main .add-multi-products {
      padding-bottom: 0; } }
  .product-info-main .add-multi-products .size {
    font-size: 1.8rem;
    margin-bottom: 2.1rem; }
  .product-info-main .add-multi-products .select-size {
    font-size: 1.3rem;
    margin-bottom: 1.6rem; }
  .product-info-main .add-multi-products .size-qty-wrapper {
    display: flex;
    text-align: center; }
    .product-info-main .add-multi-products .size-qty-wrapper .fields {
      background: #000;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border: 1px solid #fff; }
      .product-info-main .add-multi-products .size-qty-wrapper .fields .label {
        color: #fff;
        width: 6.2rem;
        margin-bottom: 0;
        flex: 0 0 15%; }
      .product-info-main .add-multi-products .size-qty-wrapper .fields .input-text {
        height: 4rem;
        border: 1px solid #B2B2B2;
        flex: 0 0 85%; }
      .product-info-main .add-multi-products .size-qty-wrapper .fields .mage-error {
        background: #fff;
        text-align: left; }

.product-options-wrapper .field.required > .label::after {
  display: none; }

.product-options-wrapper .btn-option-toggle {
  width: 100%;
  border: 2px solid #d31b19;
  margin: 2rem 0;
  position: relative; }
  .product-options-wrapper .btn-option-toggle span {
    color: #d31b19; }
    .product-options-wrapper .btn-option-toggle span::before {
      content: '+';
      position: absolute;
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      border: 2px solid #d31b19;
      top: 50%;
      left: 10%;
      transform: translate(-50%, -50%);
      font-size: 15px; }

.product-options-wrapper .additional {
  margin-bottom: 1.5rem; }
  .product-options-wrapper .additional > span {
    font-size: 18px; }

.product-options-wrapper .field .note {
  display: none; }

.product-options-wrapper .field.additional {
  padding: 1rem 3rem 2.7rem;
  background: #fff;
  margin: 0; }

.product-options-wrapper .btn-remove-wrapper {
  text-align: right; }
  .product-options-wrapper .btn-remove-wrapper .btn-option-remove {
    border: none;
    background: none;
    margin: 0;
    padding: 0; }
    .product-options-wrapper .btn-remove-wrapper .btn-option-remove:active {
      box-shadow: none; }
  .product-options-wrapper .btn-remove-wrapper span {
    text-decoration: underline;
    font-size: 13px; }

.fotorama__thumb--icon.fotorama__thumb--icon {
  background-image: url(../images/icon-chevron-right.svg);
  background-size: 24px 24px;
  background-position: center;
  padding-bottom: 2.4rem; }
  .fotorama__nav-wrap--vertical .fotorama__thumb--icon.fotorama__thumb--icon {
    transform: rotate(90deg); }
  .fotorama__thumb__arr--left .fotorama__thumb--icon.fotorama__thumb--icon {
    transform: rotate(180deg); }
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon.fotorama__thumb--icon {
      transform: rotate(-90deg); }
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon.fotorama__thumb--icon {
      transform-origin: 10px 6px; }

.product.data.items::before, .product.data.items::after {
  display: none; }

.product.data.items > .item.content {
  position: relative;
  z-index: 0; }
  @media (max-width: 767.98px) {
    .product.data.items > .item.content {
      border: none;
      padding-top: 2.4rem;
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 768px) {
    .product.data.items > .item.content {
      margin-top: 6.7rem;
      padding-top: 3.7rem;
      border-top-color: #B2B2B2; }
      .product.data.items > .item.content::before, .product.data.items > .item.content::after {
        content: '';
        position: absolute;
        bottom: 100%;
        border-top: 1px solid #B2B2B2;
        width: 100%; }
      .product.data.items > .item.content::before {
        left: 100%; }
      .product.data.items > .item.content::after {
        right: 100%; } }

.product.data.items > .item.title.title.title {
  margin-right: 8rem; }
  .product.data.items > .item.title.title.title > .switch {
    height: auto;
    background-color: #fff;
    padding: 2.1rem 4rem;
    border: none;
    font-weight: 700;
    font-size: 1.6rem;
    color: #262626; }
    @media (max-width: 767.98px) {
      .product.data.items > .item.title.title.title > .switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 3.2rem; }
        .product.data.items > .item.title.title.title > .switch img {
          transform: rotate(90deg); } }
  @media (max-width: 767.98px) {
    .product.data.items > .item.title.title.title {
      width: calc(100% + 6.4rem);
      margin: 0 -3.2rem;
      border-top: 1px solid #B2B2B2; }
      .product.data.items > .item.title.title.title:first-child {
        border-top: none; }
      .product.data.items > .item.title.title.title.active > .switch {
        border-bottom-color: #212221; }
        .product.data.items > .item.title.title.title.active > .switch img {
          transform: rotate(-90deg); } }
  @media (min-width: 768px) {
    .product.data.items > .item.title.title.title > .switch {
      padding: 2.1rem 4rem;
      border-bottom: 5px solid #fff;
      font-size: 1.5rem; }
      .product.data.items > .item.title.title.title > .switch img {
        display: none; }
    .product.data.items > .item.title.title.title.active > .switch {
      border-bottom-color: #212221; } }

@media (min-width: 768px) {
  .product.info.detailed {
    max-width: 1322px;
    width: 100%;
    margin: 0 auto 4.7rem;
    padding: 0 4.2rem; } }

@media (max-width: 767.98px) {
  .product.info.detailed {
    margin-bottom: 0; } }

.product-tabs .tab-description .item-content-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (min-width: 1024px) {
    .product-tabs .tab-description .item-content-inner .left {
      width: 55%; }
    .product-tabs .tab-description .item-content-inner .right {
      width: 35%; } }
  @media (max-width: 1023.98px) {
    .product-tabs .tab-description .item-content-inner .right {
      width: 100%; } }

@media (max-width: 1023.98px) {
  .product-tabs .branding-data {
    margin-top: 3rem; } }

.product-tabs .branding-data .branding-block {
  padding: 3rem;
  background-color: #F7F7F7;
  margin-bottom: 1.6rem;
  border-radius: 0.3rem; }
  .product-tabs .branding-data .branding-block .h5 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem; }

.product-tabs .branding-data .branding-template a {
  text-decoration: none;
  color: #262626;
  font-size: 1.3rem;
  display: flex;
  align-items: center; }
  .product-tabs .branding-data .branding-template a:hover {
    text-decoration: underline; }
  .product-tabs .branding-data .branding-template a::before {
    content: ' ';
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 0.8rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/icon-pdf.svg); }

.product-tabs .branding-data .branding-options .branding-group {
  margin-bottom: 1.3rem;
  position: relative; }
  .product-tabs .branding-data .branding-options .branding-group:last-child {
    margin-bottom: 0; }
  .product-tabs .branding-data .branding-options .branding-group::before {
    content: ' ';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 3px;
    left: 0;
    background-image: url(../images/icon-tag.svg); }

.product-tabs .branding-data .branding-options .branding-label {
  padding-left: 3.5rem;
  font-size: 1.3rem; }

.product-tabs .branding-data .branding-options .branding-description {
  padding-left: 3.5rem;
  font-size: 1.3rem; }

.purchase-details {
  padding: 2rem;
  background-color: #fff;
  font-size: 1.3rem;
  border-radius: 0.3rem; }
  .purchase-details .discount-tiers,
  .purchase-details .min-purchase {
    position: relative;
    padding-left: 3.2rem; }
    .purchase-details .discount-tiers::before,
    .purchase-details .min-purchase::before {
      left: 0;
      content: ' '; }
    .purchase-details .discount-tiers .breakdown-final-price,
    .purchase-details .min-purchase .breakdown-final-price {
      display: none; }
    .purchase-details .discount-tiers .min-tooltip, .purchase-details .discount-tiers::before,
    .purchase-details .min-purchase .min-tooltip,
    .purchase-details .min-purchase::before {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 1px; }
  .purchase-details .prices-tier .selected-item {
    color: red; }
  .purchase-details .discount-tiers .price-including-tax .price,
  .purchase-details .discount-tiers .price-excluding-tax .price {
    font-size: 1.3rem; }
  @media (max-width: 1365.98px) {
    .purchase-details .discount-tiers {
      padding-left: 0; }
      .purchase-details .discount-tiers b {
        padding-left: 3.2rem; } }
  .purchase-details .discount-tiers::before {
    background-image: url(../images/icon-dollar.svg); }
  .purchase-details .min-purchase::before {
    background-image: url(../images/icon-tag.svg); }
  .purchase-details .min-tooltip {
    right: 0; }

.sizing-holder {
  overflow-x: auto; }
  .sizing-holder .sizing-table th {
    white-space: nowrap; }

.customer-account-login .page-main {
  margin-bottom: 0; }
  @media (min-width: 1024px) {
    .customer-account-login .page-main {
      margin-bottom: 11.3rem; } }
  @media (max-width: 1023.98px) {
    .customer-account-login .page-main {
      padding-left: 3.3rem;
      padding-right: 3.3rem; } }

.customer-account-login .h2 {
  margin-bottom: 0.6rem; }
  @media (max-width: 1023.98px) {
    .customer-account-login .h2 {
      margin-bottom: 3.4rem; } }

.customer-account-login .message.message {
  margin: 2rem auto; }

@media (min-width: 1024px) {
  .customer-account-login .page-wrapper {
    position: relative;
    z-index: 0;
    background: linear-gradient(to right, #F7F7F7 50%, transparent 50%); }
    .customer-account-login .page-wrapper .remove-bg::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: #fff; } }

.customer-account-login .customer-login-container {
  max-width: 1170px;
  font-size: 1.3rem; }

.customer-account-login .new-customers h2, .customer-account-login .new-customers .h2,
.customer-account-login .block-customer-login .block-title {
  font-size: 1.8rem;
  margin-bottom: 0.5rem; }

.customer-account-login .form-login .password-requirements {
  margin-top: 1.8rem; }
  @media (min-width: 1024px) {
    .customer-account-login .form-login .password-requirements {
      margin-bottom: 5rem; } }
  .customer-account-login .form-login .password-requirements p {
    margin-bottom: 0; }
  .customer-account-login .form-login .password-requirements ol {
    margin-bottom: 1.7rem; }
  .customer-account-login .form-login .password-requirements li {
    margin-bottom: 0; }

.customer-account-login .form-login .actions-container {
  position: relative; }
  .customer-account-login .form-login .actions-container .btn.btn-dark {
    width: 100%; }
  .customer-account-login .form-login .actions-container .action.primary {
    width: 100%;
    border-radius: 3px; }
  @media (min-width: 1024px) {
    .customer-account-login .form-login .actions-container .secondary {
      position: absolute;
      top: calc(100%);
      left: 0; } }
  .customer-account-login .form-login .actions-container .action.remind {
    font-size: 1.1rem;
    color: #262626;
    text-decoration: underline; }
    .customer-account-login .form-login .actions-container .action.remind:hover {
      text-decoration: none; }

.customer-account-login .form-login .fieldset {
  margin-bottom: 0; }

@media (min-width: 1024px) {
  .customer-account-login .customer-login-withcart-row {
    flex-flow: row-reverse; } }

@media (min-width: 1024px) {
  .customer-account-login .returning-customers,
  .customer-account-login .new-customers {
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 4rem; } }

@media (min-width: 1366px) {
  .customer-account-login .returning-customers,
  .customer-account-login .new-customers {
    padding-left: 7.8rem;
    padding-right: 7.8rem;
    margin-top: 6rem; } }

@media (max-width: 1023.98px) {
  .customer-account-login .returning-customers {
    position: relative;
    z-index: 0;
    background-color: #F7F7F7;
    padding: 2rem 0 3.5rem; }
    .customer-account-login .returning-customers:not(:only-child) {
      margin-bottom: 3rem; }
    .customer-account-login .returning-customers::before, .customer-account-login .returning-customers::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc((100vw - 100%) / 2);
      background-color: inherit; }
    .customer-account-login .returning-customers::before {
      left: 100%; }
    .customer-account-login .returning-customers::after {
      right: 100%; } }

.customer-account-login .block-customer-login .field {
  margin-bottom: 2.6rem;
  margin-top: 2.6rem; }
  .customer-account-login .block-customer-login .field.note {
    margin-top: 0; }

.new-customers {
  display: flex;
  flex-direction: column; }
  .new-customers .action-holder {
    margin-top: auto;
    margin-bottom: 36px;
    text-align: center; }
    .new-customers .action-holder a {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem; }
  @media (max-width: 1023.98px) {
    .new-customers {
      margin-bottom: 6rem; }
      .new-customers,
      .new-customers [data-element='inner'][data-element='inner'] {
        padding-left: 0;
        padding-right: 0; } }

.new-customer-text ol {
  margin-top: 2.8rem;
  padding-left: 4.2rem;
  list-style: none;
  line-height: 1.7;
  counter-reset: item; }
  .new-customer-text ol li {
    counter-increment: item;
    margin-bottom: 3rem;
    position: relative; }
    .new-customer-text ol li::before {
      content: counter(item);
      margin-right: 0.9rem;
      background: #fff;
      box-shadow: 1px 1px 6px #00000029;
      border-radius: 100%;
      font-size: 1.3rem;
      line-height: 2.8rem;
      width: 3rem;
      height: 3rem;
      text-align: center;
      display: inline-block;
      position: absolute;
      right: 100%;
      top: 0; }

.customer-account-logoutsuccess .page-main {
  text-align: center;
  margin-top: 3rem; }

.customer-account-forgotpassword,
.customer-account-createpassword {
  font-size: 1.3rem; }
  .customer-account-forgotpassword .page.messages .message,
  .customer-account-createpassword .page.messages .message {
    margin: 0 auto 1rem; }
  @media (max-width: 767.98px) {
    .customer-account-forgotpassword .forget .note,
    .customer-account-createpassword .forget .note {
      line-height: 1.55; } }
  .customer-account-forgotpassword .page-main,
  .customer-account-createpassword .page-main {
    margin-top: 3rem;
    margin-bottom: 3rem; }
    @media (min-width: 768px) {
      .customer-account-forgotpassword .page-main,
      .customer-account-createpassword .page-main {
        margin-top: 6.2rem;
        margin-bottom: 6.2rem; } }
    @media (max-width: 767.98px) {
      .customer-account-forgotpassword .page-main,
      .customer-account-createpassword .page-main {
        padding-left: 3.3rem;
        padding-right: 3.3rem; } }
  @media (max-width: 767.98px) {
    .customer-account-forgotpassword .page-title::before,
    .customer-account-createpassword .page-title::before {
      content: 'Sign In';
      display: block;
      margin-bottom: 1.9rem;
      font-size: 2.8rem;
      font-weight: 400; } }
  .customer-account-forgotpassword .block-title,
  .customer-account-createpassword .block-title {
    margin-bottom: 0;
    font-size: 1.8rem; }
  .customer-account-forgotpassword .form.form,
  .customer-account-createpassword .form.form {
    min-width: 0;
    max-width: 445px; }
  .customer-account-forgotpassword .actions-toolbar .primary,
  .customer-account-createpassword .actions-toolbar .primary {
    width: 100%;
    margin-right: 0; }
    @media (max-width: 767.98px) {
      .customer-account-forgotpassword .actions-toolbar .primary,
      .customer-account-createpassword .actions-toolbar .primary {
        margin-bottom: 4rem; } }

.amsearch-search-page h1, .amsearch-search-page .h1 {
  margin-bottom: 1rem; }

.cms-become-a-peak-customer .page-title,
.page-layout-cms-landing .page-title {
  margin-bottom: 2.3rem; }
  @media (max-width: 1023.98px) {
    .cms-become-a-peak-customer .page-title,
    .page-layout-cms-landing .page-title {
      margin-bottom: 1.8rem; } }

.cms-become-a-peak-customer .page.messages,
.page-layout-cms-landing .page.messages {
  padding: 0; }
  .cms-become-a-peak-customer .page.messages [role='alert']:not(:empty),
  .page-layout-cms-landing .page.messages [role='alert']:not(:empty) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    padding-right: 6.8rem;
    padding-bottom: 3.6rem;
    z-index: 1050; }
    @media (max-width: 767.98px) {
      .cms-become-a-peak-customer .page.messages [role='alert']:not(:empty),
      .page-layout-cms-landing .page.messages [role='alert']:not(:empty) {
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        padding-bottom: 8.8rem; } }
    .cms-become-a-peak-customer .page.messages [role='alert']:not(:empty) .message-container:not(:last-child),
    .page-layout-cms-landing .page.messages [role='alert']:not(:empty) .message-container:not(:last-child) {
      margin-bottom: 1.6rem; }
    .cms-become-a-peak-customer .page.messages [role='alert']:not(:empty) .message-container .message .btn-close,
    .page-layout-cms-landing .page.messages [role='alert']:not(:empty) .message-container .message .btn-close {
      display: block; }
    .cms-become-a-peak-customer .page.messages [role='alert']:not(:empty) .btn-close,
    .cms-become-a-peak-customer .page.messages [role='alert']:not(:empty) .overlay-close,
    .page-layout-cms-landing .page.messages [role='alert']:not(:empty) .btn-close,
    .page-layout-cms-landing .page.messages [role='alert']:not(:empty) .overlay-close {
      display: none; }

.become-a-peak-customer-wrapper {
  max-width: 1368px;
  margin: 0 auto;
  padding: 6rem 2.4rem;
  position: relative; }
  @media (min-width: 1024px) {
    .become-a-peak-customer-wrapper {
      padding: 4rem 2.4rem 8rem; } }
  .become-a-peak-customer-wrapper::after {
    content: '';
    display: block;
    background: url(../images/icon-peak-primary-ellipse.svg);
    width: 59px;
    height: 48px;
    margin: 0 auto;
    margin-top: 3.6rem;
    background-size: cover; }
    @media (min-width: 1024px) {
      .become-a-peak-customer-wrapper::after {
        margin-top: 4rem;
        width: 80px;
        height: 66px; } }
  .become-a-peak-customer-wrapper .header-message [data-content-type='column-line'] {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-bottom: 3.6rem; }
    @media (min-width: 1024px) {
      .become-a-peak-customer-wrapper .header-message [data-content-type='column-line'] {
        flex-direction: row;
        gap: 0;
        margin-bottom: 7.2rem; } }
    .become-a-peak-customer-wrapper .header-message [data-content-type='column-line'] [data-content-type='column'] {
      width: auto !important;
      flex: 0 0 30%; }
      .become-a-peak-customer-wrapper .header-message [data-content-type='column-line'] [data-content-type='column'].contact {
        flex: 0 0 70%; }
  .become-a-peak-customer-wrapper .header-message h3, .become-a-peak-customer-wrapper .header-message .h3 {
    font-size: 2.6rem;
    line-height: 1.07;
    margin-bottom: 0; }
    @media (min-width: 1024px) {
      .become-a-peak-customer-wrapper .header-message h3, .become-a-peak-customer-wrapper .header-message .h3 {
        font-size: 4.6rem;
        line-height: 1.13; } }
  .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-top: 2.2rem; }
    @media (min-width: 1024px) {
      .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child {
        gap: 3.7rem;
        flex-direction: row;
        margin-top: 2.4rem; } }
    .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child a {
      display: flex;
      text-decoration: underline;
      color: #212221;
      font-weight: 600;
      font-size: 1.6rem; }
      @media (min-width: 1024px) {
        .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child a {
          font-size: 2rem; } }
      .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child a:hover {
        color: #FF1A1A; }
      .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child a::before {
        content: '';
        display: inline-block;
        background: url(../images/icon-primary-ellipse.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 1.5rem;
        width: 33px;
        height: 32px; }
      .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child a[href^=tel]::before {
        background: url(../images/icon-phone-ellipse.svg); }
      .become-a-peak-customer-wrapper .header-message .contact > [data-content-type='text']:last-child a[href^=mailto]::before {
        background: url(../images/icon-email-ellipse.svg); }
  .become-a-peak-customer-wrapper .header-message p {
    margin: 0;
    font-size: 1.6rem; }
    @media (min-width: 1024px) {
      .become-a-peak-customer-wrapper .header-message p {
        font-size: 2rem;
        line-height: 1.5; } }

.become-a-peak-customer-form {
  background-color: #F7F7F7;
  position: relative;
  z-index: 1;
  padding: 2.6rem 2.8rem 4.5rem; }
  @media (min-width: 1366px) {
    .become-a-peak-customer-form {
      padding: 6.4rem 11.2rem 8rem; } }
  .become-a-peak-customer-form::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(../images/icon-peak-graphic.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 176px;
    height: 145px;
    z-index: -1; }
  @media (min-width: 1024px) {
    .become-a-peak-customer-form::before {
      content: '';
      width: 355px;
      height: 306px; } }
  .become-a-peak-customer-form .width-1-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3.9rem; }
    @media (max-width: 1023.98px) {
      .become-a-peak-customer-form .width-1-2 {
        gap: 0; } }
    .become-a-peak-customer-form .width-1-2 > div {
      flex: 1; }
      @media (max-width: 1023.98px) {
        .become-a-peak-customer-form .width-1-2 > div {
          width: 100%;
          flex: inherit; } }
  @media (max-width: 1023.98px) {
    .become-a-peak-customer-form .fieldset {
      margin-bottom: 0; } }
  .become-a-peak-customer-form .fieldset .field {
    margin-bottom: 2.8rem; }
    @media (max-width: 1023.98px) {
      .become-a-peak-customer-form .fieldset .field {
        margin-bottom: 1.8rem; } }
    .become-a-peak-customer-form .fieldset .field .label {
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-size: 1.8rem; }
      @media (max-width: 1023.98px) {
        .become-a-peak-customer-form .fieldset .field .label {
          font-size: 1.4rem; } }
    .become-a-peak-customer-form .fieldset .field.required > .label::after {
      content: '*';
      margin: 0 0 0 0.5rem;
      color: #FF1A1A; }
    .become-a-peak-customer-form .fieldset .field .input-text {
      height: 40px;
      padding: 1rem 1.5rem;
      border: initial; }
      @media (min-width: 1024px) {
        .become-a-peak-customer-form .fieldset .field .input-text {
          padding: 1.3rem 1.5rem;
          height: 48px; } }
    .become-a-peak-customer-form .fieldset .field.email .control::before {
      content: none; }
    .become-a-peak-customer-form .fieldset .field.requirements {
      margin-bottom: 2.2rem; }
      @media (min-width: 1024px) {
        .become-a-peak-customer-form .fieldset .field.requirements {
          margin-bottom: 3.2rem; } }
      .become-a-peak-customer-form .fieldset .field.requirements .input-text {
        min-height: 160px; }
  .become-a-peak-customer-form .fieldset > span {
    margin: 3rem 0 0;
    display: block;
    font-size: 1.5rem; }
  .become-a-peak-customer-form .subscribe-newsletter {
    display: flex;
    align-items: center;
    margin-bottom: 2.651rem; }
    .become-a-peak-customer-form .subscribe-newsletter label {
      font-size: 1.3rem;
      margin-left: 1.2rem; }
  .become-a-peak-customer-form .actions-container .action.primary {
    padding: 0.5rem 2rem;
    font-size: 1.4rem;
    line-height: 2;
    width: 100%; }
    @media (min-width: 1024px) {
      .become-a-peak-customer-form .actions-container .action.primary {
        padding: 0.9rem 1rem;
        font-size: 1.6rem;
        letter-spacing: 0.128rem;
        width: 167px; } }
    .become-a-peak-customer-form .actions-container .action.primary span {
      text-transform: uppercase; }

.page-layout-cms-landing .page-main-full-width .columns {
  max-width: 100%; }

.page-layout-cms-landing .suppliers {
  text-align: center;
  padding: 2rem 0; }
  @media (min-width: 1024px) {
    .page-layout-cms-landing .suppliers {
      padding: 4rem 0; } }
  .page-layout-cms-landing .suppliers h3, .page-layout-cms-landing .suppliers .h3 {
    line-height: 1.06;
    font-size: 1.5rem;
    letter-spacing: 0.06rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 6rem; }
    @media (min-width: 1024px) {
      .page-layout-cms-landing .suppliers h3, .page-layout-cms-landing .suppliers .h3 {
        font-size: 2.2rem;
        margin-bottom: 3.2rem; } }
  .page-layout-cms-landing .suppliers [data-content-type='image'] {
    margin: 0 2.4rem; }

.page-layout-cms-landing .btn-primary a {
  color: #fff; }
  .page-layout-cms-landing .btn-primary a:hover {
    text-decoration: none;
    color: #fff; }

.page-layout-cms-landing .btn-outline-primary a:hover {
  text-decoration: none; }

@media (min-width: 768px) {
  .negotiable_quote-quote-view.account.page-layout-2columns-left .column.main {
    max-width: none; } }

.negotiable_quote-quote-view .quote-date,
.negotiable_quote-quote-view .quote-sales-rep {
  float: none; }

.negotiable_quote-quote-view .quote-actions-toolbar {
  margin-top: 1.5rem; }

.negotiable_quote-quote-view .quote-view-links {
  display: flex;
  justify-content: flex-end;
  gap: 2.5rem; }
  @media (max-width: 767.98px) {
    .negotiable_quote-quote-view .quote-view-links {
      display: inherit; } }
  .negotiable_quote-quote-view .quote-view-links .action {
    padding: 1.55rem 4.85rem; }
  .negotiable_quote-quote-view .quote-view-links .btn-close {
    color: #FF1A1A;
    background: none;
    width: auto;
    height: auto; }

.negotiable_quote-quote-view .discount {
  padding-bottom: 0.8rem; }

.negotiable_quote-quote-view .quote-table-items .actions-toolbar a {
  color: #262626;
  text-decoration: underline; }
  .negotiable_quote-quote-view .quote-table-items .actions-toolbar a:hover {
    text-decoration: none; }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-view .product.data.items {
    border-bottom: 1px solid #B2B2B2;
    margin-bottom: 4rem; } }

.negotiable_quote-quote-view .product.data.items::before, .negotiable_quote-quote-view .product.data.items::after {
  content: '';
  display: table; }

@media (min-width: 768px) {
  .negotiable_quote-quote-view .product.data.items > .item.content {
    border: 1px solid #B2B2B2; } }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-view .product.data.items > .item.content {
    padding: 1rem 1.5rem 3rem; } }

.negotiable_quote-quote-view .product.data.items > .item.content::before, .negotiable_quote-quote-view .product.data.items > .item.content::after {
  border-top: none; }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-view .product.data.items > .item.title.title.title {
    width: 100%;
    margin: 0;
    border-top: none; } }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-view .product.data.items > .item.title.title.title.active > .switch::after {
    transform: rotate(-90deg); } }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-view .product.data.items > .item.title.title.title > .switch {
    position: relative;
    border-top: 1px solid #B2B2B2; }
    .negotiable_quote-quote-view .product.data.items > .item.title.title.title > .switch::after {
      content: ' ';
      background-image: url(../images/icon-chevron-right.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      position: absolute;
      right: 0;
      top: 0.5rem;
      transform: rotate(90deg);
      width: 50px;
      height: 50px;
      transition: transform 0.3s ease-out; } }

.negotiable_quote-quote-view .quote-view-buttons button {
  display: inline-block; }

.negotiable_quote-quote-view .field-attachment label {
  color: #000; }
  .negotiable_quote-quote-view .field-attachment label:hover {
    opacity: 0.7; }
  .negotiable_quote-quote-view .field-attachment label::before {
    color: #000; }

.negotiable_quote-quote-view .messages {
  margin-bottom: 1.5rem; }

.negotiable_quote-quote-view #messages {
  margin-bottom: 1.5rem; }
  .negotiable_quote-quote-view #messages .message.message {
    z-index: 0; }
    .negotiable_quote-quote-view #messages .message.message.notice .btn-close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      display: block; }

.negotiable_quote-quote-view .negotiable-quote-note-popup textarea:focus,
.negotiable_quote-quote-view .negotiable-quote-note-popup textarea:hover {
  border-color: #000;
  outline: none; }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-view .negotiable-quote-note-popup .actions-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-view .negotiable-quote-note-popup .actions-toolbar .secondary {
    margin-bottom: 1rem; } }

.negotiable_quote-quote-view .negotiable-quote-note-popup .actions-toolbar .secondary .action {
  color: #000; }
  @media (max-width: 767.98px) {
    .negotiable_quote-quote-view .negotiable-quote-note-popup .actions-toolbar .secondary .action {
      border: none;
      background: none; } }

.negotiable_quote-quote-view .modal-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center; }
  .negotiable_quote-quote-view .modal-footer .action-secondary.action-dismiss {
    border: none;
    background: none; }

.negotiable_quote-quote-send .page-title-wrapper {
  margin-top: 2rem; }

.negotiable_quote-quote-send .negotiable-quote-success {
  margin-bottom: 4rem; }

.negotiable_quote-quote-index .table-wrapper td a {
  color: #262626;
  text-decoration: underline; }
  .negotiable_quote-quote-index .table-wrapper td a:hover {
    text-decoration: none; }

@media (max-width: 767.98px) {
  .negotiable_quote-quote-index .toolbar .pages {
    float: none; } }

.negotiable_quote-quote-index .toolbar .pages .pages-label {
  display: none; }

.negotiable_quote-quote-index .toolbar .pages .pages-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5rem; }
  .negotiable_quote-quote-index .toolbar .pages .pages-items li.pages-item-previous, .negotiable_quote-quote-index .toolbar .pages .pages-items li.pages-item-next,
  .negotiable_quote-quote-index .toolbar .pages .pages-items li.current .label {
    display: none; }
  .negotiable_quote-quote-index .toolbar .pages .pages-items li a {
    color: #262626; }

.negotiable_quote-quote-index .limiter {
  margin-top: -1rem; }
  @media (max-width: 767.98px) {
    .negotiable_quote-quote-index .limiter {
      display: none; } }
