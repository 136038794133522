.spinner-lg {
  width: 4rem;
  height: 4rem;
}

.fotorama__spinner.fotorama__spinner--show {
  display: inline-block;
  vertical-align: text-bottom;
  width: 4rem;
  height: 4rem;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  color: $primary !important;
  background-image: none;
  transform: none;
}
