.offcanvas-header {
  position: relative;
  display: block;
  padding: 1.2rem;
  background-color: $black;
  text-align: center;
  color: $white;

  .btn-close {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    margin-top: 0;
    margin-bottom: 0;
    background-size: 32px 32px;
  }
}

.offcanvas-title {
  font-size: 1.8rem;
}

/* Allowing responsive offcanvas (disable on desktop, enable on mobile */
@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  $infix: breakpoint-infix($next, $grid-breakpoints);

  .offcanvas#{$infix} {

    @include media-breakpoint-down($next) {
      position: fixed;
      bottom: 0;
      z-index: $zindex-offcanvas;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      color: $offcanvas-color;
      visibility: hidden;
      background-color: $offcanvas-bg-color;
      background-clip: padding-box;
      outline: 0;

      @include box-shadow($offcanvas-box-shadow);
      @include transition(transform $offcanvas-transition-duration ease-in-out);

      &.offcanvas-start {
        top: 0;
        left: 0;
        width: $offcanvas-horizontal-width;
        border-right: $offcanvas-border-width solid $offcanvas-border-color;
        transform: translateX(-100%);
      }

      &.offcanvas-end {
        top: 0;
        right: 0;
        width: $offcanvas-horizontal-width;
        border-left: $offcanvas-border-width solid $offcanvas-border-color;
        transform: translateX(100%);
      }

      &.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: $offcanvas-vertical-height;
        max-height: 100%;
        border-bottom: $offcanvas-border-width solid $offcanvas-border-color;
        transform: translateY(-100%);
      }

      &.offcanvas-bottom {
        right: 0;
        left: 0;
        height: $offcanvas-vertical-height;
        max-height: 100%;
        border-top: $offcanvas-border-width solid $offcanvas-border-color;
        transform: translateY(100%);
      }

      &.showing,
      &.show:not(.hiding) {
        transform: none;
      }

      &.showing,
      &.hiding,
      &.show {
        visibility: visible;
      }
    }

    @if not ($infix == '') {

      @include media-breakpoint-up($next) {
        $offcanvas-vertical-height: auto;
        $offcanvas-border-width: 0;

        background-color: transparent !important;
        transform: none;
        width: auto;

        .offcanvas-header {
          display: none;
        }

        .offcanvas-body {
          display: flex;
          flex-grow: 0;
          padding: 0;
          overflow-y: visible;
          background-color: transparent !important;
        }

        + .offcanvas-backdrop {
          display: none;
        }
      }
    }
  }
}
