.info-tiles {
  max-width: 1368px;
  margin: 0 auto;

  &[data-content-type=row][data-appearance=full-width] {
    padding: 0 2.4rem !important;
  }

  [data-content-type='column-group'] {

    &:first-child {
      margin-top: 6rem;

      @include media-breakpoint-up(lg) {
        margin-top: 8rem;
      }
    }

    &:not(:first-child) {
      margin-top: 6rem;

      @include media-breakpoint-up(lg) {
        margin-top: 14rem;
      }
    }

    &:nth-child(even) {

      [data-content-type='column-line'] {
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }
      }

      [data-content-type='column']:nth-child(odd) {
        margin-right: 0;

        @include media-breakpoint-up(lg) {
          margin-right: 11.5rem;
        }
      }
    }

    &:nth-child(odd) {

      [data-content-type='column']:nth-child(even) {
        margin-left: 0;

        @include media-breakpoint-up(lg) {
          margin-left: 11.5rem;
        }
      }
    }

    &:last-child {
      margin-bottom: 6rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 14rem;
      }
    }

    [data-content-type='column-line'] {

      [data-content-type='column'] {
        width: auto !important;
        justify-content: center !important;

        >* {
          max-width: 525px;
          width: 100%;
        }

        [data-content-type='image'] {
          width: 100%;
          max-width: 680px;
        }
      }
    }

    h3 {
      margin-bottom: 1.2rem;
      margin-top: 2.4rem;
      font-size: 2.6rem;
      line-height: 1.076;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: 2rem;
        font-size: 4.6rem;
        line-height: 1.13;
      }
    }

    [data-content-type='buttons'] .btn {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }

    [data-content-type='text'] p {
      font-size: 1.6rem;
      line-height: 1.375;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        line-height: 1.55;
      }

      &:last-child {
        margin-bottom: 2.8rem;

        @include media-breakpoint-up(lg) {
          margin-bottom: 4.4rem;
        }
      }
    }
  }
}
