.product-cards-carousel {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {

    &::after {
      content: '';
    }
  }

  &::after {
    position: absolute;
    background: url(../images/icon-peak-graphic-transparent.svg);
    background-size: contain;
    bottom: 0;
    right: 0;
    width: 382px;
    height: 390px;
    z-index: -1;
  }

  [data-content-type='image'] {
    margin-bottom: 0;
  }

  .slick-slider.slick-initialized .slick-dots {
    padding-top: 0;
  }

  ul:not(.slick-dots) {
    padding: 0;
    visibility: hidden;
    transition: $transition-fade;
    opacity: 0;

    &.slick-initialized {
      visibility: visible;
      opacity: 1;

      .slick-list {

        @include media-breakpoint-down(lg) {
          padding: 0 2.2rem !important;
        }

        @include media-breakpoint-up(lg) {
          padding: 0 11rem !important;
        }
      }
    }

    li {
      padding: 2rem 0;
      max-width: max-content;

      @include media-breakpoint-up(lg) {
        max-width: 316px;
        padding: 4.8rem 0 14.8rem;
      }

      a {
        background-color: $dark;
        text-decoration: none;
        color: $white;
        display: flex;
        flex-direction: column;
        transition: $transition-base;

        &:hover {
          background-color: $primary;
          box-shadow: 2px 4px 20px 0 rgba($primary, 0.8);

          @include media-breakpoint-up(lg) {
            box-shadow: 2px 6px 30px 0 rgba($primary, 0.8);
          }
        }

        p {
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 1;
          padding: 1rem 1.6rem 1.5rem;
          margin-bottom: 0;

          @include media-breakpoint-up(lg) {
            font-size: 2.5rem;
            padding: 2.2rem 3.1rem;
          }

          @include media-breakpoint-up(xl) {
            font-size: 3.2rem;
          }
        }
      }
    }
  }
}
