.btn {

  &.btn {
    border-width: $btn-border-width;
  }

  &.btn-primary,
  &.btn-secondary,
  &.btn-dark,
  &.btn-outline-primary,
  &.btn-outline-dark {
    text-transform: uppercase;

    &:hover {
      box-shadow: $box-shadow;
    }

    &.btn-sm {
      text-transform: none;

      &:hover {
        box-shadow: $box-shadow-sm;
      }
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.btn-outline-primary,
.btn-outline-secondary {

  &:hover {
    color: $white;
  }
}

.btn-primary,
.action.primary,
.action.action-update,
.action.update,
.action-primary.action-accept {

  @include button-variant(
    $primary,
    $primary,
    $color: $white,
    $hover-background: shade-color($primary, $btn-hover-bg-shade-amount),
    $hover-border: shade-color($primary, $btn-hover-border-shade-amount),
    $hover-color: $white,
    $disabled-background: $gray-300,
    $disabled-border: $gray-300,
    $disabled-color: $gray-500
  );
}

.btn-secondary,
.action.secondary,
.action.action-cancel,
.action.reload {

  @include button-variant(
    $secondary,
    $secondary,
    $color: $white,
    $hover-background: shade-color($secondary, $btn-hover-bg-shade-amount),
    $hover-border: shade-color($secondary, $btn-hover-border-shade-amount),
    $hover-color: $white,
    $disabled-background: $gray-300,
    $disabled-border: $gray-300,
    $disabled-color: $gray-500
  );
}

.btn-white {

  @include button-variant(
    $white,
    $white,
    $color: $dark,
    $hover-background: $dark,
    $hover-border: $dark,
    $hover-color: $white,
    $disabled-background: $gray-300,
    $disabled-border: $gray-300,
    $disabled-color: $gray-500
  );
}

.action-primary,
.action-secondary,
.action {

  &::before {
    display: none !important;
  }

  &.primary,
  &.secondary,
  &.action-update,
  &.action-cancel,
  &.reload,
  &.update,
  &.action-accept {
    text-transform: uppercase;
    border-radius: $btn-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: $btn-border-width;
    border-style: solid;
    padding: $btn-padding-y $btn-padding-x;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: 1.3;
    color: $white;

    @include transition($btn-transition);

    &:hover,
    &:focus {
      box-shadow: $box-shadow;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &.clear,
  &.back,
  &.link {
    background: none;
    border: none;
    text-decoration: underline;
    font-weight: normal;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.continue,
  &.update {
    padding-left: $btn-padding-x !important;
    padding-right: $btn-padding-x !important;
  }
}

.btn-close {

  &:hover {
    border: none;
  }
}
