.brands,
.suppliers {
  padding: 6rem 0 4rem;
  text-align: center;
  max-width: 1384px;
  margin: 0 auto;

  [data-content-type='image'] {
    margin: 0 2.4rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 8rem 0;
  }

  h3 {
    line-height: 1.06;
    font-size: 1.5rem;
    letter-spacing: 0.06rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 6rem;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.2rem;
      margin-bottom: 3.6rem;
    }
  }

  &-carousel {
    margin: 0 auto;
    max-width: 100vw;
    visibility: hidden;
    transition: $transition-fade;
    opacity: 0;

    &.slick-initialized {
      visibility: visible;
      opacity: 1;
    }

    li {
      display: flex;
      justify-content: center;

      img {
        margin: 0 auto;
        width: auto !important;
      }
    }
  }
}
