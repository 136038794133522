.modals-overlay {
  background-color: rgba(30, 31, 30, 0.41);
}

.modal-popup {

  .modal-header,
  .modal-content,
  .modal-footer {

    @include media-breakpoint-up(md) {
      padding-left: 5.9rem;
      padding-right: 5.9rem;
    }
  }

  .modal-title {
    font-weight: 400;
    font-size: 2.8rem;
    border-bottom: none;
  }

  .action-close {
    width: 50px;
    height: 50px;
    background: url(../images/icon-close.svg);
    background-size: 32px 32px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    font-size: 0;
  }

  &.modal-slide .modal-footer {
    border-top: none;
  }
}
