.negotiable_quote-quote-view {

  @include media-breakpoint-up(md) {

    &.account.page-layout-2columns-left .column.main {
      max-width: none;
    }
  }

  .quote-date,
  .quote-sales-rep {
    float: none;
  }

  .quote-actions-toolbar {
    margin-top: 1.5rem;
  }

  .quote-view-links {
    display: flex;
    justify-content: flex-end;
    gap: 2.5rem;

    @include media-breakpoint-down(md) {
      display: inherit;
    }

    .action {
      padding: 1.55rem 4.85rem;
    }

    .btn-close {
      color: $red;
      background: none;
      width: auto;
      height: auto;
    }
  }

  .discount {
    padding-bottom: 0.8rem;
  }

  .quote-table-items {

    .actions-toolbar a {
      color: $body-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .product.data.items {

    @include media-breakpoint-down(md) {
      border-bottom: $form-check-input-border;
      margin-bottom: 4rem;
    }

    &::before,
    &::after {
      content: '';
      display: table;
    }

    > .item {

      &.content {

        @include media-breakpoint-up(md) {
          border: $form-check-input-border;
        }

        @include media-breakpoint-down(md) {
          padding: 1rem 1.5rem 3rem;
        }

        &::before,
        &::after {
          border-top: none;
        }
      }

      &.title.title.title {

        @include media-breakpoint-down(md) {
          width: 100%;
          margin: 0;
          border-top: none;
        }

        &.active > .switch::after {

          @include media-breakpoint-down(md) {
            transform: rotate(-90deg);
          }
        }

        > .switch {

          @include media-breakpoint-down(md) {
            position: relative;
            border-top: $form-check-input-border;

            &::after {
              content: ' ';
              background-image: url(../images/icon-chevron-right.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: 24px 24px;
              position: absolute;
              right: 0;
              top: 0.5rem;
              transform: rotate(90deg);
              width: 50px;
              height: 50px;
              transition: transform 0.3s ease-out;
            }
          }
        }
      }
    }
  }

  .quote-view-buttons {

    button {
      display: inline-block;
    }
  }

  .field-attachment {

    label {
      color: $black;

      &:hover {
        opacity: 0.7;
      }

      &::before {
        color: $black;
      }
    }
  }

  .messages {
    margin-bottom: 1.5rem;
  }

  #messages {
    margin-bottom: 1.5rem;

    .message.message {
      z-index: 0;

      &.notice {

        .btn-close {
          position: absolute;
          top: 2rem;
          right: 2rem;
          display: block;
        }
      }
    }
  }

  .negotiable-quote-note-popup {

    textarea:focus,
    textarea:hover {
      border-color: $black;
      outline: none;
    }

    .actions-toolbar {

      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .secondary {

        @include media-breakpoint-down(md) {
          margin-bottom: 1rem;
        }

        .action {
          color: $black;

          @include media-breakpoint-down(md) {
            border: none;
            background: none;
          }
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .action-secondary.action-dismiss {
      border: none;
      background: none;
    }
  }
}
