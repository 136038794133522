.about {
  max-width: 1320px;
  margin: 0 auto;

  .btn-outline-primary {
    border-width: 2px;

    &:hover {

      a {
        color: $white;
      }
    }
  }

  h2[data-content-type='heading'] {
    font-size: 2.6rem;
    line-height: 1.076;
    font-weight: 600;
    margin-bottom: 1.4rem;
    letter-spacing: -0.104rem;

    @include media-breakpoint-up(lg) {
      font-size: 4.6rem;
      line-height: 1.13;
      letter-spacing: -0.184rem;
      margin: 0;
      padding-right: 4rem;
    }
  }

  [data-content-type='image'] {
    margin-bottom: 0;
  }

  .slick-slider {

    .slick-list {
      padding: 0 5.6rem !important;
      margin: 0 -1.3rem;

      .slick-slide {
        margin: 0 1.3rem;
      }
    }

    .slick-arrow {
      top: 100px;
    }

    .slick-dots {
      padding-top: 3.6rem;
    }
  }

  [data-content-type='column-group']:not(.tiles) {

    .pagebuilder-column-line {
      padding: 0 2.4rem;

      @include media-breakpoint-up(xl) {
        padding: 0;
      }
    }

    [data-content-type='column'] {

      p {
        font-size: 1.4rem;
        line-height: 1.428;
        font-weight: 300;
        margin-bottom: 3.2rem;

        @include media-breakpoint-up(lg) {
          font-size: 2rem;
          line-height: 1.5;
          margin-bottom: 2.8rem;
        }
      }

      [data-content-type='buttons'] {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          gap: 2.4rem;
        }

        a:hover {
          text-decoration: none;
        }
      }
    }
  }

  .tiles {
    margin-top: 4.8rem;
    margin-bottom: 6rem;

    @include media-breakpoint-up(lg) {
      margin-top: 5.6rem;
      margin-bottom: 13.6rem;
    }

    h3 {
      line-height: 1;
      font-size: 2.2rem;
      margin-bottom: 1rem;
      font-weight: 600;

      @include media-breakpoint-up(lg) {
        font-size: 3.2rem;
      }
    }

    .pagebuilder-column-line {
      padding: 0 2.4rem;

      @include media-breakpoint-down(lg) {
        visibility: hidden;
        transition: $transition-fade;
        opacity: 0;

        &.slick-initialized {
          visibility: visible;
          opacity: 1;
          padding: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        gap: 3.4rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 0;
      }

      [data-content-type='image'] {
        margin-bottom: 2.6rem;

        @include media-breakpoint-up(lg) {
          margin-bottom: 3.4rem;
        }
      }

      [data-content-type='text'] {

        @include media-breakpoint-up(lg) {
          padding-right: 1rem;
        }

        @include media-breakpoint-up(xl) {
          padding-right: 2.5rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 1.428;
          margin-bottom: 0;

          @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
          }

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
