.prefooter {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 4rem 2rem;
  background-color: $primary-dark;
  color: $white;
  font-size: 1.5rem;
  font-weight: 300;

  @include media-breakpoint-up(md) {
    padding: 2.775rem 0;
    padding: 3rem 0.5rem;
    font-size: 1.6rem;

    > div {
      max-width: 1150px;
    }
  }

  @include media-breakpoint-down(md) {
    line-height: 1.6;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  [data-element='inner'] {
    flex-direction: row !important;
    align-items: center;
  }

  [data-content-type='text'] {
    flex-grow: 1;

    *:last-child {
      margin-bottom: 0;
    }
  }

  [data-content-type='html'] {

    @include media-breakpoint-down(md) {
      position: absolute;
      top: 4rem;
      right: 1.9rem;
    }
  }

  h2 {
    margin-bottom: 2.5rem;
    font-size: 2.4rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0.5rem;
      font-size: 2.2rem;
    }
  }

  .btn {
    padding: 1.5rem 3.85rem;
    font-size: 1.4rem;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    @media (max-width: 345px) {
      padding-left: 2.3rem;
      padding-right: 2.3rem;
    }
  }
}

.page-footer {

  .footer.content {
    max-width: none;
    margin: 0;
    padding: 0;
  }

  .footer-container {
    padding: 7.4rem 0 6.2rem;
    background-color: $dark;
    color: $white;
    font-size: 1.3rem;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    @include media-breakpoint-up(md) {

      .footer-links {
        max-width: 1165px;
      }
    }

    a {
      color: inherit !important;
    }
  }

  .footer {
    border-top: none;
  }

  .footer-links {

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  .accordion {
    justify-content: space-between;

    h2 {
      font-size: 2rem;
    }

    [data-element='inner'][data-element='inner'] {
      padding-left: 0;
      padding-right: 0;
    }

    a {
      display: block;
      margin-bottom: 0.7rem;
      font-weight: 300;

      @include media-breakpoint-down(md) {
        margin-bottom: 1.7rem;
      }
    }

    .btn-link {
      border: none;
      font-size: 1.6rem;
      color: inherit;

      &:hover,
      &:focus,
      &:active {
        background: none;
        border: none;
        box-shadow: none;
      }
    }

    @include media-breakpoint-up(lg) {

      .footer-link {

        &:nth-child(1),
        &:nth-child(2) {

          ul {
            column-count: 2;
          }
        }

        &:nth-child(1) ul {
          max-width: 300px;
        }

        &:nth-child(2) ul {
          max-width: 435px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      font-weight: 700;

      .collapse,
      .collapsing {
        display: block !important;
        height: auto !important;
      }

      .btn-link {
        font-weight: 400;
        margin-bottom: 1.9rem;
        padding: 0.6rem;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          cursor: default;
          box-shadow: none !important;
        }

        img {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 2.3rem;
      margin-left: -3rem;
      margin-right: -3rem;
      font-weight: normal;

      .footer-content {
        padding: 2rem 2rem 1rem;
        border-top: 1px solid #363736;
      }

      .footer-link {
        padding: 0;

        &:nth-child(1),
        &:nth-child(2) {

          ul {
            column-count: 2;
          }
        }
      }

      .btn-link.btn-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: -1px;
        padding: 1.8rem 2.4rem 1.8rem 3.4rem;
        font-weight: 700;
        border-top: 1px solid #363736;
        border-bottom: 1px solid #363736;
        font-weight: normal;
        text-decoration: none;

        &,
        &:hover,
        &:focus,
        &:active {
          color: $white;
        }

        &:focus {
          box-shadow: none;
        }

        &:not(.collapsed) img {
          transform: rotate(180deg);
        }
      }

      ul {
        padding-bottom: 0.8rem;
      }

      [data-content-type='text'] {
        padding: 1.8rem 2.4rem 1.8rem 3.4rem;
      }
    }
  }

  .text-headings {
    font-weight: 300;

    p:first-child {
      margin-bottom: 1.2rem;

      a {
        font-weight: 600;
        font-size: 2.1rem;
      }
    }

    strong {
      font-weight: 700;
    }

    @include media-breakpoint-down(md) {

      [data-element='inner'][data-element='inner'] {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
      }

      [data-content-type='text'] {
        display: flex;
        flex-wrap: wrap;
      }

      p:first-child {
        width: 100%;

        a {
          font-size: 2.2rem;
        }
      }

      p:not(:last-child) {
        margin-right: 8.6rem;
      }
    }

    @include media-breakpoint-only(xl) {

      [data-content-type='text'] {
        margin-right: -4rem;
      }
    }
  }

  .social-links {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-down(md) {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }

    div {
      padding: 0 !important;
    }

    ul {
      list-style: none;
      display: flex;
      margin-bottom: 0;
      padding-left: 0;

      li + li {
        margin-left: 1.75rem;
      }

      @include media-breakpoint-down(lg) {

        li + li {
          margin-left: 2.6rem;
        }
      }
    }

    a {
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 0;
      width: 34px;
      height: 34px;
      transition: opacity 0.2s;
      background-size: auto 34px;

      &[href*='facebook'] {
        background-image: url('../images/icon-facebook.svg');
      }

      &[href*='linkedin'] {
        background-image: url('../images/icon-linkedin.svg');
      }

      &[href*='instagram'] {
        background-image: url('../images/icon-instagram.svg');
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .copyright {

    @include media-breakpoint-up(md) {
      margin: 4rem 0 -4rem;
    }
  }
}
