.input-text,
.select {
  display: block;
  width: 100%;
  height: 50px;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  /* To get around issues with increasing font size on computer/browser */
  &.select {
    padding: 0.5rem $input-padding-x;
  }

  @include font-size($input-font-size);
  @include border-radius($input-border-radius, 0);
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow); /* stylelint-disable-line */
    } @else { /* stylelint-disable-line */
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    opacity: 1;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    label + & {
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }
  }

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.form-control,
.input-text,
.select {
  border-width: $input-border-width;
  transition: $transition-base;

  &:focus {
    box-shadow: $input-btn-focus-box-shadow;
  }

  &.mage-error {
    border-color: $danger;
  }
}

div.mage-error.mage-error {
  width: 100%;
  margin-top: 0;
  padding-left: 0.2rem;
  font-size: 1.2rem;
}

textarea.input-text {
  height: auto;
}

select {
  appearance: none;
  display: block;
  width: 100%;
  height: auto;
  padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
  -moz-padding-start: subtract($form-select-padding-x, 3px);
  font-family: $form-select-font-family;
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  color: $form-select-color;
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: $form-select-border-width solid $form-select-border-color;

  @include font-size($form-select-font-size);
  @include border-radius($form-select-border-radius, 0);
  @include box-shadow($form-select-box-shadow);
  @include transition($form-select-transition);

  &:focus {
    border-color: $form-select-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow); /* stylelint-disable-line */
    } @else { /* stylelint-disable-line */
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  &[multiple],
  &[size]:not([size='1']) {
    padding-right: $form-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $form-select-color;
  }
}

.label {
  margin-bottom: $form-label-margin-bottom;
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: $form-label-color;

  @include font-size($form-label-font-size);
}

.fieldset {
  margin-bottom: 2.9rem;

  .legend,
  .field:not(.choice) > .control,
  .field:not(.choice) > .label {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    text-align: left;
  }

  .street .control .label {

    @include visually-hidden();
  }

  .legend {
    margin-bottom: 1rem;
  }

  .field {
    margin-bottom: 1.2rem;

    .field::before {
      display: none;
    }

    &.choice::before {
      display: none;
    }

    &.required,
    &._required {

      > .label {
        display: flex;
        flex-wrap: wrap;

        &::after {
          content: '(Required)';
          color: inherit;
          font-size: inherit;
          margin: 0 0 0 0.3rem;
        }
      }
    }
  }
}

.actions-toolbar.actions-toolbar.actions-toolbar.actions-toolbar {
  margin-left: 0;
}

.form-check {
  line-height: 1.5;
  font-size: 1.3rem;

  .form-check-input {

    &[type='checkbox']:not(:checked):hover {
      background-image: escape-svg($form-check-input-unchecked-bg-image);
    }

    &[type='radio'] {
      margin-top: -0.3rem;
      background-size: 1.4rem;

      &:not(:checked):hover {
        background-image: escape-svg($form-check-radio-unchecked-bg-image);
      }
    }
  }
}

.custom-email,
.custom-password {

  &::placeholder {
    color: $body-color;
  }
}

.control::before {

  .field.email & {
    background-image: url(../images/icon-email.svg);
  }

  .field.password & {
    background-image: url(../images/icon-password.svg);
  }
}

.field {

  &.email,
  &.password {

    .control {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 1.3rem;
        left: 1.5rem;
        z-index: 3;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        pointer-events: none;
      }
    }

    .form-control,
    .input-text {
      padding-left: 4.9rem;
    }
  }
}
