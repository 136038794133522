.ose-store-notice {
  min-height: 4.5rem;

  .notice-content {
    height: 100%;

    > p {
      height: 100%;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
