.breadcrumb {
  margin-top: 0.8rem;
  color: $breadcrumb-divider-color;

  @include media-breakpoint-down(md) {
    margin-top: 1.5rem;
    justify-content: center;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .breadcrumb-item {
    margin-bottom: 0;

    &:not(:last-child) {

      a {
        font-weight: 700;
      }

      &::after {
        content: '';
        width: 22px;
        height: 22px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M10.06 18.531 9 17.47 14.47 12 9 6.53l1.06-1.06L16.59 12z'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        float: right;
        opacity: 0.35;
        margin-top: 1px;
        margin-right: -8px;
      }
    }
  }
}
