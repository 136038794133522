$contained-width: 871px;

.cms-page-view {

  .breadcrumb {
    margin-bottom: 0.8rem;
  }

  .page-main-full-width {
    margin: 0 auto 1.9rem;

    @include media-breakpoint-up(md) {
      line-height: 1.765;
      font-size: 1.7rem;
      width: 100%;

      .columns {
        max-width: 1293px;
        margin: 0 auto;
      }
    }
  }

  .page-main {
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 9rem;
    }
  }

  [data-appearance='full-bleed'] {

    img {
      width: 100%;
    }
  }

  .main {

    [data-appearance='contained'] {
      max-width: $contained-width;
    }

    [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {

      @include media-breakpoint-down(md) {
        padding: 0 3.5rem;
      }
    }
  }

  h1 {
    margin-top: 1.7rem;
    margin-bottom: 3.6rem;
    font-size: $h2-font-size;
    text-align: center;
  }

  h2 {
    margin-bottom: 1.6rem;
    font-size: $h4-font-size;
  }

  p {
    margin-bottom: 1.9rem;
  }

  .branding-emphasis.branding-emphasis.branding-emphasis.branding-emphasis {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    padding: 2.5rem 3rem;
    background-color: $light;

    @include media-breakpoint-down(md) {
      margin: 2rem 3.5rem 2.5rem;
    }

    h2,
    p {
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {

    .cms-right {
      padding-right: 0 !important;

      figure {
        margin-right: calc(((100vw - #{$contained-width}) / 2) * -1);

        img {
          width: 100%;
        }
      }
    }
  }
}

@import 'cms/all';
