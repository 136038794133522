.customer-account-login {

  .page-main {
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      margin-bottom: 11.3rem;
    }

    @include media-breakpoint-down(lg) {
      padding-left: 3.3rem;
      padding-right: 3.3rem;
    }
  }

  .h2 {
    margin-bottom: 0.6rem;

    @include media-breakpoint-down(lg) {
      margin-bottom: 3.4rem;
    }
  }

  .message.message {
    margin: 2rem auto;
  }

  .page-wrapper {

    @include media-breakpoint-up(lg) {
      position: relative;
      z-index: 0;
      background: linear-gradient(to right, $light 50%, transparent 50%);

      .remove-bg::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: $white;
      }
    }
  }

  .customer-login-container {
    max-width: 1170px;
    font-size: 1.3rem;
  }

  .new-customers h2,
  .block-customer-login .block-title {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  .form-login {

    .password-requirements {
      margin-top: 1.8rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 5rem;
      }

      p {
        margin-bottom: 0;
      }

      ol {
        margin-bottom: 1.7rem;
      }

      li {
        margin-bottom: 0;
      }
    }

    .actions-container {
      position: relative;

      .btn.btn-dark {
        width: 100%;
      }

      .action.primary {
        width: 100%;
        border-radius: 3px;
      }

      .secondary {

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: calc(100%);
          left: 0;
        }
      }

      .action.remind {
        font-size: 1.1rem;
        color: $body-color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .fieldset {
      margin-bottom: 0;
    }
  }

  .customer-login-withcart-row {

    @include media-breakpoint-up(lg) {
      flex-flow: row-reverse;
    }
  }

  .returning-customers,
  .new-customers {

    @include media-breakpoint-up(lg) {
      padding-left: 4rem;
      padding-right: 4rem;
      margin-top: 4rem;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 7.8rem;
      padding-right: 7.8rem;
      margin-top: 6rem;
    }
  }

  .returning-customers {

    @include media-breakpoint-down(lg) {
      position: relative;
      z-index: 0;
      background-color: $light;
      padding: 2rem 0 3.5rem;

      &:not(:only-child) {
        margin-bottom: 3rem;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: calc((100vw - 100%) / 2);
        background-color: inherit;
      }

      &::before {
        left: 100%;
      }

      &::after {
        right: 100%;
      }
    }
  }

  .block-customer-login .field {
    margin-bottom: 2.6rem;
    margin-top: 2.6rem;

    &.note {
      margin-top: 0;
    }
  }
}

.new-customers {
  display: flex;
  flex-direction: column;

  .action-holder {
    margin-top: auto;
    margin-bottom: 36px;
    text-align: center;

    a {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 6rem;

    &,
    [data-element='inner'][data-element='inner'] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.new-customer-text {

  ol {
    margin-top: 2.8rem;
    padding-left: 4.2rem;
    list-style: none;
    line-height: 1.7;
    counter-reset: item;

    li {
      counter-increment: item;
      margin-bottom: 3rem;
      position: relative;

      &::before {
        content: counter(item);
        margin-right: 0.9rem;
        background: $white;
        box-shadow: 1px 1px 6px #00000029;
        border-radius: 100%;
        font-size: 1.3rem;
        line-height: 2.8rem;
        width: 3rem;
        height: 3rem;
        text-align: center;
        display: inline-block;
        position: absolute;
        right: 100%;
        top: 0;
      }
    }
  }
}

.customer-account-logoutsuccess .page-main {
  text-align: center;
  margin-top: 3rem;
}

.customer-account-forgotpassword,
.customer-account-createpassword {
  font-size: 1.3rem;

  .page.messages .message {
    margin: 0 auto 1rem;
  }

  .forget .note {

    @include media-breakpoint-down(md) {
      line-height: 1.55;
    }
  }

  .page-main {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-top: 6.2rem;
      margin-bottom: 6.2rem;
    }

    @include media-breakpoint-down(md) {
      padding-left: 3.3rem;
      padding-right: 3.3rem;
    }
  }

  .page-title {

    &::before {

      @include media-breakpoint-down(md) {
        content: 'Sign In';
        display: block;
        margin-bottom: 1.9rem;
        font-size: 2.8rem;
        font-weight: 400;
      }
    }
  }

  .block-title {
    margin-bottom: 0;
    font-size: $h4-font-size;
  }

  .form.form {
    min-width: 0;
    max-width: 445px;
  }

  .actions-toolbar {

    .primary {
      width: 100%;
      margin-right: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 4rem;
      }
    }
  }
}
