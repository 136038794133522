.category-grid {

  .pagebuilder-column-group {

    @include media-breakpoint-up(xl) {
      margin: 0 -1.15rem;
      padding: 0 2.3rem;
    }
  }

  .pagebuilder-column {
    padding: 0 1.7rem 3rem;

    @include media-breakpoint-up(md) {
      padding: 0 2.3rem 2.3rem;
    }

    .category-block {
      padding: 2rem;
    }
  }

  .pagebuilder-banner-wrapper {
    min-height: 0 !important;
    background-position: top !important;
    background-size: contain !important;
  }

  [data-element='content'] {
    min-height: 0;
    overflow: visible;
  }

  .pagebuilder-overlay {
    margin-left: auto;
    margin-right: auto;
    padding: 100% 0 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      margin-bottom: $grid-gutter-width;
    }
  }

  .category-block {
    position: relative;
    padding: 1.5rem;
    background-color: $white;
    text-align: left;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
      padding: 2.5rem;
    }

    &:hover {
      box-shadow: 0 0 10px rgba($black, 0.06);
    }

    .cat-image {
      text-align: center;
    }

    .cat-name a,
    .pagebuilder-overlay a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $body-color;
      font-weight: 700;
      font-size: 1.5rem;

      @include media-breakpoint-down(md) {
        font-size: 1.3rem;
      }

      &:hover {
        text-decoration: none;

        strong,
        span,
        &::after {
          transform: translateY(-10px);
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      strong,
      span {
        transition: transform 0.3s ease-out;
      }

      &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='m16.297 7.794-1.414 1.414 6.293 6.293H7.59v2h13.586l-6.293 6.293 1.414 1.414 8.707-8.707z'/%3E%3C/svg%3E");
        background-size: 24px 24px;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        margin-top: 0.1rem;
        margin-right: 0.5rem;
        transition: transform 0.3s ease-out;
      }
    }

    .pagebuilder-overlay {

      p {
        margin-bottom: 1.3rem;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          margin: 0 1rem 1.3rem 0;
        }

        a {
          position: relative;
          z-index: 1;
          padding: 0.37rem 1.365rem;
          line-height: 1.8;
          font-weight: 400;
          font-size: 1.2rem;
          background-color: $gray-100;
          border-radius: $border-radius-pill;
          transition: $transition-base;

          &::before,
          &::after {
            display: none;
          }

          &:hover {
            background-color: $dark;
            color: $light;
          }
        }
      }
    }
  }
}

.category-banner {

  p:last-child a:only-child {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;

    @include button-variant(
      $primary,
      $primary,
      $color: $white,
      $hover-background: shade-color($primary, $btn-hover-bg-shade-amount),
      $hover-border: shade-color($primary, $btn-hover-border-shade-amount),
      $hover-color: $white,
      $disabled-background: $gray-300,
      $disabled-border: $gray-300,
      $disabled-color: $gray-500
    );
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  }
}

.catalog-category-view {

  .product-item {

    .price-container {

      .weee {

        &::after {
          content: '';
        }
      }
    }
  }
}
