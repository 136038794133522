// Global product styles

.catalog-product-view {

  .page-main-container {
    overflow-x: hidden;

    .page-title-wrapper .page-title {
      margin-top: 0;
      font-size: 2.2rem;
      line-height: 1.1;
      font-weight: 400;

      @include media-breakpoint-up(md) {
        font-size: 2.8rem;
      }

      @include media-breakpoint-down(md) {
        padding: 0 3.2rem;
      }
    }

    > .page-title-wrapper {

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .page-main {

    @include media-breakpoint-up(md) {
      max-width: map-get($grid-breakpoints, 'xl');
      padding: 0;
    }

    @include media-breakpoint-down(md) {
      padding: 0 3.2rem;
    }
  }

  .column.main {

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .product-info-main {
    background-color: $light;

    .page-title-wrapper {

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      margin-top: 2.7rem;
      margin-left: -3.2rem;
      margin-right: -3.2rem;
    }

    @include media-breakpoint-down(lg) {
      padding: 2.9rem 3.2rem 6.5rem;
    }

    @include media-breakpoint-up(md) {
      margin-top: -4.6rem;
      margin-bottom: -6.7rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 5rem 7.5rem;
      width: 41.435%;
    }
  }

  .product.media {

    @include media-breakpoint-up(md) {
      order: -1;
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% - 41.435%);
      padding-right: 4.9rem;
      padding-left: 14.3984rem;
    }
  }

  ._block-content-loading {

    @include media-breakpoint-up(lg) {
      height: 517px;

      .gallery-placeholder__image {
        padding-left: 8.152rem;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(2rem, -50%);
      }
    }
  }
}

.box-tocart {
  display: flex;

  .control {
    position: relative;

    &:focus-within .qty {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
    }
  }

  .btn-qty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    &[data-direction='minus'] {
      left: 0;
    }

    &[data-direction='add'] {
      right: 0;
    }

    &:focus-visible {
      opacity: 0.7;
    }
  }

  .field.qty {
    flex-grow: 1;
    max-width: 379px;
    margin-right: 2rem;
  }

  input.qty.qty.qty.qty {
    margin-top: 0;
    width: 100%;
    border-radius: $border-radius;
    text-align: center;
    font-size: 1.8rem;
  }

  .fieldset {
    margin-bottom: 0;
  }
}

.toolbar-products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: relative;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    padding: 0;
  }

  &.toolbar-top {

    .show-all-limiter {

      @include media-breakpoint-down(lg) {
        bottom: 0;
      }
    }
  }

  .show-all-limiter {
    order: 3;
    position: absolute;
    right: 0;

    a {
      color: $body-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    & + .pages {
      padding-left: 7rem;
      padding-right: 7rem;

      @include media-breakpoint-down(lg) {
        padding-left: 0;
      }

      @include media-breakpoint-down(md) {
        padding-left: 7rem;
      }

      @media (max-width: 370px) {
        justify-content: flex-end !important;
      }
    }
  }

  .pages {
    order: 5;

    &.pages.pages.pages {
      float: none !important;
      display: block;

      @include media-breakpoint-down(md) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    #paging-label {
      display: none;
    }
  }

  &::before,
  &::after,
  .modes {
    display: none !important;
  }

  .limiter.limiter.limiter {
    float: none;
    order: 1;
  }

  .toolbar-amount {
    order: 3;
    padding-top: 0;
  }

  .toolbar-sorter {
    float: none;
    order: 1;
    padding: 0;

    .sorter-action,
    .sorter-label {
      display: none;
    }

    .sorter-options {
      margin: 0;
      color: $gray-500;
    }

    @include media-breakpoint-up(lg) {

      .refine-by {
        display: none;
      }
    }

    @include media-breakpoint-down(lg) {
      width: calc(100% + #{$grid-gutter-width});
      margin-left: -$grid-gutter-width / 2;
      margin-right: -$grid-gutter-width / 2;
      display: flex;
      margin-bottom: 3rem;

      .refine-by,
      .sorter-options {
        width: 50%;
        height: 60px;
        background-color: $gray-50;
        border: none;
        font-size: 1.3rem;
        line-height: 1.6rem;
        padding: 2.2rem 2.5rem;
        color: $body-color;
      }

      .sorter-options {
        margin-right: 1px;
      }

      .refine-by {
        margin-left: 1px;
        text-align: left;
        position: relative;

        &::after {
          content: ' ';
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          top: 50%;
          right: 17px;
          transform: translateY(-50%);
          background-image: url(../images/icon-filter.svg);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }
}

.fotorama--fullscreen {
  background-color: rgba($white, 0.9) !important;

  .fotorama__wrap,
  .fotorama__stage {
    height: 100% !important;
  }

  .fotorama__nav-wrap,
  .fotorama__zoom-in,
  .fotorama__zoom-out {
    display: none !important;
  }

  .fotorama__arr__arr,
  .fotorama__fullscreen-icon {
    background-position: center !important;
  }

  .fotorama__arr,
  .fotorama__thumb__arr,
  .fotorama__fullscreen-icon {
    display: block !important;
    background-color: transparent !important;
    background-repeat: no-repeat;

    &:hover {
      background-color: rgba($dark, 0.2) !important;
    }
  }

  .fotorama__arr {
    width: 80px;
    height: 80px;
    margin: auto 0;
  }

  .fotorama__arr--prev,
  .fotorama__arr--next {

    .fotorama__arr__arr {
      background-image: url(../images/icon-chevron-right.svg);
      background-size: 32px 32px;
    }
  }

  .fotorama__arr--prev .fotorama__arr__arr {
    transform-origin: 20px 20px;
    transform: rotate(180deg);
  }

  .fotorama__fullscreen-icon {
    background-image: url(../images/icon-close.svg);
    background-size: 32px 32px;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
    }
  }
}

.fotorama__stage {

  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
  }

  @include media-breakpoint-down(md) {

    .fotorama__arr {
      display: block !important;
      width: 50%;
      background: none;

      &.fotorama__arr--prev .fotorama__arr__arr {
        transform: translateY(-50%) rotate(180deg);
        left: 0;
      }
    }

    .fotorama__arr__arr {
      background-image: url(../images/icon-chevron-right.svg);
      background-size: 24px 24px;
      background-repeat: no-repeat;
      transform: translateY(-50%);
      background-position: right center;
    }
  }

  .fotorama__img {
    position: static;
    transform: none;
  }
}

.fotorama__nav-wrap--vertical {

  @include media-breakpoint-up(md) {

    .fotorama__nav--thumbs .fotorama__nav__frame.fotorama__nav__frame {
      padding: 1.5rem 0.4rem;
    }
  }
}

.fotorama__thumb-border.fotorama__thumb-border.fotorama__thumb-border {
  background: none;
  border: 1px solid $dark;
  border-radius: 3px;
}

.magnify-lens {

  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.product-info-main {

  .attribute.sku {

    strong {
      display: none;
    }

    .value {
      color: $body-color;
    }
  }

  .product-info-price {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 5rem;
    }

    .price-label {
      display: none !important;
    }

    .special-price {
      margin: 0;
    }

    .old-price {
      order: -1;
      padding-right: 1rem;
      text-decoration: none;

      .price {
        text-decoration: line-through;
      }
    }

    .price-box {
      display: inline-flex;
      flex-wrap: wrap;
      margin: 1rem 0;

      @include media-breakpoint-down(md) {
        margin-top: 0;
      }

      &:empty {
        display: none;
      }

      .price-wrapper {

        &::after {
          content: 'Price includes GST';
          display: block;
          margin-top: 1rem;
        }

        .old-price & {
          display: none;
        }
      }

      .price-wrapper .price {
        color: $body-color;
        font-size: 2.2rem;
        font-weight: 700;

        @include media-breakpoint-up(md) {
          font-size: 2.8rem;
        }

        &::after {
          content: ' per unit';
        }
      }

      .old-price .price-wrapper .price {

        &::after {
          display: none;
        }
      }
    }
  }

  .box-tocart.box-tocart.box-tocart {
    display: flex;

    @include media-breakpoint-down(md) {
      margin-top: 0.5rem;
    }

    .fieldset {
      width: 100%;
    }

    .field {
      display: flex;
      max-width: none;
      margin: 0;
      padding: 0;

      .control {
        width: 100%;

        div.mage-error.mage-error {
          position: absolute;
          top: 100%;
          left: 0;
          margin-top: -0.5rem;
        }
      }
    }

    .label {
      display: none;
    }

    .actions {
      display: block;
      padding-top: 2rem;

      .tocart {
        width: 100%;
      }
    }
  }

  .shipping-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.1rem;
    font-size: 1.3rem;

    img {
      margin-right: 0.4rem;
    }
  }

  .add-multi-products {
    padding-bottom: 1.2rem;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }

    .size {
      font-size: 1.8rem;
      margin-bottom: 2.1rem;
    }

    .select-size {
      font-size: 1.3rem;
      margin-bottom: 1.6rem;
    }

    .size-qty-wrapper {
      display: flex;
      text-align: center;

      .fields {
        background: $black;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: 1px solid $white;

        .label {
          color: $white;
          width: 6.2rem;
          margin-bottom: 0;
          flex: 0 0 15%;
        }

        .input-text {
          height: 4rem;
          border: $form-check-input-border;
          flex: 0 0 85%;
        }

        .mage-error {
          background: $white;
          text-align: left;
        }
      }
    }
  }
}

.product-options-wrapper {

  .field.required > .label::after {
    display: none;
  }

  .btn-option-toggle {
    width: 100%;
    border: 2px solid #d31b19;
    margin: 2rem 0;
    position: relative;

    span {
      color: #d31b19;

      &::before {
        content: '+';
        position: absolute;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        border: 2px solid #d31b19;
        top: 50%;
        left: 10%;
        transform: translate(-50%, -50%);
        font-size: 15px;
      }
    }
  }

  .additional {
    margin-bottom: 1.5rem;

    > span {
      font-size: 18px;
    }
  }

  .field {

    .note {
      display: none;
    }

    &.additional {
      padding: 1rem 3rem 2.7rem;
      background: $white;
      margin: 0;
    }
  }

  .btn-remove-wrapper {
    text-align: right;

    .btn-option-remove {
      border: none;
      background: none;
      margin: 0;
      padding: 0;

      &:active {
        box-shadow: none;
      }
    }

    span {
      text-decoration: underline;
      font-size: 13px;
    }
  }
}

.fotorama__thumb--icon.fotorama__thumb--icon {
  background-image: url(../images/icon-chevron-right.svg);
  background-size: 24px 24px;
  background-position: center;
  padding-bottom: 2.4rem;

  .fotorama__nav-wrap--vertical & {
    transform: rotate(90deg);
  }

  .fotorama__thumb__arr--left & {
    transform: rotate(180deg);

    .fotorama__nav-wrap--vertical & {
      transform: rotate(-90deg);
    }

    .fotorama__nav-wrap--horizontal & {
      transform-origin: 10px 6px;
    }
  }
}

.product.data.items {

  &::before,
  &::after {
    display: none;
  }

  > .item {

    &.content {
      position: relative;
      z-index: 0;

      @include media-breakpoint-down(md) {
        border: none;
        padding-top: 2.4rem;
        padding-left: 0;
        padding-right: 0;
      }

      @include media-breakpoint-up(md) {
        margin-top: 6.7rem;
        padding-top: 3.7rem;
        border-top-color: $border-color;

        &::before,
        &::after {
          content: '';
          position: absolute;
          bottom: 100%;
          border-top: 1px solid $border-color;
          width: 100%;
        }

        &::before {
          left: 100%;
        }

        &::after {
          right: 100%;
        }
      }
    }

    &.title.title.title {
      margin-right: 8rem;

      > .switch {
        height: auto;
        background-color: $white;
        padding: 2.1rem 4rem;
        border: none;
        font-weight: 700;
        font-size: 1.6rem;
        color: $body-color;

        @include media-breakpoint-down(md) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1.5rem 3.2rem;

          img {
            transform: rotate(90deg);
          }
        }
      }

      @include media-breakpoint-down(md) {
        width: calc(100% + 6.4rem);
        margin: 0 -3.2rem;
        border-top: 1px solid $border-color;

        &:first-child {
          border-top: none;
        }

        &.active > .switch {
          border-bottom-color: $dark;

          img {
            transform: rotate(-90deg);
          }
        }
      }

      @include media-breakpoint-up(md) {

        > .switch {
          padding: 2.1rem 4rem;
          border-bottom: 5px solid $white;
          font-size: 1.5rem;

          img {
            display: none;
          }
        }

        &.active > .switch {
          border-bottom-color: $dark;
        }
      }
    }
  }
}

.product.info.detailed {

  @include media-breakpoint-up(md) {
    max-width: map-get($container-max-widths, 'xl');
    width: 100%;
    margin: 0 auto 4.7rem;
    padding: 0 4.2rem;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
}

.product-tabs {

  .tab-description {

    .item-content-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include media-breakpoint-up(lg) {

        .left {
          width: 55%;
        }

        .right {
          width: 35%;
        }
      }

      @include media-breakpoint-down(lg) {

        .right {
          width: 100%;
        }
      }
    }
  }

  .branding-data {

    @include media-breakpoint-down(lg) {
      margin-top: 3rem;
    }

    .branding-block {
      padding: 3rem;
      background-color: $light;
      margin-bottom: 1.6rem;
      border-radius: $border-radius-lg;

      .h5 {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
      }
    }

    .branding-template {

      a {
        text-decoration: none;
        color: $body-color;
        font-size: 1.3rem;
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }

        &::before {
          content: ' ';
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 0.8rem;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url(../images/icon-pdf.svg);
        }
      }
    }

    .branding-options {

      .branding-group {
        margin-bottom: 1.3rem;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: ' ';
          display: inline-block;
          width: 24px;
          height: 24px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 3px;
          left: 0;
          background-image: url(../images/icon-tag.svg);
        }
      }

      .branding-label {
        padding-left: 3.5rem;
        font-size: 1.3rem;
      }

      .branding-description {
        padding-left: 3.5rem;
        font-size: 1.3rem;
      }
    }
  }
}

.purchase-details {
  padding: 2rem;
  background-color: $white;
  font-size: 1.3rem;
  border-radius: 0.3rem;

  .discount-tiers,
  .min-purchase {
    position: relative;
    padding-left: 3.2rem;

    &::before {
      left: 0;
      content: ' ';
    }

    .breakdown-final-price {
      display: none;
    }

    .min-tooltip,
    &::before {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 1px;
    }
  }

  .prices-tier {

    .selected-item {
      color: red;
    }
  }

  .discount-tiers {

    .price-including-tax .price,
    .price-excluding-tax .price {
      font-size: 1.3rem;
    }

    @include media-breakpoint-down(xl) {
      padding-left: 0;

      b {
        padding-left: 3.2rem;
      }
    }
  }

  .discount-tiers::before {
    background-image: url(../images/icon-dollar.svg);
  }

  .min-purchase::before {
    background-image: url(../images/icon-tag.svg);
  }

  .min-tooltip {
    right: 0;
  }
}

.sizing-holder {
  overflow-x: auto;

  .sizing-table {

    th {
      white-space: nowrap;
    }
  }
}
