.page-header {
  position: sticky;
  top: 0;
  z-index: 900;
  background: $white;
  border-bottom: none;
  box-shadow: $box-shadow;
  margin-bottom: 0;

  @include media-breakpoint-down(lg) {
    box-shadow: none;
    position: static;
  }

  .panel.wrapper {
    border-bottom: none;
  }

  .panel.wrapper,
  .header-content {
    position: relative;
    z-index: 1;
    background: white;
  }

  .panel.header {

    @include media-breakpoint-down(lg) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 3.3rem;

      .panel.wrapper {
        padding-bottom: 0.1rem;
      }

      [data-content-type='row'] {
        margin: 0 0 0 2rem !important;
      }

      [data-element='inner'] {
        padding: 0;
      }

      ul {
        list-style: none;
        display: flex;
        padding-left: 0;
        margin-left: 1.2rem;
      }

      li {
        margin-bottom: 0;

        &:not(:last-child)::after {
          content: '|';
          display: inline-block;
          margin: 0 1rem 0 0.7rem;
          transform: translateY(-1px);
        }
      }

      a {
        color: $body-color;
        font-size: 1.3rem;
        font-weight: 700;
      }
    }
  }

  .header.content {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 0.1rem;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      padding: 1.5rem 0;

      .logo {
        flex-shrink: 0;
        margin: 0 4rem 0 0;
      }
    }

    @include media-breakpoint-up(lg) {

      .logo {
        margin-right: 7.1rem;
      }
    }

    @include media-breakpoint-down(lg) {
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }

      .logo {
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 114.28px;
        height: 30.47px;
      }
    }
  }
}

.header-links {

  @include media-breakpoint-up(lg) {
    /* TODO: Can be changed to 2rem after search is put back in */
    margin-left: auto;
  }
}

.header.links {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;

  .minicart-wrapper {
    float: none;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }

  .btn-link {
    position: relative;
    border: none;
  }

  .action {

    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 62px;

      img {
        width: 34px;
        height: 34px;
      }
    }

    img {
      transition: $transition-fade;
      display: block;
      margin: 0 auto 0.5rem;
    }

    span {
      line-height: 1;
      font-size: 1.3rem;
      color: $gray-950;
    }

    &:hover img {
      opacity: 0.7;
    }
  }

  .counter.qty {
    position: static;

    .loading-mask {
      display: none;
    }

    .counter-number:not(:empty) {
      position: absolute;
      top: 0.4rem;
      left: 2.7rem;
      height: 2.2rem;
      min-width: 2.2rem;
      padding: 0 6px;
      border-radius: 99px;
      border: 1px solid $white;
      background-color: $primary-dark;
      color: $white;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.6;

      @include media-breakpoint-down(lg) {
        top: 1.1rem;
        left: auto;
        right: 1rem;
      }
    }
  }
}
